// export class User {
//   email: string = "";
//   firstName: string = "";
//   lastName: string = "";
//   password: string = "";
//   dob: any = "";
//   gender: string = "";
//   location: string = "";
//   contactNumber: string = "";
// }

// export class Address {
//   city: string = "";
//   state: string = "";
//   country: string = "";
//   postalCode: string = "";
//   lat:string="";
//   lng:string='';
// }

// export class profile {
//   id:string="";
//   profile_picture:string="";
//   about: string = "";
//   firstName: string = "";
//   quality1: string = "";
//   quality2: string = "";
//   quality3: string = "";
//   quality4: string = "";
//   quality5: string = "";
//   quality6: string = "";
//   impQuality1: string = "";
//   impQuality2: string = "";
//   impQuality3: string = "";
//   impQuality4: string = "";
//   impQuality5: string = "";
//   impQuality6: string = "";
// }



// export const quality_imp_img = [
// {name:'Adventurous'  , img: './assets/img/qualities_important_to_me/adventurous.png'},
// {name:'Altruistic'  , img: './assets/img/qualities_important_to_me/altruistic.png'},
// {name:'Ambitious'  , img: './assets/img/qualities_important_to_me/ambitious.png'},
// {name:'Appreciative'  , img: './assets/img/qualities_important_to_me/appreciative.png'},
// {name:'Calm'  , img: './assets/img/qualities_important_to_me/calm.png'},
// {name:'Charismatic'  , img: './assets/img/qualities_important_to_me/charismatic.png'},
// {name:'Cheerful'  , img: './assets/img/qualities_important_to_me/cheerful.png'},
// {name:'Clever'  , img: './assets/img/qualities_important_to_me/clever.png'},
// {name:'Confident'  , img: './assets/img/qualities_important_to_me/confident.png'},
// {name:'Courageous'  , img: './assets/img/qualities_important_to_me/courageous.png'},
// {name:'Creative'  , img: './assets/img/qualities_important_to_me/creative.png'},
// {name:'Dedicated'  , img: './assets/img/qualities_important_to_me/dedicated.png'},
// {name:'Dependable'  , img: './assets/img/qualities_important_to_me/dependable.png'},
// {name:'Easygoing'  , img: './assets/img/qualities_important_to_me/easygoing.png'},
// {name:'Educated'  , img: './assets/img/qualities_important_to_me/educated.png'},
// {name:'Eloquent'  , img: './assets/img/qualities_important_to_me/eloquent.png'},
// {name:'Empathetic'  , img: './assets/img/qualities_important_to_me/empathetic.png'},
// {name:'Encouraging'  , img: './assets/img/qualities_important_to_me/encouraging.png'},
// {name:'Enterprising'  , img: './assets/img/qualities_important_to_me/enterprising.png'},
// {name:'Family focus'  , img: './assets/img/qualities_important_to_me/family focus.png'},
// {name:'Fit'  , img: './assets/img/qualities_important_to_me/fit.png'},
// {name:'Friendly'  , img: './assets/img/qualities_important_to_me/friendly.png'},
// {name:'Funny'  , img: './assets/img/qualities_important_to_me/funny.png'},
// {name:'Generous'  , img: './assets/img/qualities_important_to_me/generous.png'},
// {name:'Gentleman'  , img: './assets/img/qualities_important_to_me/gentleman.png'},
// {name:'Genuine'  , img: './assets/img/qualities_important_to_me/genuine.png'},
// {name:'Glamorous'  , img: './assets/img/qualities_important_to_me/glamorous.png'},
// {name:'Hardworking'  , img: './assets/img/qualities_important_to_me/hardworking.png'},
// {name:'Healthy'  , img: './assets/img/qualities_important_to_me/healthy.png'},
// {name:'Honest'  , img: './assets/img/qualities_important_to_me/honest.png'},
// {name:'Resourceful'  , img: './assets/img/qualities_important_to_me/resourceful.png'},
// {name:'Responsible'  , img: './assets/img/qualities_important_to_me/responsible.png'},
// {name:'Romantic'  , img: './assets/img/qualities_important_to_me/romantic.png'},
// {name:'Spiritual'  , img: './assets/img/qualities_important_to_me/spiritual.png'},
// {name:'Strong'  , img: './assets/img/qualities_important_to_me/strong.png'},
// {name:'Tender'  , img: './assets/img/qualities_important_to_me/tender.png'},
// {name:'Tidy'  , img: './assets/img/qualities_important_to_me/tidy.png'},
// {name:'Trustworthy'  , img: './assets/img/qualities_important_to_me/trustworthy.png'},
// ];
// export const like_img = [
// {name:'Acting'  , img: './assets/img/things_i_like_to_do/acting.png'},
// {name:'Art'  , img: './assets/img/things_i_like_to_do/art.png'},
// {name:'Bbq'  , img: './assets/img/things_i_like_to_do/bbq.png'},
// {name:'Beach'  , img: './assets/img/things_i_like_to_do/beach.png'},
// {name:'Camping'  , img: './assets/img/things_i_like_to_do/camping.png'},
// {name:'Career'  , img: './assets/img/things_i_like_to_do/career.png'},
// {name:'Charity'  , img: './assets/img/things_i_like_to_do/charity.png'},
// {name:'Climbing'  , img: './assets/img/things_i_like_to_do/climbing.png'},
// {name:'Comedy'  , img: './assets/img/things_i_like_to_do/comedy.png'},
// {name:'Cooking'  , img: './assets/img/things_i_like_to_do/cooking.png'},
// {name:'Cycling'  , img: './assets/img/things_i_like_to_do/cycling.png'},
// {name:'Dancing'  , img: './assets/img/things_i_like_to_do/dancing.png'},
// {name:'Dining'  , img: './assets/img/things_i_like_to_do/dining.png'},
// {name:'Diy'  , img: './assets/img/things_i_like_to_do/diy.png'},
// {name:'Golf'  , img: './assets/img/things_i_like_to_do/golf.png'},
// {name:'Gym'  , img: './assets/img/things_i_like_to_do/gym.png'},
// {name:'Hiking'  , img: './assets/img/things_i_like_to_do/hiking.png'},
// {name:'Investing'  , img: './assets/img/things_i_like_to_do/investing.png'},
// {name:'Learning'  , img: './assets/img/things_i_like_to_do/learning.png'},
// {name:'Meditate'  , img: './assets/img/things_i_like_to_do/meditate.png'},
// {name:'Movies'  , img: './assets/img/things_i_like_to_do/movies.png'},
// {name:'Music'  , img: './assets/img/things_i_like_to_do/music.png'},
// {name:'Nature'  , img: './assets/img/things_i_like_to_do/nature.png'},
// {name:'Photography'  , img: './assets/img/things_i_like_to_do/photography.png'},
// {name:'Piano'  , img: './assets/img/things_i_like_to_do/piano.png'},
// {name:'Picnic'  , img: './assets/img/things_i_like_to_do/picnic.png'},
// {name:'Public speaking'  , img: './assets/img/things_i_like_to_do/public speaking.png'},
// {name:'Quitar'  , img: './assets/img/things_i_like_to_do/quitar.png'},
// {name:'Reading'  , img: './assets/img/things_i_like_to_do/reading.png'},
// {name:'Relaxing'  , img: './assets/img/things_i_like_to_do/relaxing.png'},
// {name:'Running'  , img: './assets/img/things_i_like_to_do/running.png'},
// {name:'Sailing'  , img: './assets/img/things_i_like_to_do/sailing.png'},
// {name:'Scuba'  , img: './assets/img/things_i_like_to_do/scuba.png'},
// {name:'Sculpture'  , img: './assets/img/things_i_like_to_do/sculpture.png'},
// {name:'Self development'  , img: './assets/img/things_i_like_to_do/self development.png'},
// {name:'Singing'  , img: './assets/img/things_i_like_to_do/singing.png'},
// {name:'Skiboard'  , img: './assets/img/things_i_like_to_do/skiboard.png'},
// {name:'Socialising'  , img: './assets/img/things_i_like_to_do/socialising.png'},
// {name:'Sport'  , img: './assets/img/things_i_like_to_do/sport.png'},
// {name:'Study'  , img: './assets/img/things_i_like_to_do/study.png'},
// {name:'Surf'  , img: './assets/img/things_i_like_to_do/surf.png'},
// {name:'Tennis'  , img: './assets/img/things_i_like_to_do/tennis.png'},
// {name:'Theatre'  , img: './assets/img/things_i_like_to_do/theatre.png'},
// {name:'Travel'  , img: './assets/img/things_i_like_to_do/travel.png'},
// {name:'Yoga'  , img: './assets/img/things_i_like_to_do/yoga.png'},
// ];

// // [a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$

// export const emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
export const phonePattern = /[0-9]*/;
export const namePattern ="[a-zA-Z ]*";
// export const emailPattern = "/^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$/";
// export const emailPattern = "^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$";
export const emailPattern = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;
//export const emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";


