import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from 'src/app/_service/home-service.service';
import { AlertService } from '../_service/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as pattern from '../../app/model/user';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  public loader:boolean=false;
  public isSuccess: boolean = false;
  public message: any;
  public forgetEmailForm:FormGroup;
  constructor(public service: HomeServiceService,public alertService:AlertService,private formBuilder:FormBuilder) { }

  ngOnInit() {
    this.forgetEmailForm = this.formBuilder.group({
      forgetEmail: [
        "",
        // Validators.compose([Validators.required,Validators.pattern(pattern.emailPattern),this.customValidation.bind(this)])
        Validators.compose([Validators.required,Validators.email,this.customValidation.bind(this)])
      ]
    });
  }
  forgetPassword(forgetEmail) {
    if(this.forgetEmailForm.invalid){
      return
    }
    this.loader=true;
    this.service.forgetPassword(forgetEmail).subscribe(res => {
      this.loader=false;
      this.isSuccess = true;
      this.message = res;
    },
      (error) => {
        this.loader=false;
        this.alertService.error(error);
      });
  }
customValidation()
{

}
}
