import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { s } from '@angular/core/src/render3';
// enum CheckBoxType { self, behalf, NONE };
@Component({
  selector: 'app-donate-reg',
  templateUrl: './donate-reg.component.html',
  styleUrls: ['./donate-reg.component.css']
})
export class DonateRegComponent implements OnInit {
  selfForm: boolean = true;
  behalfForm: boolean = false;
  // check_box_type = CheckBoxType;
  currentlyChecked:string;
  constructor(private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params) => {
      if (params["type"] === "indv"){
        this.currentlyChecked = 'self'
        this.behalfForm = false;
        this.selfForm = true;
      }
      else if(params["type"] === "org"){ 
        this.currentlyChecked = 'behalf'
        this.behalfForm = true; 
        this.selfForm = false;
      }
    });
    // if(this.behalfForm == true){
    //   const element = document.getElementById('behalf') as HTMLInputElement;
    //   const el = document.getElementById('self') as HTMLInputElement;
    //   el.checked = false
    //   element.checked = true;
    // }
    
  
  }
  
  // selectCheckBox(event,targetType) {
  //   console.log(event.target.value)
  //   if(event.target.value == 'self'){
  //     this.currentlyChecked = event.target.value
  //     this.behalfForm= false
  //     this.selfForm = true
  //   }
  //   else if(event.target.value == 'behalf') {
  //     this.currentlyChecked = event.target.value
  //     this.selfForm = false
  //     this.behalfForm = true
  //   }
  // }
  selectCheckBox(event,targetType){
    if(event.target.value == 'self' ){
      const element = document.getElementById('behalf') as HTMLInputElement;
      const el = document.getElementById('self') as HTMLInputElement;
      if(el.checked == true ){
        element.checked = false
        this.behalfForm = false
        this.selfForm = true
      }
      else {
        element.checked = true
        this.behalfForm = true
        this.selfForm = false
      }
          
    }
    else if(event.target.value == 'behalf') {
      const element = document.getElementById('behalf') as HTMLInputElement;
      const el = document.getElementById('self') as HTMLInputElement;
          if(element.checked == true ){
            el.checked = false
            this.behalfForm = true
            this.selfForm = false
          }
          else {
            el.checked = true
            this.behalfForm = false
            this.selfForm = true
          }
        }
        
  }
  
}
