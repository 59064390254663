import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "src/app/home/home.component";
// import { ContactUsComponent } from "src/app/contact-us/contact-us.component";
import { FaqsComponent } from "src/app/faqs/faqs.component";
import { GuidelinesComponent } from "src/app/_shared/guidelines/guidelines.component";
import { LoginComponent } from "src/app/login/login.component";
import { SchoolRegistrationComponent } from "src/app/_shared/school-registration/school-registration.component";
import { ForgetPasswordComponent } from "src/app/forget-password/forget-password.component";
import { TempComponent } from "./_temp/Temp/Temp.component";
import { AdminAuthGuard } from "./AuthGuard/admin-auth-service.service";
import { SyllabusComponent } from "./_shared/syllabus/syllabus.component";
import { ViewResultComponent } from "./view-result/view-result.component";
import { AuthguardGuard } from "./AuthGuard/authguard.guard";
import { HelpComponent } from "./help/help.component";
import { SolicitingHelpComponent } from "./help-subComp/soliciting-help/soliciting-help.component";
import { ReadyHelpComponent } from "./help-subComp/ready-help/ready-help.component";
import { PrepClassesComponent } from './prep-classes/prep-classes.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { HelpRegistrationComponent } from "./help-subComp/help-registration/help-registration.component";
import { DonateIndividualComponent } from "./help-subComp/donate-individual/donate-individual.component";
import { CorporateOrganisationComponent } from "./help-subComp/corporate-organisation/corporate-organisation.component";
import { DonateRegComponent } from "./help-subComp/donate-reg/donate-reg.component";
import { LoadingPageComponent } from "./_shared/loading-page/loading-page.component";
import { PrivacyPolicyComponent } from "./_shared/footer/footer-subcomponent/privacy-policy/privacy-policy.component";
import { TermsOfUseComponent } from "./_shared/footer/footer-subcomponent/terms-of-use/terms-of-use.component";
import { AboutQuicklearningComponent } from "./_shared/footer/footer-subcomponent/about-quicklearning/about-quicklearning.component";
import { ContactUsComponent } from "./_shared/footer/footer-subcomponent/contact-us/contact-us.component";
// import { CertificatesComponent } from "./certificates/certificates.component";
import { AdvanceSignupComponent } from "./_shared/advance-signup/advance-signup.component";
import { AdvanceDashboardComponent } from "./advance-section/advance-dashboard/advance-dashboard.component";
import { AdvanceAuthGuard } from "./AuthGuard/advance-auth-gaurd";
import { PastQuestionsComponent } from "./admin_management/general/past-questions/past-questions.component";
import { ContinentalViewResultComponent } from "./view-result/continental-view-result/continental-view-result.component";
import { AdvancedViewResultComponent } from "./view-result/advanced-view-result/advanced-view-result.component";
import { PaymentModule } from "./admin_management/payment/payment.module";
import { PaymentComponent } from "./payment/payment.component";
import { ContinentalHomeComponent } from "./continental/continental-home/continental-home.component";
import { MasterClassesComponent } from "./master-classes/master-classes.component";
import { UniversityStudentRegistrationComponent } from "./_shared/university-student-registration/university-student-registration.component";
import { AdvanceSignupUniversityComponent } from './advance-signup-university/advance-signup-university.component';
import { HallOfFameComponent } from "./hall-of-fame/hall-of-fame.component";
import { HonarsRollComponent } from "./honars-roll/honars-roll.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";


const routes: Routes = [
  { path: "", redirectTo: "main", pathMatch: "full" },
  { path: "main", component: LoadingPageComponent},
  { path: "home", component: HomeComponent, canActivate: [AuthguardGuard] },
  {
    path: "guide-line",
    component: GuidelinesComponent,
    canActivate: [AuthguardGuard],
  },
  { path: "faq", component: FaqsComponent, canActivate: [AuthguardGuard] },
  // {
  //   path: "contact-us",
  //   component: ContactUsComponent,
  //   canActivate: [AuthguardGuard],
  // },
  {
    path: "syllabus",
    component: SyllabusComponent,
    canActivate: [AuthguardGuard],
  },
  {
    path: "past-questions",
    component: PastQuestionsComponent,
    canActivate: [AuthguardGuard],
  },
  { path: "login", component: LoginComponent },
  { path: "temp", component: TempComponent, canActivate: [AuthguardGuard] },
  {
    path: "school-registration",
    component: SchoolRegistrationComponent,
    canActivate: [AuthguardGuard],
  },
  {
    path: "forget-password",
    component: ForgetPasswordComponent,
    canActivate: [AuthguardGuard],
  },
  {
    path: "result/:type",
    component: ViewResultComponent,
    canActivate: [AuthguardGuard],
  },
  {
    path: "result/africa/:type",
    component: AdvancedViewResultComponent,
    canActivate: [AuthguardGuard],
  },
  {
    path: "result/university/:type",
    component: ContinentalViewResultComponent,
    canActivate: [AuthguardGuard],
  },
  {
    path: "school",
    loadChildren: "./schoolmanagement/school.module#SchoolModule",
  },
  {
    path: "accountManager",
    loadChildren:
      "./AccountManger/schoolaccount-manager/schoolaccount-manager.module#SchoolaccountManagerModule",
  },
  {
    path: "examiner",
    loadChildren: "./examiner/examiner.module#ExaminerModule",
  },
  {
    path: "admin",
    loadChildren: "./admin_management/admin-module.module#AdminModuleModule",
  },
  {
    path: "exam-authorizer",
    loadChildren:
      "./examiner-authorizer/examiner-authorizer.module#ExaminerAuthorizerModule",
  },
  {
    path: "report-admin",
    loadChildren:
      "./reportadmin/reportadmin.module#ReportadminModule",
  },
  {
    path: "super-admin",
    loadChildren:
      "./superadmin-management/superadmin-management.module#SuperadminManagementModule",
  },
  {
    path: "advance",
    loadChildren:
      "./advance-section/advance/advance.module#AdvanceModule"
  },
  { path: "help", component: HelpComponent },
  { path: "soliciting-help", component: SolicitingHelpComponent },
  { path: "ready-help", component: ReadyHelpComponent },
  { path: "prep-classes", component: PrepClassesComponent  },
  { path: "master-classes", component: MasterClassesComponent },
  { path: "sponsor", component: SponsorComponent  },
  // { path: "help-registration", component: HelpRegistrationComponent  },
  { path: "donate-individual", component: DonateIndividualComponent  },
  { path: "corporate-organisation", component: CorporateOrganisationComponent  },
  {path:"registration-help",component:HelpRegistrationComponent},
  {path:"registration-donate",component:CorporateOrganisationComponent},
  {path:"indregistration-Donate",component:DonateIndividualComponent},
  {path:"donate-reg",component:DonateRegComponent},
  {path:"privacy-policy", component:PrivacyPolicyComponent},
  {path:"terms-of-use", component:TermsOfUseComponent},
  {path:"about-quicklearning",component:AboutQuicklearningComponent},
  {path:"contactus",component:ContactUsComponent},
  {path:"africa-signup", component:AdvanceSignupComponent},
  {path:"payment-gateway", component:PaymentComponent},
  {path:"continetal-home", component:ContinentalHomeComponent},
  {path:"university-signup", component:UniversityStudentRegistrationComponent},
  {path:"university-update", component:UniversityStudentRegistrationComponent},
  {path:"university/update", component:AdvanceSignupUniversityComponent},
  {path:"hall-of-fame", component:HallOfFameComponent},
  {path:"honars-roll", component:HonarsRollComponent},
  {path:"404", component:PageNotFoundComponent},
  {path:"**", redirectTo:'/404'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
