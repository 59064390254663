import { FormGroup } from '@angular/forms';

export function DuplicateMail(controlName: string, matchingControlName: string ,ParentEmail:string) {
    return (formGroup: FormGroup) => {
        const personlMail = formGroup.controls[controlName];
        const schoolMail = formGroup.controls[matchingControlName];
        const parent1Mail = formGroup.controls[ParentEmail];
        // const parent2Mail = formGroup.controls[Parent2Email];
  

        if (schoolMail.errors && !schoolMail.errors.duplicateMial ) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        if ((personlMail.value == schoolMail.value) || (parent1Mail.value == schoolMail.value)) {
            schoolMail.setErrors({ duplicateMial: true });
        } else {
            schoolMail.setErrors(null);
        }
        if ((personlMail.value == parent1Mail.value) || (parent1Mail.value == schoolMail.value)) {
            parent1Mail.setErrors({ duplicateMial: true });
        } else {
            parent1Mail.setErrors(null);
        }
    }
}

export function DuplicatePhone(fcandidate: any, faltercandid: any ,fschool:any,fparent1:any) {
    return (formGroup: FormGroup) => {
        const candidate = formGroup.controls[fcandidate];
        const altercandid = formGroup.controls[faltercandid];
        const school = formGroup.controls[fschool];
        const parent1 = formGroup.controls[fparent1];
        // const parent2 = formGroup.controls[fparent2];
        // debugger
        if (candidate.errors && !candidate.errors.duplicatePhone ) {
            return;
        }

        if (school.errors && !school.errors.duplicatePhone ) {
            return;
        }

        if (parent1.errors && !parent1.errors.duplicatePhone) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        // if ((candidate.value == altercandid.value) || (candidate.value == school.value) || (candidate.value == parent1.value) || ((candidate.value == parent2.value))) {
        //     candidate.setErrors({ duplicatePhone: true });
        // } else {
        //     candidate.setErrors(null);
        // }
        if (((altercandid.value!= '') && ((altercandid.value == candidate.value) || (altercandid.value == school.value) || (altercandid.value == parent1.value)))) {
            altercandid.setErrors({ duplicatePhone: true });
        } else {
            altercandid.setErrors(null);
        }
        if ((school.value == altercandid.value) || (school.value == candidate.value) || (school.value == parent1.value)) {
            school.setErrors({ duplicatePhone: true });
        } else {
            school.setErrors(null);
        }
        if ((parent1.value == altercandid.value) || (parent1.value == school.value) || (parent1.value == candidate.value)) {
            parent1.setErrors({ duplicatePhone: true });
        } else {
            parent1.setErrors(null);
        }
    }
}