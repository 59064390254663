import { Component, isDevMode, OnInit } from '@angular/core';
import { candidateDetails, honoursRoll } from '../models/home-page-model/home-page-model.module';
import { AlertService } from '../_service/alert.service';
import { HomeServiceService } from '../_service/home-service.service';

@Component({
  selector: 'app-honars-roll',
  templateUrl: './honars-roll.component.html',
  styleUrls: ['./honars-roll.component.css']
})
export class HonarsRollComponent implements OnInit {
  countryType:any
  loader : boolean=false
  filter : honoursRoll = new honoursRoll()
  data : candidateDetails = new candidateDetails()
  year : any = []
  dispYear:any;
  serviceBaseUrl: string;

  constructor(public service: HomeServiceService,
    private alertService: AlertService,) { 
      if(isDevMode){
        this.serviceBaseUrl = 'https://testserver.quicklearning.africa';
      }
      else {
        this.serviceBaseUrl = 'https://mathserver.quicklearning.africa'
      }
    }

  ngOnInit() {
    this.dispYear = this.filter.Year;
    this.countryType = localStorage.getItem('type')
    if(this.countryType) {
      this.countryType = localStorage.getItem('type')
    }
    else {
      this.countryType = 'Nigeria'
    }
    this.generateYear();
    this.getHonourRollDetail();
  }


  getHonourRollDetail(){
    this.loader = true
    if(this.countryType == 'Nigeria'){
      this.service.nigeriaHonourRollDetailApi(this.filter).subscribe((res:any)=>{
        this.data = res
        this.filter.totalPage = this.data.TotalRecords
        this.filter.totalPage = <number>Math.ceil(this.filter.totalPage / 10);
        this.dispYear = this.filter.Year
        this.loader = false

      },
      (error) => {
        this.loader = false;
        this.alertService.error(error, true);
      });
    }
    else if(this.countryType == 'Africa') {
      this.service.africaHonourRollDetailApi(this.filter).subscribe((res:any)=>{
        this.data = res
        this.filter.totalPage = this.data.TotalRecords
        this.filter.totalPage = <number>Math.ceil(this.filter.totalPage / 10);
        this.dispYear = this.filter.Year
        this.loader = false

      },
      (error) => {
        this.loader = false;
        this.alertService.error(error, true);
      });
      
    }
    else if(this.countryType == 'University') {
      this.service.universityHonourRollDetailApi(this.filter).subscribe((res:any)=>{
        this.data = res
        this.filter.totalPage = this.data.TotalRecords
        this.filter.totalPage = <number>Math.ceil(this.filter.totalPage / 10);
        this.dispYear = this.filter.Year
        this.loader = false

      },
      (error) => {
        this.loader = false;
        this.alertService.error(error, true);
      });
     
    }
  }
  generateYear(){
    var currentYear = (new Date()).getFullYear();
    for (var i = 2000; i <= currentYear; i++) {
      this.year.push(i);
  }
  }
  next(){

  }

  previous(){

  }
  reset(){

  }

}
