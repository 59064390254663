import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-quicklearning',
  templateUrl: './about-quicklearning.component.html',
  styleUrls: ['./about-quicklearning.component.css']
})
export class AboutQuicklearningComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
