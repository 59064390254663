import { Component, OnInit, NgZone, ChangeDetectorRef } from "@angular/core";
import { HomeSecondService } from "src/app/_service/home-second.service";
import { HomeServiceService } from "src/app/_service/home-service.service";
import { AlertService } from "src/app/_service/alert.service";
import {
  PaginationModel,
  GridColumnType,
  PropertyMetaModel
} from "src/app/_models/pagination-model";
import * as url from "src/app/model/config";
import { SchoolService } from "src/app/_service/school-service.service";
declare var $: any;
@Component({
  selector: "app-syllabus-modal",
  templateUrl: "./syllabus.component.html",
  styleUrls: ["./syllabus.component.css"]
})
export class SyllabusComponent implements OnInit {
  classList: any;
  constructor(
    private service: HomeSecondService,
    private serviceHome: HomeServiceService,
    private alert: AlertService,
    private changeRef: ChangeDetectorRef,
    private schoolService: SchoolService
  ) {
    this.displayColumns = [
      {
        PropertyName: "FileName",
        ColumnName: "Document Name",
        PropertyType: GridColumnType.Text,
        ClassName: "mylink mypointer"
      },
      {
        PropertyName: "SeasonType",
        ColumnName: "Season Type",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "Category",
        ColumnName: "Category",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "ClassName",
        ColumnName: "Class",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "Year",
        ColumnName: "Year",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "Id",
        ColumnName: "Delete",
        PropertyType: GridColumnType.Icon,
        DisplayText: "Delete"
      }
    ];
  }
  public pageInfo: PaginationModel = {
    PageSize: 4,
    CurrentPage: 1,
    TotalRecord: 0
  };
  public gridData: any[] = [];
  public displayColumns: PropertyMetaModel[];
  SeasonType = 'Nigeria'
  category: any = "";
  ClassName:any = "";
  modalCategory: any = "";
  modalSeasonType = 'Nigeria';
  modalClassName:any = "";
  year: any = "";
  Modalyear: any = "";
  yearList: any = [];
  fileList: FileList;
  formData: FormData = new FormData();

  loader: boolean;
  deleteModalData: any;
  ngOnInit() {
    this.getSyllabusYears();
    this.getSyllabus();
    this.getAllClasses();
    var that = this;
    $("#myImportSyllabusModal").on("hidden.bs.modal", function () {
      that.ResetValue();
    });
  }

  ResetValue(): any {
    this.modalSeasonType = 'Nigeria'
    this.modalCategory = "";
    this.Modalyear = "";
    $("#fileSelect").val("");
    this.changeRef.detectChanges();
  }

  getSyllabusYears() {
    var data = {
      Category: "",
      Season: "",
      pageno: this.pageInfo.CurrentPage,
      pagesize: this.pageInfo.PageSize,
      sortby: "",
      sortorder: ""
    };
    this.service.getSyllabusYears().subscribe(
      res => {
        var resp = JSON.parse(JSON.stringify(res));
        this.yearList = resp.List;
      },
      err => {
        console.log("get year negative", err);
      }
    );
  }

  getSyllabus() {
    this.loader = true;
    var data = {
      SeasonType: this.SeasonType,
      Category: this.category,
      Year: this.year,
      pageno: this.pageInfo.CurrentPage,
      pagesize: this.pageInfo.PageSize,
      sortby: "",
      sortorder: ""
    };

    this.service.LoadSyllabus(data).subscribe(
      res => {
        this.loader = false;
        var resp = JSON.parse(JSON.stringify(res));

        if (resp) {
          this.pageInfo.TotalRecord = resp.TotalRecords;
          this.gridData = resp.List;
          this.gridData.forEach(x => {
            x["link"] = url.serviceBaseUrl + "/SyllabusDocuments/" + x.FileName;
            x["Delete"] = !x.IsDeleted;
          });
        } else {
          this.gridData = [];
        }
      },
      err => {
        this.loader = false;
      }
    );
  }

  openDeleteModal(item) {
    $("#deleteModel").modal("show");
    this.deleteModalData = item;
  }

  chooseFile(event) {
    this.fileList = event.target.files;
    if (this.fileList.length > 0) {
      console.log("1")
      let file: File = this.fileList[0];
      if (
        this.fileList[0].type == "application/pdf"
      ) {
        console.log("2")
        if (this.formData.has("file")) {
          this.formData.delete("file");
        }
        this.formData.append("file", file);
      }
      else {
        console.log("3")
        this.alert.error("File must be of format pdf.", true);
      }
    }


  }

  importFileToServer() {
    this.loader = true;
    if (this.fileList == undefined) {
      this.loader = false;
      this.alert.error(
        "No file exist Please select a valid file to import data.",
        true
      );
      return;
    }
    if ((this.modalSeasonType == 'Nigeria' && this.modalCategory == "") || (this.modalSeasonType == 'Africa' && this.modalCategory == "") || this.Modalyear == "") {
      this.loader = false;
      this.alert.error("All * fields are mandatory", true);
      return;
    }
    if (this.Modalyear) {
      let regexp = new RegExp(/^\d{4}$/);
      let isValid = regexp.test(this.Modalyear);
      if (!isValid) {
        this.loader = false;
        this.alert.error("Enter Valid Year", true);
        return;
      }
    }
    if (this.formData.has("Category") || this.formData.has("Year")) {
      this.formData.delete("Category");
      this.formData.delete("Year");
    }
    if (this.modalSeasonType == 'University') {
      this.formData.append("SeasonType", this.modalSeasonType)
      this.formData.append("ClassName", this.modalClassName);
      this.formData.append("Year", this.Modalyear);
    }
    else if(this.modalSeasonType == 'Nigeria' || this.modalSeasonType == 'Africa'){
      this.formData.append("SeasonType", this.modalSeasonType)
      this.formData.append("Category", this.modalCategory);
      this.formData.delete("ClassName");
      this.formData.append("Year", this.Modalyear);
    }
    // else if(this.modalSeasonType == 'Africa'){
    //   this.formData.append("SeasonType", this.modalSeasonType)
    //   this.formData.append("ClassName", this.modalClassName);
    //   this.formData.append("Year", this.Modalyear);
    // }
    this.serviceHome.importSyllabus(this.formData).subscribe(
      res => {
        $("#myImportSyllabusModal").modal("hide");
        this.formData = new FormData()
        this.getSyllabus();
        this.getSyllabusYears();
        this.loader = false;
        var resp = JSON.parse(JSON.stringify(res));
        this.alert.success("Syllabus uploaded.", true);
      },
      err => {
        this.formData = new FormData()
        this.loader = false;
        this.alert.error(err, true);
        this.alert.error("Syllabus upload failed.", true);
      }
    );
  }

  deleteUploadSyllabus() {
    var data = {
      $$hashKey: "object:679",
      Category: this.deleteModalData.Category,
      FileName: this.deleteModalData.FileName,
      Id: this.deleteModalData.Id,
      IsDeleted: this.deleteModalData.IsDeleted,
      RowNumber: this.deleteModalData.RowNumber,
      Season: this.deleteModalData.Season,
      Season1: this.deleteModalData.Season1
    };
    this.serviceHome.deleteUploadSyllabus(data).subscribe(
      res => {
        $("#deleteModel").modal("hide");
        this.getSyllabus();
        var resp = JSON.parse(JSON.stringify(res));
        this.alert.success(resp, true);
      },
      err => {
        console.log(" delete negative", err);
        this.alert.error(err, true);
      }
    );
  }
  clearFilters() {
    this.category = "";
    this.year = "";
    this.SeasonType = 'Nigeria'
    this.getSyllabus()
  }
  pageInfoChange(event) {
    this.pageInfo.CurrentPage = event.CurrentPage;
    this.pageInfo.PageSize = event.PageSize;
    this.getSyllabus();
  }

  action(event) {
    if (event.action == "Id") this.openDeleteModal(event.value);
    else if (event.action == "FileName") window.open(event.value.link, "blank");
  }

  getAllClasses() {
    this.loader = true;
    this.schoolService.advanceGetClass().subscribe(res => {
      this.classList = res;
      this.loader = false;
    },
      (error) => {
        this.loader = false;
      });
  }
}
