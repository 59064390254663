import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HomeServiceService } from "src/app/_service/home-service.service";
import { AlertService } from "src/app/_service/alert.service";
import { Router } from "@angular/router";
import { debug } from "util";
import { HttpResponse } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { AdminServiceService } from "../_service/admin-service.service";
declare var $: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  loginForm: FormGroup;
  show: boolean = false;
  password;
  submittedError: boolean;
  message: any;
  loader: boolean;
  messageKey: any;
  error: any;
  public passwordType: string = "password";
  public hideShowIcon: string = "fa fa-eye-slash";
  theCheckbox = false;
  focusCount = 0
  countryType: string;
  regClosed: any;
  constructor(
    private formBuilder: FormBuilder,
    public service: HomeServiceService,
    public alert: AlertService,
    private route: Router,
    private _cookieService: CookieService,
    private adminService: AdminServiceService,
    private router:Router
  ) { 
  
  }

  ngOnInit() {

    this.countryType = localStorage.getItem('type')
    if(this.countryType){
      this.countryType = localStorage.getItem('type')
    }
    else {
      this.countryType = 'Nigeria'
    }

    this.password = 'password'
    if (this._cookieService.get("remember")) {
      this.theCheckbox = Boolean(this._cookieService.get("remember"));
      this.loginForm = this.formBuilder.group({
        username: [
          this._cookieService.get("username"),
          Validators.compose([Validators.required, Validators.email])
        ],
        password: [this._cookieService.get("password"), Validators.required]
      });
    } else {
      this.loginForm = this.formBuilder.group({
        username: [
          "",
          Validators.compose([Validators.required, Validators.email])
        ],
        password: ["", Validators.required]
      });
    }
  }

  toggleVisibility(e) {

    this.theCheckbox = e.target.checked;
    if (!e.target.checked) {
      this._cookieService.delete("username");
      this._cookieService.delete("password");
      this._cookieService.delete("remember");
    }
  }
  showPassword() {
    if (this.password === "password") {
      this.password = "text";
      this.show = true;
    } else {
      this.password = "password";
      this.show = false;
    }
  }

  onSubmit(form) {
    if (this.loginForm.invalid) {
      this.submittedError = true;
      return;
    }
    this.loader = true;
    var password = btoa(form.value.password);
    // form.value.password = password;
    var data = {
      username: form.value.username,
      password: password
    };

    if (this.theCheckbox) {
      this._cookieService.set("username", this.loginForm.value.username);
      this._cookieService.set("password", this.loginForm.value.password);
      this._cookieService.set("remember", this.theCheckbox.toString());
    }
    this.service.login(data).subscribe(
      (res: HttpResponse<any>) => {
        this.focusCount = 0
        localStorage.setItem("Token", res.headers.get("Token"));
        var resp = JSON.parse(JSON.stringify(res.body));
        localStorage.setItem("Role", resp.RoleName);
        this.loader = false;
        localStorage.setItem("logincredential", JSON.stringify(res));
        if (resp.RoleName == "SchoolRepresentative") {
          localStorage.setItem("SchoolId", resp.SchoolId);
          this.route.navigate(["school/schooldashboard"]);
          return;
        } else if (resp.RoleName == "VerificationManager") {
          localStorage.setItem("VerificationManager", resp);
          this.route.navigate(["accountManager/Manager"]);
          return;
        } else if (resp.RoleName == "AfricaStudent") {
          this.route.navigate(["advance/africa-dashboard"]);
        }
        else if (resp.RoleName == "UniversityStudent") {
          this.route.navigate(["advance/unversity-dashboard"]);
        } else if (resp.RoleName == "Examiner") {
          this.route.navigate(["examiner/view-candidate"]);
          return;
        } else if (resp.RoleName == "ExaminerAuthorizer") {
          this.route.navigate(["exam-authorizer/view-candidate"]);
          return;
        } else if (resp.RoleName == "ReportAdmin") {
          localStorage.setItem("ReportAdmin", resp);
          this.route.navigate(["report-admin/school-type"]);
          this.loader = false;
          return;
        }
        else if (resp.RoleName == "Admin") {
          this.route.navigate(["admin/centres"]);
          return;
        }
        else if (resp.RoleName == "SuperAdmin") {
          this.route.navigate(["super-admin/centres"]);
          return;
        }

      },
      err => {
        this.loader = false;
        if (err != undefined) {
          
          if (err.error.FlagSchoolEmailVerified == false) {
            // this.alert.error("please, kindly check your email for the verification link, if not seen click resnd mail", true);
            $('#verificationModal').modal('show')
          } else {
            this.alert.error(err.error, true);
          }
        }
      }
    );
  }

  initfocus() {
    if (this.focusCount == 0) {
      this.emailInput.nativeElement.value = ""
      this.passwordInput.nativeElement.value = ""
      let input = <HTMLElement>document.getElementById('email')
      input.focus()
      this.focusCount++
    }
  }
  emailVerification() {
    this.adminService.emailVerificationApi(this.loginForm.value.username).subscribe((res: any) => {
      if (res) {
        $('#verificationModal').modal('hide')
        this.alert.success("Verification mail sent successfully")
      }
    }, (err) => {

    }
    )
  }

  regPage(){
    if (this.countryType == 'Nigeria') {
       this.router.navigate(['/school-registration'])
      // this.nigeriaRegStatus(this.countryType)
    }
    else if (this.countryType == 'Africa') {
      this.router.navigate(['/africa-signup'])
    }
    else if (this.countryType == 'University') {
      this.router.navigate(['/university-signup'])
    }
  }
}

