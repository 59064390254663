import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams
} from "@angular/common/http";
import * as url from "src/app/model/config";
import "rxjs/add/operator/map";
import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { Observable, timer, Subscriber, Subject, throwError } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import "rxjs/add/observable/of";

@Injectable({
  providedIn: "root"
})
export class HomeServiceService {
  countryType = new Subject<string>();
  regClosedBySeason = new Subject<any>();

  rejectCandidates(data: { CandidateList: string[] }): any {
    const Url = url.rejectCandidatesURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getCandidateDetail(Id) {
    const Url = url.getCandidateData + "?Id=" + Id; ;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getAfricaCandidateDetail(Id) {
    const Url = url.getAfricaCandidateData + "?Id=" + Id; ;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getUniversityCandidateDetail(Id) {
    const Url = url.getUniversityCandidateData + "?Id=" + Id; ;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  approveCandidates(data: { CandidateList: string[] }): any {
    const Url = url.approveCandidatesURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  previousSessionCandidatesReportApi(data): any {
    const Url = url.PreviousSessionCandidatesReport;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  
  schoolPreviousStudentData(data): any {
    const Url = url.schoolPreviousStudentData;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  helpNeedDatta(data):any {
    const Url = url.helpNeedDatta;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  organisationdonationData(data):any {
    const Url = url.organisationdonationData;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  individualdonationData(data):any {
    const Url = url.individualdonationData;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  regenrateSlip(): any {
    throw new Error("Method not implemented.");
  }

  constructor(private httpClient: HttpClient) {}
  getIsResultPublishedURI() {
    const Url = url.getIsResultPublishedURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  updateCandidate(data) {
    const Url = url.saveCandidateData;
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (key == "Picture") {
        var x = data[key];
        formData.append("file", x);
      } else formData.append(key, data[key]);
    });
    return this.httpClient.post(Url, data);
  }

  login(data) {
    const Url = url.login;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      observe: "response" as "response"
    };
    return this.httpClient.post(Url, data, httpOptions);
  }
  emailVerify(data) {
    const Url = url.validateEmail;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  phoneVerify(data) {
    const Url = url.validateNumber;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  forgetPassword(forgetEmail) {
    const Url = url.forgetPassword + "?emailId=" + forgetEmail;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getStates() {
    const Url = url.getStates;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
   getStatesofCountries(data) {
    const Url = "https://countriesnow.space/api/v0.1/countries/states/";
  //   const sdata ={
  //     "country": "india"
  // }
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data ,httpOptions)
      // .map(response => response)
      // .catch((error: any) => {
      //   return Observable.throw(error.error);
      // });
  }
  getCountries() {
    const Url = "https://countriesnow.space/api/v0.1/countries/states";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      // .map(response => response)
      // .catch((error: any) => {
      //   return Observable.throw(error.error);
      // });
  }
  getStatefromCountry(id){
    const Url = url.getStatefromCOuntry+id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getCountry(){
    const Url = url.getCountry;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });

  }
  getSchoolType() {
    const Url = url.getSchoolType;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  registerSchool(data) {
    const Url = url.registerSchool;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  CandidateRegister(data){
    const Url = url.registerCandidate;
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (key == "ProfilePic") {
        var x = data[key];
        formData.append("file", x);
      } else formData.append(key, data[key]);
    });
    return this.httpClient
      .post(Url, formData)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  CandidateUniversityRegister(data){
    const Url = url.registerUniversityCandidate;
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (key == "ProfilePic") {
        var x = data[key];
        formData.append("file", x);
      } else formData.append(key, data[key]);
    });
    return this.httpClient
      .post(Url, formData)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getConcernType() {
    const Url = url.concertType;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  contactUs(data) {
    const Url = url.contactUs;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  createCenter(data) {
    const Url = url.createCenter;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getCenterList(data) {
    const Url = url.getCenterList;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  deleteCenter(id) {
    const Url = url.deleteCenter + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getExamCenterById(id) {
    const Url = url.getCenterById + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx EMAIL VALIDATOR AND FUNCTION xxxxxxxxxxxxxxxx
  emailExist(data) {
    const Url = url.validateEmail;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    // debounce
    return timer(1000).pipe(
      switchMap(() => {
        // Check if username is available
        return this.httpClient.post(Url, data, httpOptions);
      })
    );
  }
  emailExistValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      return this.emailExist(control.value).pipe(
        map(res => {
          // if username is already taken
          if (res == false) {
            // return error
            return { emailTaken: true };
          } else {
            return null;
          }
        })
      );
    };
  }
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  //xxxxxxxxxxxxxxxxxxxxxxxxx PHONE VALIDATOR AND FUNCTION xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  phoneExist(data) {
    const Url = url.validateNumber;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    // debounce
    return timer(100).pipe(
      switchMap(() => {
        // Check if username is available
        return this.httpClient.post(Url, data, httpOptions);
      }),
      catchError(this.handleError)
    );
  }

  handleError(error: HttpErrorResponse) {
    return Observable.of(false);
  }
  dummy: Subject<any> = new Subject<any>();

  phoneExistValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      if (control.value) {
        return this.phoneExist(control.value).pipe(
          map(res => {
            if (control.value.length == 0) {
              return;
            }
            if (control.value.length != 0 && control.value.length != 11) {
              return { invalidNumber: true };
            }
            // if username is already taken
            if (res == false) {
              // return error

              return { phoneTaken: true };
            }
            if (!res) {
              return { phoneTaken: true };
            }
          })
        );
      } else {
        this.returnDummy();
        return this.dummy.asObservable();
      }
    };
  }

  returnDummy(): any {
    setTimeout(() => {
      this.dummy.next(null);
    }, 200);
  }

  phoneExistValidatorforNullables(val): AsyncValidatorFn {
    // if(val == "nullValue"){
    //   return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
    //     return true;
    //   }
    // }
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      return this.phoneExist(control.value).pipe(
        map(res => {
          // if username is already taken
          if (res == false) {
            // return error
            return { phoneTaken: true };
          }
        })
      );
    };
  }
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  getCandidateList(data) {
    const Url = url.getCandidateList;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getUniversityCandidateList(data) {
    const Url = url.candidateGetAllUniversitycandidate;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }


  getManualCandidateList(data) {
    const Url = url.getAllManualCandidate;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  uploadManualCandidates(data) {
    const Url = url.uploadManualCandidates;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  attendanceSheet(data) {
    const Url = url.attendanceSheet;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  mas() {
    const Url = url.mas;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  importQuestion(data) {
    const Url = url.importQuestion;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  importCompendium(data) {
    const Url = url.importCompendium;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  importSyllabus(data) {
    const Url = url.importSyllabus;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  deleteUploadCompendium(data) {
    const Url = url.deleteUploadCompendium;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  deleteUploadSyllabus(data) {
    const Url = url.deleteSyllabus;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  deleteUploDocument(data) {
    const Url = url.deleteUploDocument;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  uploadMarks(data) {
    const Url = url.uploadMarks;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  uploadadvMarks(data) {
    const Url = url.uploadadvMarks;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  updateCondidateScore(body: any): any {
    const Url = url.updateScore;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, body, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  // updateAdvCondidateScore(body: any): any {
  //   const Url = url.updateAdvScore;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json"
  //     })
  //   };
  //   return this.httpClient
  //     .post(Url, body, httpOptions)
  //     .map(response => response)
  //     .catch((error: any) => {
  //       return Observable.throw(error.error);
  //     });
  // }
   updateAfricaCandidateScore(body: any): any {
    const Url = url.updateAfricaScore;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, body, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
   updateUniversityCandidateScore(body: any): any {
    const Url = url.updateUniversityScore;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, body, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  deleteCandidate(deleteId) {
    const Url = url.deleteCandidateData + "?id=" + deleteId;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getSyllabus() {
    const Url = url.getSyllabus;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  deleteSyllabus(data) {
    const Url = url.deleteSyllabus + data;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  addSyllabus(data) {
    const Url = url.importSyllabus;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getPublishResult(data) {
    const Url = url.getPublishResult;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getAfricaPublishResult(data) {
    const Url = url.getAfricaCandidatePublishResult;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getUniversityPublishResult(data){
    const Url = url.getUniversityPublishResult;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getTop3Result(data) {
    const Url = url.getTop3Result;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getAdvanceFinalPublishResult(data) {
    const Url = url.getAdvanceFinalCandidatePublishResult;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getAdvanceTop3Result(data) {
    const Url = url.getTop3AdvanceCandidatePublishResult;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getFinalPublishResult(data) {
    const Url = url.getFinalPublishResult;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  // admin user Role
  addAdminUserRoleApi(data): any {
    const Url = url.addAdminUserRole;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getAdminUserRolesApi():any {
    const Url = url.getAdminUserRoles;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getAdminUserRolesByIdApi(id):any {
    const Url = url.getUserRolesById + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  addUpdatePrepClassesApi(data):any {
    const Url = url.AddUpdatePrepClasses;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  ContactUsdetailsApi(data):any {
    const Url = url.ContactUsdetails;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  
  CountryListApi():any {
    const Url = url.countryList;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  ContinentalRegistrationReportApi(data) {
    const Url = url.ContinentalRegistrationReport;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  ContinentalCountryRatingReportApi(data) {
    const Url = url.ContinentalCountryRatingReport;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  ContactDetailsReportApi(data) {
    const Url = url.ContactDetailsReport;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  ContinentalPercentageReportApi(data) {
    const Url = url.ContinentalPercentageReport;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  ContinentalAverageAgeReportApi(data) {
    const Url = url.ContinentalAverageAgeReport;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  } 
  advanceCandidateListApi(data){
    const Url = url.advanceCandidateList;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  paymentIntializationApi(data):any {
    const Url = url.paymentIntialization;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  
  paymentSucessfulApi(data):any {
    const Url = url.paymentSuccessfulTransaction;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  transactionFailedApi(data):any {
    const Url = url.failedTransaction + data;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getHomeSeasonDetail() {
    const Url = url.getHomeSeasonDetail;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  registerEmail(data) {
    const Url = url.registerEmail + data.CandidateEmail + '&regionType=' +data.Type;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getCourseOfStudyApi(){
    const Url = url.getCourseOfStudy;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getUniversityCourseOfStudyApi(){
    const Url = url.getUniversityCourseOfStudy;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getUniversityOfStudyApi(){
    const Url = url.getUniversityOfStudy;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  // 
  HallOfFameApi(data):any {
    const Url = url.hallOfFame;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  universityHonourRollDetailApi(data):any {
    const Url = url.universityHonourRollDetail;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  nigeriaHonourRollDetailApi(data):any {
    const Url = url.nigeriaHonourRollDetail;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  africaHonourRollDetailApi(data):any {
    const Url = url.africaHonourRollDetail;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url,data,httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
// hall of fame
  uploadhofcandidateApi(data){
    const Url = url.uploadhofcandidate;
    return this.httpClient
      .post(Url, data)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
}
