import { FormGroup } from '@angular/forms';

export function DuplicateMailVerify(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const personlMail = formGroup.controls[controlName];
        const parentMail = formGroup.controls[matchingControlName];
        if (parentMail.errors && !parentMail.errors.duplicateMial ) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        if ((personlMail.value == parentMail.value)) {
            parentMail.setErrors({ duplicateMial: true });
        } else {
            parentMail.setErrors(null);
        }
   
    }
}
// CandidateEmail
// ParentEmail