import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
  ValidatorFn,
} from "@angular/forms";
import * as pattern from "../../model/user";
import { HomeServiceService } from "../../_service/home-service.service";
import { AlertService } from "../../_service/alert.service";
// import { PasswordValidation } from './PasswordValidation';
import { ActivatedRoute } from "@angular/router";
import { HomeSecondService } from "../../_service/home-second.service";
import { CustomValidation } from "./PasswordValidation";
import { Router } from "@angular/router";
import { MustMatch } from "./password-match.validator";
import { CountryStates } from "src/app/_models/school-registration-model";

declare var $: any;
@Component({
  selector: "app-school-registration",
  templateUrl: "./school-registration.component.html",
  styleUrls: ["./school-registration.component.css"],
})
export class SchoolRegistrationComponent implements OnInit {
  // show = false;
  password;
  submittedError: boolean = false;
  public states: any;
  CountryStates: CountryStates = new CountryStates();
  public loader: boolean;
  public userProfile: any;
  schoolRegForm: FormGroup;
  schoolType: any;
  isSubmitted: boolean = false;
  public passwordType: string = "password";
  public hideShowIcon: string = "fa fa-eye-slash";
  show: boolean = false;
  public schoolDetails: any = {
    Id: 0,
  };
  countryList: any = [];
  statesList: any = [];
  affiliatedList: any = [
    { value: "Primary School", name: "Primary School" },
    { value: "Junior Secondary", name: "Junior Secondary" },
    { value: "Senior Secondary", name: "Senior Secondary" },
    { value: "A-Levels", name: "A-Levels" },
    { value: "Tertiary", name: "Tertiary" },
  ];
  PrivateList: any = [
    { value: "Christian", name: "Christian" },
    { value: "Islamic", name: "Islamic" },
    { value: "Catholic", name: "Catholic" },
    { value: "Non-Religious", name: "Non-Religious" },
  ];
  schoolEnrolmentList: any = [
    { value: "1-100", name: "1-100" },
    { value: "101-200", name: "101-200" },
    { value: "201-300", name: "201-300" },
    { value: "301-400", name: "301-400" },
    { value: "401-500", name: "401-500" },
    { value: "501-600", name: "501-600" },
    { value: "601-700", name: "601-700" },
    { value: "701-800", name: "701-800" },
    { value: "801-900", name: "801-900" },
    { value: "901-1000", name: "901-1000" },
  ];
  phone: any;
  resData: Object;
  statesname: any;
  schoolId: any;
  getStateValue: any;
  constructor(
    private formBuilder: FormBuilder,
    private service: HomeServiceService,
    private secondService: HomeSecondService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private _router: Router,
    private el: ElementRef
  ) { }

  ngOnInit() {
    //new code
    window.scroll(0, 0)
    this.password = 'password'
    this.userProfile = JSON.parse(localStorage.getItem("logincredential"));

    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.schoolRegForm = this.formBuilder.group(
      {
        Id: [""],
        SchoolName: ["", [Validators.required]],
        SchoolEmail: ["", [Validators.required, Validators.pattern(emailregex)]],
        SchoolPhone: [
          "",
          [
            Validators.required,
            Validators.minLength(7),
            Validators.pattern(pattern.phonePattern),
          ],
          // this.service.phoneExistValidator(),
        ],
        Address: ["", [Validators.required]],
        SchoolCategoryType: ["", [Validators.required]],
        SchoolType: ["", [Validators.required]],
        State: ["", [Validators.required]],
        PrimaryContactName: ["", [Validators.required]],
        PrimaryContactPhone: [
          "",
          [
            Validators.required,
            Validators.minLength(7),
            Validators.pattern(pattern.phonePattern),
          ],
        ],
        PrimaryContactEmail: ["", [Validators.required, Validators.pattern(emailregex)]],
        Password: [""],
        ConfirmPassword: [""],
        Country: [""],
        AffiliatedSchool: new FormArray([], this.minSelectedCheckboxes(1)),
        PrincipalName: ["", [Validators.required]],
        PrincipalPhone: [
          "",
          [
            Validators.required,
            Validators.minLength(7),
          ],
        ],
        MathTutorName: ["", [Validators.required]],
        MathTutorEmail: ["", [Validators.required, Validators.pattern(emailregex)]],
        MathTutorPhone: ["",
          [
            Validators.required,
            Validators.minLength(7),
            Validators.pattern(pattern.phonePattern),
          ],
          // this.service.phoneExistValidator(),
        ],
        AltMathTutorName: [""],
        AltMathTutorEmail: ["", [Validators.pattern(emailregex)]],
        AltMathTutorPhone: ["",
          [
            Validators.minLength(7),
            Validators.pattern(pattern.phonePattern),
          ],
          // this.service.phoneExistValidator(),
        ],
        PrivateType: ["", [Validators.required]],
        SchoolRange: ["", [Validators.required]],
        PrimaryContactAlternateEmail: [
          "",
          [Validators.pattern(emailregex)],
        ],
        PostalAddress: [""],
        FlagSchoolConfirmed: [false],
        FlagCanChangeState: [true],
        FlagPrimaryContactConfirmed: [false],
        FlagCanDelete: [true],
        FlagCanEditSchoolType: [true],
        FlagCanEditSchoolCategory: [true],
        FlagCanChangeStateSchoolEmail: [true],
      },
      { validator: MustMatch("Password", "ConfirmPassword") }
    );
    this.addCheckBoxes()
    // this.getStates();
    this.getSchoolType();
    this.getCountry();
    this.schoolId = parseInt(this.route.snapshot.paramMap.get("id"));
    if (!isNaN(this.schoolId)) {
      if (this.schoolId > 0) {
        this.getSchoolDetails(this.schoolId);

      }
    } else {
      this.schoolId = "NotRegistered";
      this.schoolRegForm.controls["Password"].setValidators([
        Validators.required,
      ]);
      this.schoolRegForm.controls["ConfirmPassword"].setValidators([
        Validators.required,
      ]);
    }
  }

  // init affiliated school checkboxes
  addCheckBoxes() {
    this.affiliatedList.map((o, i) => {
      const control = new FormControl();
      (this.schoolRegForm.controls.AffiliatedSchool as FormArray).push(control)
    })
  }

  get affiliatedSchoolArray() {
    return this.schoolRegForm.controls.AffiliatedSchool as FormArray
  }


  // new code
  // get formArr() {
  //   return this.schoolRegForm.get('AffiliatedSchool') as FormArray;
  // }
  get s() {
    return this.schoolRegForm.controls;
  }
  nameValidations(event) {
    var regex = new RegExp("^[\\sa-zA-Z'-]*$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  numbersOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getSchoolType() {
    this.service.getSchoolType().subscribe(
      (res) => {
        res[0].SchoolType = 'Male'
        res[1].SchoolType = 'Female'
        res[2].SchoolType = 'Both'
        this.schoolType = res;
      },
      (error) => { }
    );
  }
  // onCheckboxChange(event) {
  //   const formArray: FormArray = this.schoolRegForm.get(
  //     "AffiliatedSchool"
  //   ) as FormArray;

  //   /* Selected */
  //   if (event.target.checked) {
  //     // Add a new control in the arrayForm
  //     formArray.push(new FormControl(event.target.value));
  //   } else {
  //   /* unselected */
  //     // find the unselected element
  //     let i: number = 0;

  //     formArray.controls.forEach((ctrl: FormControl) => {
  //       if (ctrl.value == event.target.value) {
  //         // Remove the unselected element from the arrayForm
  //         formArray.removeAt(i);
  //         return;
  //       }

  //       i++;
  //     });
  //   }
  // }
  // getSelectedAffiliatedSchoolValue() {
  //   const selectedIds = this.schoolRegForm.value.AffiliatedSchool.map((checked, i) => checked ? this.affiliatedList[i].value : null).filter(v => v !== null)
  //   this.schoolRegForm.value.AffiliatedSchool = selectedIds
  //   return selectedIds
  // }
  // get affiliatedSchoolArray() {
  //   return this.schoolRegForm.controls.AffiliatedSchool as FormArray
  // }

  getSelectedAffiliatedSchoolValue() {
    let selectedIds = this.schoolRegForm.value.AffiliatedSchool.map((checked, i) => checked ? this.affiliatedList[i].value : null).filter(v => v != null)
    this.schoolRegForm.value.AffiliatedSchool = this.getCheckedValue(selectedIds)
  }

  getCheckedValue(selectedIds) {
    let result = []
    selectedIds.forEach(x => {
      this.affiliatedList.find(c => {
        if (x === c.value) {
          result.push(x)
        }
      })
    })
    return result
  }

  schoolRegistration(form) {
    this.isSubmitted = true;
    this.getSelectedAffiliatedSchoolValue()
    if (this.schoolRegForm.invalid) {
      this.submittedError = true;
      for (const key of Object.keys(this.schoolRegForm.controls)) {
        if (this.schoolRegForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          // const inv = this.el.nativeElement.querySelector('[formArrayName="' + key + '"]');
          if(invalidControl != null){
            invalidControl.focus();
          }
        
          break;
        }
      }
      return;
    }
    if (this.schoolId > 0) {
      this.loader = true;
      console.log(this.schoolRegForm);
      this.secondService.updateSchool(this.schoolRegForm.value).subscribe(
        (res) => {
          this.loader = false;
          if (res) this.alertService.success(res.toString(), true)
          setTimeout(() => {
            console.log('hold')
          }, 20000)
          if (this.userProfile && this.userProfile.body.RoleName == "Admin") {
            this.alertService.success("School Added Successfully");
            this._router.navigateByUrl("admin/schools");
          }
          else if(this.userProfile && this.userProfile.body.RoleName == "SuperAdmin"){
            this.alertService.success("School Added Successfully");
            this._router.navigateByUrl("super-admin/schools");
          }
          else {
            this.alertService.success("School Updated Successfully");
            this._router.navigateByUrl("school/schooldashboard");
          }
          this.schoolRegForm.reset();
        },
        (err) => {
          this.loader = false;
          this.alertService.error(err, true);
        }
      );
    }

    if (this.schoolId == "NotRegistered" || this.schoolId == 0) {
      if (this.userProfile && this.userProfile.body.RoleName == "Admin") {
        form.value.Password = Math.random().toString(36).slice(2, 15)
        console.log(form.value)
      }
      form.value.Id = this.schoolDetails.Id;
      form.value.Password = btoa(this.schoolRegForm.value.Password);
      form.value.ConfirmPassword = btoa(
        this.schoolRegForm.value.ConfirmPassword
      );
      this.loader = true;
      this.service.registerSchool(form.value).subscribe(
        (res) => {
          this.loader = false;
          if (res) this.alertService.success(res.toString(), true);
          if (this.userProfile && this.userProfile.body.RoleName == "Admin") {
            // if (res)
            this.alertService.success("School Added Successfully");
            this._router.navigateByUrl("admin/schools");
            return
          }
          this.schoolRegForm.reset();
          this._router.navigateByUrl("login");
        },
        (error) => {
          this.loader = false;
          this.alertService.error(error, true);

        }
      );
    }
  }
  // confirmRegistration(){
  //   this.schoolRegistration(this.schoolRegForm.value)
  // }
  getStatesname(id) {

    this.service.getStatefromCountry(id).subscribe(
      (res: any) => {
        this.statesList = res;
      },
      (error) => { }
    );
  }
  stateAndCountry() {
    if (this.schoolId > 0) {
      $("#stateChangeModel").modal("show");
    }
  }
  getCountry() {
    this.service.getCountry().subscribe(
      (res: any) => {
        this.countryList = res;
      },
      (error) => { }
    );
  }
  showPassword() {
    if (this.password === "password") {
      this.password = "text";
      this.show = true;
    } else {
      this.password = "password";
      this.show = false;
    }
  }
  getSchoolDetails(id) {
    this.secondService.getSchool(id).subscribe((res: any) => {
      if (res.SchoolCategoryType == 'Public') {
        this.schoolRegForm.get("PrivateType").clearValidators();
        this.schoolRegForm.get("PrivateType").updateValueAndValidity();
      }
      // this.getCountry = res.Country
      this.getStatesname(res.Country)
      this.getStateValue = res.State
      res.Password = "*********";
      res.ConfirmPassword = "*********";
      let fieldArray = []
      for (let i = 0; i < this.affiliatedList.length; i++) {
        fieldArray.push(null)
      }
      for (let i = 0; i < res.AffiliatedSchool.length; i++) {
        let fieldIndex = this.affiliatedList.findIndex(x => x.value === res.AffiliatedSchool[i])
        fieldArray[fieldIndex] = true
      }
      res.AffiliatedSchool = fieldArray
      this.schoolRegForm.patchValue(res);
      console.log(this.schoolRegForm)
    });
  }
  selectCategory(value) {
    if (value == "Public") {
      this.schoolRegForm.get("PrivateType").clearValidators();
      this.schoolRegForm.get("PrivateType").updateValueAndValidity();
    } else if (value == "Private") {
      this.schoolRegForm
        .get("PrivateType")
        .setValidators([Validators.required]);
      this.schoolRegForm.get("PrivateType").updateValueAndValidity();
    }
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }
}
