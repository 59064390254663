import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moments from "moment";
import { AlertService } from '../_service/alert.service';
import { HomeServiceService } from '../_service/home-service.service';
declare var $: any;


@Component({
  selector: 'app-prep-classes',
  templateUrl: './prep-classes.component.html',
  styleUrls: ['./prep-classes.component.css']
})
export class PrepClassesComponent implements OnInit {
  prepsignUpForm: FormGroup;
  isSubmitted: Boolean = false
  timeDropDownList = [];
  getTimeSlot: { id: number; value: string; }[];
  loader: boolean;
  nigeria: boolean = false;
  africa: boolean;
  classList: any;
  countryType: string;
  constructor(private fb: FormBuilder, private service: HomeServiceService, private alert: AlertService, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.countryType = localStorage.getItem('type')
    console.log(this.countryType)
    if(this.countryType){
      this.countryType = localStorage.getItem('type')
    }
    else {
      this.countryType = 'Nigeria'
    }
    this.prepSignUp()
    this.getTimeSlot = [{ id: 1, value: '9am-10am' },
    { id: 2, value: '12pm-1pm' },
    { id: 3, value: '3pm-4pm' }]
    this.getCourseOfStudy()
  }
  prepSignUp() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.prepsignUpForm = this.fb.group({
      userName: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(emailregex)]],
      Phone: ["", [Validators.required,
      Validators.minLength(7)]],
      Class: ["", [Validators.required]],
      preferedTime: ["", [Validators.required]],
      ParentName: ["", [Validators.required]],
      Parent2Name: [""],
      ParentRelation: ["", [Validators.required]],
      Parent2Relation: [""],
      ParentPhone: ["", [Validators.required,
      Validators.minLength(7)]],
      Parent2Phone: ["", [
        Validators.minLength(7)]],
      ParentEmail: ["", [Validators.required, Validators.pattern(emailregex)]],
      Parent2Email: ["", [Validators.pattern(emailregex)]],
      Type: ['Africa']

    })
  }
  prepSignUpSubmit(form) {
    this.isSubmitted = true
    if (this.prepsignUpForm.invalid) {
      return
    }
    this.loader = true
    this.service.addUpdatePrepClassesApi(form.value).subscribe(
      (res: any) => {
        this.loader = false
        $("#prepClassModel").modal("show");
        // this.alert.success("Prep Class Registered", true)
        this.prepsignUpForm.reset()
        this.isSubmitted = false
      },
      (error) => {
        this.loader = false
        this.alert.error(error, true)
      }
    );
  }
  get s() {
    return this.prepsignUpForm.controls
  }
  nameValidations(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  getCourseOfStudy() {
    this.service.getCourseOfStudyApi().subscribe((res: any) => {
      if (res) {
        this.classList = res
      }
    })
  }
  numbersOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
