import { Component, OnInit, Input } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { ShareDataService } from "src/app/_shared/share-data.service";
import { OnChanges } from "@angular/core";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  public navbarClass: string = "";
  public logo: string = "./../../assets/img/logo-colour.png";
  public logoColor: string = "./../../assets/img/logo-colour.png";
  public showClass = "";
  public afterLoginHeader: boolean = false;
  imageLink: any;
  currentUrl: any;
  orangeHeader: boolean = false;

  @Input() event: String;
  constructor(private router: Router, private shareData: ShareDataService) {
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        window.scroll(0, 0);
        var url = document.location.pathname;
        this.currentUrl = url;
        if (
          this.event == undefined ||
          this.event == "default message" || this.event == 'undefined'
        ) {
          if (localStorage.getItem("user_image") != undefined) {
            this.event = localStorage.getItem("user_image");
          }
          else {
            this.event = "undefined";
          }
        }
        if (url == "/" || url == "/home") {
          if (localStorage.getItem("auth_token") != undefined) {          
            this.navbarClass = "";
            this.afterLoginHeader = true;
            this.orangeHeader = true;
          } else {           
            this.navbarClass = "navbar-home";
            this.logo = "./../../assets/img/logo-white.png";
            this.afterLoginHeader = false;
            this.orangeHeader = false;
          }
        } else if (
          url == "/our-mission" ||
          url == "/contact" ||          
          url == "/join" ||
          url.includes("/join/step-3") ||
          url.includes("/events-info/") ||
          url == "/login" ||
          url == "/forgot-password" ||
          url=="/terms-conditions" ||
          url =="/privacy-policy"          
        ) {
          this.orangeHeader = true;
          this.navbarClass = "";
          this.headerProperty();
        } else if(this.router.url == "/business")
        {
           this.navbarClass = "navbar-home";
           if (localStorage.getItem("auth_token") != undefined) {             
                     this.afterLoginHeader = true;
                     this.orangeHeader = true;                  
                    }       
        }else
         {
             if (localStorage.getItem("auth_token") != undefined) {
                if(this.router.url == "/business"){
                     this.navbarClass = "navbar-home";
                     this.afterLoginHeader = true;
                     this.orangeHeader = true;                  
                     }
                 else
                    {           
                     this.navbarClass = "";
                     this.afterLoginHeader = true;
                     this.orangeHeader = true;
                     }
            
               } 
              else 
              {               
                 this.navbarClass = "navbar-home";
                 this.logo = "./../../assets/img/logo-white.png";
                 this.afterLoginHeader = false;
                 this.orangeHeader = false;
           }
        }
      }
    });
  }

  
  ngOnInit() {
    if ( this.currentUrl == "/" || this.currentUrl == "/home" ) 
      {
          if (localStorage.getItem("auth_token") != undefined) {
             this.navbarClass = "";
             this.afterLoginHeader = true;
          } else
           {       
            this.navbarClass = "navbar-home";
            this.logo = "./../../assets/img/logo-white.png";
            this.afterLoginHeader = false;
          }
     }
    else if(
      this.router.url == "/our-mission" ||
      this.router.url == "/contact" ||      
      this.router.url == "/join" ||
      this.router.url.includes("/join/step-3") ||
      this.router.url.includes("/events-info/") ||
      this.router.url == "/login" ||
      this.router.url == "/forgot-password" ||
      this.router.url=="/terms-conditions" ||
      this.router.url =="/privacy-policy"
     ) 
     {
      this.orangeHeader = true;
      this.navbarClass = "";      
      this.headerProperty();
     }
    else if(this.router.url == "/business")
    {
        this.navbarClass = "navbar-home";
            if (localStorage.getItem("auth_token") != undefined) {             
                     this.afterLoginHeader = true;
                     this.orangeHeader = true;                  
                   }
    } 
    else {     
         if (localStorage.getItem("auth_token") != undefined) {
            if(this.router.url == "/business"){
                     this.navbarClass = "navbar-home";
                     this.afterLoginHeader = true;
                     this.orangeHeader = true;                  
                }                
                else
                {           
                  this.navbarClass = "";
                  this.afterLoginHeader = true;
                  this.orangeHeader = true;           
                }
           
     if (
          this.event == undefined ||
          this.event == "default message" || this.event == 'undefined'
        ) 
        {
             if (localStorage.getItem("user_image") != undefined) {
             this.event = localStorage.getItem("user_image");
                 }
            else {
            this.event = "undefined";
             }
        }
          }
           else {
            this.navbarClass = "navbar-home";
            this.logo = "./../../assets/img/logo-white.png";
            this.afterLoginHeader = false;
            this.orangeHeader = false;
          }   
         }
  }

  headerProperty() { 
    if (localStorage.getItem("auth_token") != undefined) {
      this.navbarClass = "";
      this.afterLoginHeader = true;
    } else {
      this.navbarClass = "";
      this.logo = "./../../assets/img/logo-white.png";
      this.afterLoginHeader = false;
    }
  }

handleHamburgerIcon(type) {
    if (type === "add") this.showClass = "show";
    else this.showClass = "";
  }

  logout() {
    localStorage.clear();
    // if (this.router.url == "/") {
    //   // location.reload();
    //   return;
    // }
    this.router.navigate(["/main"]);
  }
}
