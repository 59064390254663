import { Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[showHidePassword]"
})
export class ShowHidePassword {
  private _shown = false;

  constructor(private el: ElementRef) {
    this.setup();
  }

  toggle(div: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute("type", "text");
      let icon = <Element>div.childNodes[0];
      icon.className = "fa fa-eye";
    } else {
      this.el.nativeElement.setAttribute("type", "password");
      let icon = <Element>div.childNodes[0];
      icon.className = "fa fa-eye-slash";
    }
  }

  setup() {
    const parent = this.el.nativeElement.parentNode;
    const div = document.createElement("div");
    div.setAttribute("class", "input-group-addon");
    const icon = document.createElement("i");
    icon.setAttribute("class", "fa fa-eye-slash");
    div.appendChild(icon);
    div.addEventListener("click", event => {
      this.toggle(div);
    });
    parent.appendChild(div);
  }
}
