import { OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { Component, Input } from "@angular/core";
import {
  PaginationModel,
  PropertyMetaModel,
  GridColumnType
} from "src/app/_models/pagination-model";
import { PdfHelper } from "src/app/_models/pdf-helper";
import { Router } from "@angular/router";


@Component({
  selector: "app-data-grid",
  templateUrl: "./data-grid.component.html",
  styleUrls: ["./data-grid.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataGridComponent implements OnChanges {
  @Input() public gridData: any[];
  @Input() public pageInfo: PaginationModel;
  @Input() public displayColumns: PropertyMetaModel[];
  @Output() pageInfoChange = new EventEmitter<PaginationModel>();
  @Output() action = new EventEmitter<any>();

  public cols: PropertyMetaModel[];
  public totalPage: any[];
  public indexer = 0;
  showPagination: boolean;
  LastPage: number;
  ngOnChanges(changes: SimpleChanges): void {
    this.setColumns();
    this.setPagination();
  }
  ngDoCheck() {
    this.cd.detectChanges();
  }
  setPagination(): any {
    
    if (this.pageInfo && this.pageInfo.TotalRecord > this.pageInfo.PageSize) {
      var totalPages = Math.ceil(
        this.pageInfo.TotalRecord / this.pageInfo.PageSize
      );
      this.LastPage = totalPages;
      this.totalPage = this.getVisiblePageIndex(
        totalPages,
        this.pageInfo.CurrentPage
      );
      this.showPagination = true;
    } else {
      this.showPagination = false;
    }
  }


    constructor(private _router: Router, private cd: ChangeDetectorRef) { }
  setColumns() {
    if (this.displayColumns) {
      this.cols = Object.assign([], this.displayColumns);
    } else {
      if (this.gridData && this.gridData.length > 0) {
        let props = Object.keys(this.gridData[0]).filter(
          x => !x.toLowerCase().endsWith("id")
        );
        this.cols = props.map(x => {
          return { ColumnName: x.toUpperCase(), PropertyName: x, PropertyType: GridColumnType.Text };
        });
      } else {
        this.cols = [];
      }
    }
  }

  SetPage(index: number) {
    this.pageInfo.CurrentPage = Number(index);
    this.emitPageInfoChange();
  }

  changePageSize(val) {
    this.pageInfo.PageSize = Number(val);
    this.emitPageInfoChange();
  }

  emitPageInfoChange() {
    this.pageInfoChange.emit(this.pageInfo);
  }

  getVisiblePageIndex(totalPages: number, CurrentPage: number) {
    var array = [];
    if (totalPages > 7) {
      var j = 1;
      for (let i = 0; i < 7 || array.length < 7; i++) {
        if (CurrentPage - (3 - i) >= 1 || CurrentPage - (3 - i) <= totalPages) {
          if (CurrentPage - (3 - i) > 0)
            if (CurrentPage - (3 - i) <= totalPages) {
              array.push(CurrentPage - (3 - i));
            } else {
              array.unshift(CurrentPage - (3 + j));
              j++;
            }
        }
      }
      return array;
    } else {
      for (var i = 1; i <= totalPages; i++) {
        array.push(i);
      }
      return array;
    }
  }

  ActionButtoClick(data: any, action: string, event: any) {
    if (event) {
      event.preventDefault();
    }
    this.action.emit({ action: action, value: data });
  }

  public Sort(col : PropertyMetaModel){
    if(this.pageInfo.SortBy == col.PropertyName || this.pageInfo.SortBy == col.DisplayText){
      this.pageInfo.SortOrder = this.pageInfo.SortOrder == 'asc' ? 'desc' : 'asc';
    }else{
      this.pageInfo.SortOrder = 'desc';
    }
    this.pageInfo.SortBy = col.PropertyType == 3 ? col.DisplayText.toString() : col.PropertyName;
    this.pageInfoChange.emit(this.pageInfo);
  }  
  setRoute(route){
    this._router.navigate([`${route}`])
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}


// pushItems = function(start, end) {
//   var ary = [];
//   for (var i = start; i < end; i++) {
//     ary.push(i);
//   }
//   return ary;
// };

// getvisiblePage(currentIndex: number, lastIndex: number) {
//   var array = [];
//   if(lastIndex<7){
//     for (var i = currentIndex; i <= lastIndex; i++) {
//       array.push(i);
//     }
//   } else if(currentIndex + 3 >= lastIndex) {
//     for (var i = lastIndex; i>lastIndex-7 ; i--) {
//       array.push(i);
//       array.reverse();
//     }
//   } else if(currentIndex - 3 >= lastIndex) {
//     for (var i = lastIndex; i>lastIndex-7 ; i--) {
//       array.push(i);
//       array.reverse();
//     }
//   }
// }
