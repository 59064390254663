import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject, timer, interval, Subscription } from "rxjs";
import * as url from "src/app/model/config";
import { AlertService } from "../_service/alert.service";
import { HomeSecondService } from "../_service/home-second.service";
import { HomeServiceService } from "../_service/home-service.service";
import { SchoolService } from "../_service/school-service.service";
import { ShareDataService } from "../_shared/share-data.service";
declare var $: any;
@Component({
  selector: "africa-home-root",
  templateUrl: "./africa-home.component.html",
  styleUrls: ["./africa-home.component.css"]
})
export class AfricaHomeComponent implements OnInit {
  [x: string]: any;
  regestrationDetails: any;
  isPublished: any;
  categoryType: any;
  Category = [
    { Id: "Junior", CategoryName: "Junior" },
    { Id: "Senior", CategoryName: "Senior" }
  ];
  showRegisterSeniorButton: any;
  showRegisterJuniorButton: any;
  Filters = {
    pageno: 1,
    pagesize: "5",
    isactive: "",
    sortorder: "",
    sortby: "",
    Season: "",
    Category: ""
  };
  seasonList = [];
  SelectedItem = [];
  SelectedItemArray = [];
  List = { TotalRecords: 0, List: [] };
  hideLabelText = null;
  year: any;
  BaseAddress: any;
  noRecordFoundText: any;
  States = [];
  regClosed: boolean = false;
  userProfile: any;
  countDown: Subscription;
  subt: Subscription;
  count;
  registartionStartDate;
  registartionEndDate;
  isSubmitted: boolean = false;
  homeText = "";
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  counter: any;
  currentDate = new Date()
  examinationDate;
  countryType: any;
  regOpensOn: any;
  emailRegister: FormGroup;
  modelSubscriptions: Subscription;


  constructor(
    public alert: AlertService,
    private fb: FormBuilder,
    public service: HomeServiceService,
    public serviceSecond: HomeSecondService,
    private activatedRoute: ActivatedRoute,
    private schoolService: SchoolService,
    private route :Router,
    private _share: ShareDataService,

  ) { 
    this.modelSubscriptions = this._share.registration.subscribe((res: any) => {
      this.registerSession();
    });
  }

  ngOnInit() {
    this.scrollTo()
    this.countryType = localStorage.getItem('type')
    this.service.getHomeSeasonDetail().subscribe((res: any) => {
      if (res) {
        // res = [{ "Id": 2046, "Season": "Season 12", "RegistrationEndDate": "2021-10-2T00:00:00", "RegistrationStartDate": "2021-09-15T00:00:00", "ExamEndDate": "2022-08-24T18:00:00", "ExamStartDate": "2021-06-29T08:00:00", "ExamType": "Online", "Category": "Junior", "SeasonType": "Africa", "Max": 0, "Min": 0, "MinGirls": 0 }, { "Id": 2045, "Season": "new_Africa1", "RegistrationEndDate": "2021-08-15T00:00:00", "RegistrationStartDate": "2021-08-12T00:00:00", "ExamEndDate": "2021-08-25T17:01:00", "ExamStartDate": "2021-08-20T17:01:00", "ExamType": "Online", "Category": "Both", "SeasonType": "Nigeria", "Max": 10, "Min": 5, "MinGirls": 5 }]
        for (let i = 0; i < res.length; i++) {
          console.log(res[i].SeasonType)
          if (res[i].SeasonType == 'Africa') {
            this.regestrationDetails = res[i]
            this.registartionStartDate = new Date(this.regestrationDetails.RegistrationStartDate)
            this.registartionEndDate = new Date(this.regestrationDetails.RegistrationEndDate)
            this.registartionEndDate = new Date(this.registartionEndDate.setHours(this.registartionEndDate.getHours() + 24));
            // this.regestrationDetails = res[i]
            this.categoryType = res[i].Category;
            this.checkCartegory(this.categoryType)
            if (this.registartionStartDate > this.currentDate) {
              this.homeText = "Registartion Opens from"
              this.regClosed = true;
              this.regOpensOn = true;
              // this.registartionStartDate = new Date(this.registartionStartDate.setHours(this.registartionStartDate.getHours() + 24));
              this.examinationDate = new Date(this.registartionStartDate).getTime()
            }
            else if (this.registartionStartDate <= this.currentDate && this.registartionEndDate >= this.currentDate) {
              this.homeText = "Registration Closes by"
              
              this.examinationDate = new Date(this.registartionEndDate).getTime()
            }
            else {
              this.regClosed = true;
              this.homeText = "Registration Closed for the Current Season"
            }
          }
          else if (this.regestrationDetails == undefined) {
            this.regClosed = true;
            this.homeText = "Registration Closed for the Current Season"
          }
        }
        if (res.length == 0) {
          this.regClosed = true;
          this.homeText = "Registration Closed for the Current Season"
        }
      }
      else {
        this.regClosed = true;
        this.homeText = "Registration Closed for the Current Season"
      }
      this.service.regClosedBySeason.next(this.regClosed)
    })

    this.counter = setInterval(() => {
      this.getExaminationCounter();
    }, 1000);
    this.loadScript('assets/js/own.js');
    // this.alert.error("hello",true);
    this.service.getIsResultPublishedURI().subscribe(
      (res: any) => {
        if (res) {
          // var result = JSON.parse(JSON.stringify(res));
          if (res && res.List && res.List[0]) {
            this.isPublished = res.List[0].IsPublished;
          }
          localStorage.setItem("isResultPublished", this.isPublished);
        }
      },
      err => { }
    );
    // this.getSeason();
    this.getYearsList();
    this.userProfile = JSON.parse(localStorage.getItem("ls.userProfile"));


    $("#obejctive-section").click((e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#objective-wrapper").position().top - 90,
        },
        100
      );
    });
    $("#targetSection").click((e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#target-wrapper").position().top - 110,
        },
        500
      );

    });
    $("#stageSection").click((e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#stage-wrapper").position().top - 110,
        },
        500
      );

    });
    $("#regSectionWrapper").click((e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#register-wrapper").position().top + 80,
        },
        500
      );

    });
    $("#prizesSection").click((e) => {
      // var element = document.getElementById('prize-wrapper');
      // element.scrollIntoView(true)
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#prize-wrapper").position().top - 10,
        },
        500
      );

    });
    $("#aboutSection").click((e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#aboutcruxlearning-wrapper").position().top - 110,
        },
        500
      );

    });
    this.createEmailRegisterForm()
  }

  ngOnDestroy() {
    clearInterval(this.counter);
    this.modelSubscriptions.unsubscribe();

  }

  getExaminationCounter() {
    var now = new Date().getTime();
    //console.log(now)

    // Find the distance between now and the count down date
    var distance = this.examinationDate - now;

    // Time calculations for days, hours, minutes and seconds
    this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(this.counter);
    }
  }

  loadScript(src) {
    // var script = document.createElement("script");
    // script.type = "text/javascript";
    // document.getElementsByTagName("head")[0].appendChild(script);
    // script.src = src;
  }

  categoryChange = function (CategoryID) {
    this.Filters.Category = CategoryID;
  };

  setNoRecordMessage = function () {
    if (this.List.TotalRecords == 0 || this.List.TotalRecords == undefined)
      this.noRecordFoundText = "No Question document found.";
    else this.noRecordFoundText = "";
  };

  clearAll = function () {
    this.List.TotalRecords = 0;
    this.List.List = [];
    this.hideLabelText = null;
    this.Filters.Category = "";
    this.year = "";
  };

  loadList = function () {
    this.serviceSecond.LoadQuestions(this.Filters).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.List = res;
          this.BaseAddress = url.portalDocumentLocation;
          this.hideLabelText = res.List.length;
          this.setNoRecordMessage();
        } else {
          this.List.TotalRecords = 0;
          this.List.List = [];
          this.hideLabelText = null;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.setNoRecordMessage();
        this.hideLabelText = null;
        this.List = { TotalRecords: 0, List: [] };
      }
    );
  };

  getYearsList = function () {
    this.serviceSecond.getYears(this.Filters).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.year = res.List;
        } else {
          this.List.TotalRecords = 0;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.List = { TotalRecords: 0, List: [] };
        this.setNoRecordMessage();
      }
    );
  };
  // getSeason() {
  //   this.schoolService.getSeason().subscribe((res:any) =>{
  //    this.seasonList = res.List
  //   }, (err)=>{

  //   })
  //  }

  yearChange = function (year) {
    this.Filters.Season = year;
  };

  scrollTo() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['type'] == 'objective') {
        $("html, body").animate(
          {
            scrollTop: $("#objective-wrapper").position().top - 90,
          },
          0
        );
      }
      if (params['type'] == 'target') {
        $("html, body").animate(
          {
            scrollTop: $("#target-wrapper").position().top - 90,
          },
          0
        );
      }
      if (params['type'] == 'Registration') {
        $("html, body").animate(
          {
            scrollTop: $("#register-wrapper").position().top + 80,
          },
          0
        );
      }
      if (params['type'] == 'stages') {
        $("html, body").animate(
          {
            scrollTop: $("#stage-wrapper").position().top - 110,
          },
          0
        );
      }
      if (params['type'] == 'prizes') {
        // var element = document.getElementById('prize-wrapper');
        // element.scrollIntoView(true)
        $("html, body").animate(
          {
            scrollTop: $("#prize-wrapper").position().top - 110,
          },
          0
        );
      }
      if (params['type'] === 'about-cruxlearning') {
        $("html, body").animate(
          {
            scrollTop: $("#aboutcruxlearning-wrapper").position().top - 110,
          },
          0
        );
      }
    })
  }

  setTimer() {
    let currentDate = new Date();
    let futureDate = new Date("june 1, 2021 10:00:00");
    this.day = Math.floor(
      futureDate.getTime() / (1000 * 60 * 60 * 24) -
      currentDate.getTime() / (1000 * 60 * 60 * 24) -
      1
    );
    this.day = this.day <= 0 ? 0 : this.day
    this.hour = currentDate.getHours() - futureDate.getHours() - 1;
    this.hour = this.hour <= 0 ? 0 : this.hour
    this.minutes = 60 - (currentDate.getMinutes() - futureDate.getMinutes());
    this.seconds = 60 - (currentDate.getSeconds() - futureDate.getSeconds());
    this.myTimer();
  }

  myTimer() {
    //
    //this.count = 100;
    this.countDown = timer(0, 1000).subscribe((x) => {
      --this.count;
      if (this.hour == 0) {
        this.hour = 24;
        if (!(this.day <= 0)) {
          this.day--;
        }
        // if(this.day >= 0){
        //   this.day = 0
        // }
      }
      if (this.minutes == 0 && this.hour > 0) {
        this.minutes = 59;
        this.seconds = 60;
        this.hour = this.hour - 1;
        // this.hour = (this.hour - 1) >= 0 ? 0 : this.hour - 1;
      }
      if (this.seconds == 0) {
        this.seconds = 60;
        this.minutes = this.minutes - 1;
      }

      this.seconds = this.seconds - 1;
      // this.count = this.count - 1;
    });

    this.subt = interval(500).subscribe((x) => {
      if (this.count === 0) {
        this.subt.unsubscribe();
        this.countDown.unsubscribe();

        // this.submitTest();
      }
    });
  }
  getHomeSeasonDetail() {
    this.service.getHomeSeasonDetail().subscribe((res: any) => {
      this.regestrationDetails = res[0]
    })
  }
  checkCartegory(categoryType) {
    if (categoryType == "Both") {
      this.showRegisterJuniorButton = true;
      this.showRegisterSeniorButton = true;
    }
    if (categoryType == "Junior") {
      this.showRegisterJuniorButton = true;
      this.showRegisterSeniorButton = false;
    }
    if (categoryType == "Senior") {
      this.showRegisterSeniorButton = true;
      this.showRegisterJuniorButton = false;
    }

  }

  slides = [
    { img: "./../assets/img/Federal_Nigeria.png" },
    { img: "./../assets/img/kenya_edu.png" },
    { img: "./../assets/img/INterswitch-logoNew.png" },
    { img: "./../assets/img/Federal-education.png" },
    { img: "./../assets/img/NECONew.png" }
  ];
  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "enabled": true,
    "autoplay": true,
    "autoplaySpeed": 2000,
    "arrows": true,

  };
  slideConfig1 = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "enabled": true,
    "autoplay": true,
    "autoplaySpeed": 2000,
    "arrows": true,

  };


  afterChange(e) {
    console.log('afterChange');
  }
  ngAfterViewInit() {
    this.scrollTo()
  }

  countryRedirect(value) {
    this.countryType = value
    localStorage.setItem('type', this.type)
    this.route.navigate(['/home'])
  }
  createEmailRegisterForm(){
    this.emailRegisterForm = this.fb.group({
      CandidateEmail: ["", [Validators.required, Validators.email]],
      Type:['Africa']

    })

  }
  get s() {
    return this.emailRegisterForm.controls;
  }
  registerSession(){
    $("#seasonEditModel").modal("show");



    // [routerLink]="['/africa-signup']"
  }
  signup(){
    if(this.emailRegisterForm.invalid){
      this.isSubmitted = true
      return
    }
    this.service.registerEmail(this.emailRegisterForm.value).subscribe((res: any) => {
      $("#seasonEditModel").modal("hide");
      this.route.navigate(['/africa-signup']);  
    },(err:any)=>{
    })
  }
}
