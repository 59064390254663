import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { SharedModule } from "./_shared/shared.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
// import { SchoolRegistrationComponent } from './school-registration/school-registration.component';
import { TokenInterceptorService } from './_service/token-interceptor.service';
import { AuthServiceService } from './_service/auth-service.service';
import { PagerService } from './_service/pager.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TempComponent } from './_temp/Temp/Temp.component';
import { SchoolAuthGuard } from './AuthGuard/school-auth-service.service';
import { AdminAuthGuard } from './AuthGuard/admin-auth-service.service';
import { ViewResultComponent } from './view-result/view-result.component';
import { AudioPlayPauseComponent } from './home/audio-play-pause/audio-play-pause.component';
import { BlockCopyPasteDirective } from './Directive/app-block-copy-paste.directive';
import { ManagerAuthGuard } from './AuthGuard/manager-auth-guard';
import { CookieService } from 'ngx-cookie-service';
import { SchoolRegistrationGuard } from './AuthGuard/school-registration-guard.service';
import { PrepClassesComponent } from './prep-classes/prep-classes.component';
import { HelpComponent } from './help/help.component';
import { SolicitingHelpComponent } from './help-subComp/soliciting-help/soliciting-help.component';
import { ReadyHelpComponent } from './help-subComp/ready-help/ready-help.component';
import { HelpRegistrationComponent } from './help-subComp/help-registration/help-registration.component';
import { DonateIndividualComponent } from './help-subComp/donate-individual/donate-individual.component';
import { CorporateOrganisationComponent } from './help-subComp/corporate-organisation/corporate-organisation.component';
import { DonateRegComponent } from './help-subComp/donate-reg/donate-reg.component';
import { AdvanceCandidateListComponent } from './advance-section/advance-candidate-list/advance-candidate-list.component';
// import { AdvanceDashboardComponent } from './advance-section/advance-dashboard/advance-dashboard.component';
// import { AdvanceHeaderComponent } from './advance-section/advance-header/advance-header.component';

import { NgxPayPalModule } from 'ngx-paypal';

import { AdvancedViewResultComponent } from './view-result/advanced-view-result/advanced-view-result.component';
import { ContinentalViewResultComponent } from './view-result/continental-view-result/continental-view-result.component';
import { PaymentComponent } from './payment/payment.component';
import { MyDatePickerModule } from 'mydatepicker';
import { ContinentalHomeComponent } from './continental/continental-home/continental-home.component';
import { ContinentalHeaderComponent } from './continental/continental-header/continental-header.component';
import { AdvanceSignupUniversityComponent } from './advance-signup-university/advance-signup-university.component';
import { UniversityStudentRegistrationComponent } from './_shared/university-student-registration/university-student-registration.component';

import { MasterClassesComponent } from './master-classes/master-classes.component';
import { SlickModule } from 'ngx-slick';
import { UniversityHomeComponent } from './univesity-home/unversity-home.component';
import { AfricaHomeComponent } from './africa-home/africa-home.component';
import { UniversutyViewCandidatesComponent } from './admin_management/university-candidates/university-view-candidates/university-view-candidates.component';
import { UniversityContinentalRatingComponent } from './admin_management/university-reports/continental-rating/university-continental-rating.component';
import { UniversityAverageageRegComponent } from './admin_management/university-reports/average-age/university-average-age.component';
import { UniversityContactReportComponent } from './admin_management/university-reports/contact-report/university-contact-report.component';
import { UniversityPercentageRegComponent } from './admin_management/university-reports/percentage-reg/university-percentage.component';
import { UniversityContinentalRegComponent } from './admin_management/university-reports/continental-reg/university-continental-reg.component';
import { HallOfFameComponent } from './hall-of-fame/hall-of-fame.component';
import { HonarsRollComponent } from './honars-roll/honars-roll.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WebcamModule } from 'ngx-webcam';
//import {MatRadioGroup} from '@angular/material/radio';
// import { ChangePasswordComponent } from './change-password/change-password.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FaqsComponent,
    ContactUsComponent,
    LoginComponent,
    ForgetPasswordComponent,
    HomeHeaderComponent,
    TempComponent,
    ViewResultComponent,
    AudioPlayPauseComponent,
    BlockCopyPasteDirective,
    PrepClassesComponent,
    HelpComponent,
    SolicitingHelpComponent,
    ReadyHelpComponent,
    HelpRegistrationComponent,
    DonateIndividualComponent,
    CorporateOrganisationComponent,
    DonateRegComponent,
    AdvancedViewResultComponent,
    ContinentalViewResultComponent,
    PaymentComponent,
    ContinentalHomeComponent,
    ContinentalHeaderComponent,
    AdvanceSignupUniversityComponent,
    UniversityStudentRegistrationComponent,
    MasterClassesComponent,
    UniversityHomeComponent,
    AfricaHomeComponent,
    UniversutyViewCandidatesComponent,
    UniversityContactReportComponent,
    UniversityContinentalRegComponent,
    HallOfFameComponent,
    HonarsRollComponent,
    PageNotFoundComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPayPalModule,
    MyDatePickerModule,
    WebcamModule,
    SlickModule.forRoot()
  ],
  providers: [
    AuthServiceService,
    PagerService,
    SchoolAuthGuard,
    SchoolRegistrationGuard,
    ManagerAuthGuard,
    AdminAuthGuard,
    HttpClientModule,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }





// for sign school 
// http://10.0.0.19:50790/api/portal/portalcommon/addschool   