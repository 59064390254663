import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";
import * as url from "src/app/model/config";
import 'rxjs/add/operator/map';
import { Observable, Observer } from 'rxjs/Rx';
@Injectable({
  providedIn: 'root'
})
export class AdminServiceService {

  constructor(private httpClient: HttpClient) { }

  IsCurrentSeasonPublished()
    {
        const Url = url.IsCurrentSeasonPublished;
        const httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json"
          })
        };
        return this.httpClient
          .get(Url, httpOptions)
          .map(response => response)
          .catch((error: any) => {
            return Observable.throw(error.error);
          });
    }

    getTransactionId()
    {
      const Url = url.getTransactionId;
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      return this.httpClient
        .get(Url, httpOptions)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }

    getFailedRecipients(filter)
    {
      const Url = url.getFailedRecipients;
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      return this.httpClient
        .post(Url,filter,httpOptions)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }

    resendMessageInBulk(body)
    {
      const Url = url.resendMessageInBulk;
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      return this.httpClient
        .post(Url,body,httpOptions)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }

    deleteMessage(deleteId)
    {
      const Url = url.deleteMessage+"?id="+deleteId;
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      return this.httpClient
        .post(Url,httpOptions)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });

    }
    result(filter)
    {
      const Url = url.result;
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      return this.httpClient
        .post(Url,filter,httpOptions)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
    universityResult(filter)
    {
      const Url = url.universityresult;
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      return this.httpClient
        .post(Url,filter,httpOptions)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
    resultAfrica(data){
      
      const Url = url.africaresult;
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      return this.httpClient
        .post(Url,data,httpOptions)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }

    deleteRecord(body)
    {
      const Url = url.deleteRecord;
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      return this.httpClient
        .post(Url,body,httpOptions)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
    emailVerificationApi(data){
      const Url = url.emailVerification + data;
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      return this.httpClient
        .get(Url,httpOptions)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
    getSchoolReportData(data){
      if(data && data.FromDate && data.FromDate.date)
      {
      let fromDate = data.FromDate.date;
      data.FromDate = new Date(fromDate.year, fromDate.month -1, fromDate.day+1).toUTCString();
      }
      if(data && data.ToDate && data.ToDate.date)
      {
      let toDate = data.ToDate.date;
      data.ToDate = new Date(toDate.year, toDate.month-1, toDate.day+1).toUTCString();
      }
      const Url = url.getRegisteredSchools;
      return this.httpClient
        .post(Url,data)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
    getSchoolRegistrationReportByType(data){
      const Url = url.SchoolRegistrationReportByType;
      return this.httpClient
        .post(Url,data)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });

    }
    getSchoolRegistrationReportbyGenderTypeApi(data){
      const Url = url.SchoolRegistrationReportbyGenderType;
      return this.httpClient
        .post(Url,data)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
    getqualifiedReport(data){
      const Url = url.qualifiedReport;
      return this.httpClient
        .post(Url,data)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
    getSchoolRankReport(data){
      const Url = url.SchoolRankReport;
      return this.httpClient
        .post(Url,data)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
    
    getStateRankReport(data){
      const Url = url.StateRankReport;
      return this.httpClient
        .post(Url,data)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }

    getScoreWiseAverageAgeReport(data){
      const Url = url.ScoreWiseAverageAgeReport;
      return this.httpClient
        .post(Url,data)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
    getRegisteredStudentAverageAgeReportApi(data){
      const Url = url.RegisteredStudentAverageAgeReport;
      return this.httpClient
        .post(Url,data)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }

    getSchoolRegistrationReportbyRegistrationCompleteApi(data){
      const Url = url.SchoolRegistrationReportbyRegistrationComplete;
      return this.httpClient
        .post(Url,data)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
    getCandidateReportData(data){
      let fromDate = data.FromDate.date;
      let toDate = data.ToDate.date;
      data.FromDate = new Date(fromDate.year, fromDate.month -1, fromDate.day+1).toUTCString();
      data.ToDate = new Date(toDate.year, toDate.month-1, toDate.day+1).toUTCString();
      const Url = url.getRegisteredStudents;
      return this.httpClient
        .post(Url+data.State,data)
        .map(response => response)
        .catch((error: any) => {
          return Observable.throw(error.error);
        });
    }
}
