import { Component, OnInit, NgZone, ChangeDetectorRef } from "@angular/core";
import { HomeSecondService } from "src/app/_service/home-second.service";
import { HomeServiceService } from "src/app/_service/home-service.service";
import { AlertService } from "src/app/_service/alert.service";
import * as url from "src/app/model/config";
import {
  PaginationModel,
  GridColumnType,
  PropertyMetaModel
} from "src/app/_models/pagination-model";
declare var $: any;
@Component({
  selector: "app-past-questions",
  templateUrl: "./past-questions.component.html",
  styleUrls: ["./past-questions.component.css"]
})
export class PastQuestionsComponent implements OnInit {
  constructor(
    private service: HomeSecondService,
    private serviceHome: HomeServiceService,
    private alert: AlertService,
    private changeRef: ChangeDetectorRef
  ) {
    this.displayColumns = [
      {
        PropertyName: "FileName",
        ColumnName: "Document Name",
        PropertyType: GridColumnType.Text,
        ClassName: "mylink mypointer"
      },
      {
        PropertyName: "Category",
        ColumnName: "Category",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "Season",
        ColumnName: "Year",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "IsActive",
        ColumnName: "Is Active",
        PropertyType: GridColumnType.Boolean
      },
      {
        PropertyName: "Delete",
        ColumnName: "Action",
        PropertyType: GridColumnType.Icon,
        DisplayText: "Delete"
      }
      // { PropertyName: "id", ColumnName: "", PropertyType: GridColumnType.Action, DisplayText:  }
    ];
  }
  public pageInfo: PaginationModel = {
    PageSize: 4,
    CurrentPage: 1,
    TotalRecord: 0
  };
  public gridData: any[] = [];
  public displayColumns: PropertyMetaModel[];
  category: any = "";
  modalCategory: any = "";
  year: any = "";
  Modalyear: any = "";

  yearList: any = [];
  getAllQuestionList: any = [];
  fileList: FileList;
  formData: FormData = new FormData();

  loader: boolean;
  deleteModalData: any;
  ngOnInit() {
    this.getYear();
    this.getAllQuestion();

    var that = this;
    $("#myImportQuestionModal").on("hidden.bs.modal", function() {
      that.ResetValue();
    });
  }

  ResetValue(): any {
    this.modalCategory = "";
    this.Modalyear = "";
    $("#fileSelect").val("");
    this.changeRef.detectChanges();
  }

  getYear() {
    var data = {
      Category: "",
      Season: "",
      isactive: "",
      pageno: this.pageInfo.CurrentPage,
      pagesize: this.pageInfo.PageSize,
      sortby: "",
      sortorder: ""
    };
    this.service.getYears(data).subscribe(
      res => {
        var resp = JSON.parse(JSON.stringify(res));
        this.yearList = resp.List;
      },
      err => {
        console.log("get year negative", err);
      }
    );
  }

  getAllQuestion() {
    this.loader = true;
    var data = {
      Category: this.category,
      Season: this.year,
      isactive: "",
      pageno: this.pageInfo.CurrentPage,
      pagesize: this.pageInfo.PageSize,
      sortby: "",
      sortorder: ""
    };

    this.service.LoadQuestions(data).subscribe(
      res => {
        this.loader = false;
        var resp = JSON.parse(JSON.stringify(res));

        if (resp) {
          this.pageInfo.TotalRecord = resp.TotalRecords;
          this.gridData = resp.List;
          this.gridData.forEach(x => {
            x["link"] =
            url.serviceBaseUrl + "QuestionDocuments/" + x.FileName;
            x["Delete"] = !x.IsDeleted;
          });
        } else {
          this.gridData = [];
        }
        this.loader = false;
      },
      err => {
        this.loader = false;
        console.log("get allquestion negative", err);
      }
    );
  }

  openDeleteModal(item) {
    $("#deleteModel").modal("show");
    this.deleteModalData = item;
  }

  chooseFile(event) {
    this.fileList = event.target.files;
    if (this.fileList.length > 0) {
      let file: File = this.fileList[0];
      if (this.formData.has("file")) {
        this.formData.delete("file");
      }
      this.formData.append("file", file);
    }
  }

  importFileToServer() {
    this.loader = true;
    if (this.fileList == undefined) {
      this.loader = false;
      this.alert.error(
        "No file exist Please select a valid file to import data.",
        true
      );
      return;
    }
    if (this.modalCategory == "" || this.Modalyear == "") {
      this.loader = false;
      this.alert.error("All * fields are mandatory", true);
      return;
    }
    if (this.formData.has("CategoryID") || this.formData.has("Season")) {
      this.formData.delete("CategoryID");
      this.formData.delete("Season");
    }
    this.formData.append("CategoryID", this.modalCategory);
    this.formData.append("Season", this.Modalyear);

    this.serviceHome.importQuestion(this.formData).subscribe(
      res => {
        $("#myImportQuestionModal").modal("hide");
        this.getAllQuestion();
        this.loader = false;
        var resp = JSON.parse(JSON.stringify(res));
        this.alert.success("Past Questions document uploaded.", true);
      },
      err => {
        this.loader = false;
        console.log("upload question negative", err);
        this.alert.error(err, true);
        this.alert.error("Past Questions document upload failed.", true);
      }
    );
  }

  deleteUploadDocument() {
    var data = {
      $$hashKey: "object:679",
      Category: this.deleteModalData.Category,
      FileName: this.deleteModalData.FileName,
      Id: this.deleteModalData.Id,
      IsActive: this.deleteModalData.IsActive,
      IsDeleted: this.deleteModalData.IsDeleted,
      RowNumber: this.deleteModalData.RowNumber,
      Season: this.deleteModalData.Season,
      Season1: this.deleteModalData.Season1
    };
    this.serviceHome.deleteUploDocument(data).subscribe(
      res => {
        $("#deleteModel").modal("hide");
        this.getAllQuestion();
        var resp = JSON.parse(JSON.stringify(res));
        this.alert.success(resp, true);
      },
      err => {
        console.log(" delete negative", err);
        this.alert.error(err, true);
      }
    );
  }
  clearFilters() {
    this.category = "";
    this.year = "";
  }
  pageInfoChange(event) {
    this.pageInfo.CurrentPage = event.CurrentPage;
    this.pageInfo.PageSize = event.PageSize;
    this.getAllQuestion();
  }

  action(event) {
    if (event.action == "Delete") this.openDeleteModal(event.value);
    else if (event.action == "FileName") window.open(event.value.link, "blank");
  }
}
