import { HomeSecondService } from "./../../_service/home-second.service";
import { Component, OnInit } from "@angular/core";
import { HomeServiceService } from "src/app/_service/home-service.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  public year: any;
  countryType: string;
  constructor(private homeService: HomeSecondService, private service :HomeServiceService) {
    this.service.countryType.subscribe((res: string) => {
      if(res){
        this.countryType = res
      }
      else {
        this.countryType = 'Nigeria'
      }
    })
  }

  ngOnInit() {
    this.countryType = localStorage.getItem('type')
    if(this.countryType){
      this.countryType = localStorage.getItem('type')
    }
    else {
      this.countryType = 'Nigeria'
    }
    var body = {};
    this.homeService.getYear().subscribe((res: any) => {
      if (res && res.List.length > 0) {
        this.year = res.List[0].Name;
      }
    });
    {
    }
  }
}
