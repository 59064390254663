import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OnApproveActions, OnApproveData, OnCancelData, OnErrorData } from 'src/app/admin_management/payment/payment/payment-types/buttons';
import { OrderRequest } from 'src/app/admin_management/payment/payment/payment-types/order';
import { PayPalProcessor } from 'src/app/admin_management/payment/payment/payment.component';
import { AlertService } from 'src/app/_service/alert.service';
import { HomeServiceService } from 'src/app/_service/home-service.service';
import * as pattern from "../../model/user";
// payment
// .././payment/payment/payment-types/buttons.ts
// 
enum CheckBoxType { self, behalf, NONE };
declare var $: any;
declare var jic: any;
@Component({
  selector: 'app-donate-individual',
  templateUrl: './donate-individual.component.html',
  styleUrls: ['./donate-individual.component.css'],
  host: { 'class': 'mat-typography' },
  providers: [{ provide: PayPalProcessor, useExisting: forwardRef(() => DonateIndividualComponent) }]
})
export class DonateIndividualComponent implements OnInit {
  fileList: FileList;
  tempImg: string | ArrayBuffer;
  reader = new FileReader();
  uploadStudentImage: any;
  isSubmitted: boolean = false
  materialList: any = [
    { name: "Text Books", value: "Text Books" },
    { name: "Exercise Books", value: "Exercise Books" },
    { name: "Uniform", value: "Uniform" }
  ];
  openMaterialList: boolean = false;
  check_box_type = CheckBoxType;
  currentlyChecked: CheckBoxType;
  statesList: any = [];
  selfForm: boolean = true;
  behalfForm: boolean = false;
  countryList: any;
  stateId: any;
  width = 220;
  height = 35;
  shape = 'rect';
  color = 'gold';
  label = 'paypal';
  layout = 'vertical';

  // order: OrderRequest = {
  //   intent: 'CAPTURE', 
  //   payer: {
  //     name: {
  //       given_name: "PayPal",
  //       surname: "Customer"
  //     },
  //     address: {
  //       address_line_1: '123 ABC Street',
  //       address_line_2: 'Apt 2',
  //       admin_area_2: 'San Jose',
  //       admin_area_1: 'CA',
  //       postal_code: '95121',
  //       country_code: 'US'
  //     },
  //     email_address: "customer@domain.com",
  //     phone: {
  //       phone_type: "MOBILE",
  //       phone_number: {
  //         national_number: "14082508100"
  //       }
  //     }
  //   },
  //   purchase_units: [{
  //     custom_id: 'wallet10',
  //     amount: {
  //       currency_code: 'EUR',
  //       value: '9.99'
  //     },
  //     shipping: {
  //       address: {
  //         address_line_1: '2211 N First Street',
  //         address_line_2: 'Building 17',
  //         admin_area_2: 'San Jose',
  //         admin_area_1: 'CA',
  //         postal_code: '95131',
  //         country_code: 'US'
  //       }
  //     }
  //   }]
  // };
  item: any;
  loader: boolean;
  constructor(private fb: FormBuilder, private ref: ChangeDetectorRef,
    private service: HomeServiceService, private alert: AlertService, private router: Router) { }
  individualDonateRegForm: FormGroup;
  ngOnInit() {
    window.scroll(0, 0);
    this.individualDonateRegForm = this.fb.group({
      DonateType: [""],
      PersonalProfile: ['', [Validators.required]],
      PersonalFirstName: ["", Validators.required],
      PersonalMiddleName: ["", Validators.required],
      PersonalLastName: ["", [Validators.required]],
      PersonalGender: ["", Validators.required],
      PersonalStateOfOrigin: ["", Validators.required],
      PersonalNationality: ["", Validators.required],
      PersonalEmail: ["", [Validators.required, Validators.email]],
      PersonalPhone: ["", [
        Validators.required,
        Validators.minLength(7),
        // Validators.maxLength(11),
        Validators.pattern(pattern.phonePattern)
      ],
        // this.service.phoneExistValidator()
      ],
      PersonalDonateMaterial: new FormArray([], [Validators.required]),
      PersonalAddress: ["", Validators.required],
      PersonalDonateItems: ["", Validators.required],
      PersonalAmountNeeded: ["", [Validators.required,Validators.min(1)]],
      PersonalDropPoints: ["", Validators.required],
      Purpose: ["", Validators.required],
      agreed: ["", Validators.required]
    });
    // this.getStatesname()
    this.getCountry()

  }
  getCountry() {
    this.service.getCountry().subscribe(
      (res: any) => {
        this.countryList = res;
      },
      (error) => { }
    );
  }
  getStatesname(value) {
    if (value) {
      for (let i = 0; i < this.countryList.length; i++) {
        if (value == this.countryList[i].CountryName) {
          this.stateId = this.countryList[i].Id
        }
      }
    }
    this.service.getStatefromCountry(this.stateId).subscribe(
      (res: any) => {
        this.statesList = res;
      },
      (error) => { }
    );
  }
  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.tempImg = (<FileReader>event.target).result;
        this.individualDonateRegForm.patchValue({
          PersonalProfile: event.target.result
        });
        this.alert.success("uploaded successfully")
      };
      reader.readAsDataURL(event.target.files[0]);
      this.ref.markForCheck();
    }
    // if (event.target.files && event.target.files.length) {
    //   const reader = new FileReader();
    //   reader.onload = (event: any) => {
    //     this.tempImg = (<FileReader>event.target).result;
    //     this.helpRegForm.patchValue({
    //       BenificiaryProfile: event.target.result
    //     });
    //     this.alert.success("uploaded successfully")
    //   };
    //   reader.readAsDataURL(event.target.files[0]);
    //   this.ref.markForCheck();
    // }

    // if (this.fileList.length > 0) {
    //   let file: File = this.fileList[0];
    //   debugger
    //   this.individualDonateRegForm.get('PersonalProfile').patchValue(file)
    // }
  }
  // selectCheckBox(){
  selectCheckBox(event, targetType: CheckBoxType) {
    console.log(event.target.value)
    if (event.target.value == 'self') {
      this.selfForm = true
    }
    else {
      this.selfForm = false
      this.behalfForm = true
    }
    console.log(targetType)
    // If the checkbox was already checked, clear the currentlyChecked variable
    if (this.currentlyChecked === targetType) {
      this.currentlyChecked = CheckBoxType.NONE;
      return;
    }

    this.currentlyChecked = targetType;
    // if(this.currentlyChecked  == 'behalf'){
    //   this.behalf = true;
    // }
    // else {
    //   this.behalf = false
    //   this.self = true
    // }
  }
  // }
  donateItemsChange(value) {
    console.log(value)
    if (value == 'Material') {
      this.openMaterialList = true
    }
    else {
      this.openMaterialList = false
    }

  }
  get f() {
    return this.individualDonateRegForm.controls
  }
  onCheckboxChange(event) {
    const formArray: FormArray = this.individualDonateRegForm.get(
      "PersonalDonateMaterial"
    ) as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }
  submit(form) {
    this.isSubmitted = true
    if (this.individualDonateRegForm.invalid) {
      return
    }
    this.loader = true
    this.service.individualdonationData(form.value).subscribe((res: any) => {
      if (res) {
        if(form.value.PersonalDonateItems != ' ' || form.value.PersonalDonateItems!= null){
          this.item = form.value.PersonalDonateItems
        }
        else {
          this.item = 'Donation'
        }

        this.loader = false
        var paymentData = {
          Email: form.value.PersonalEmail,
          Amount: form.value.PersonalAmountNeeded, Items: this.item
        };
        console.log(paymentData)
        // debugger
        sessionStorage.setItem('paymentData', JSON.stringify(paymentData))
        this.individualDonateRegForm.reset()
        this.alert.success(res, true)
        this.router.navigate(["/payment-gateway"])
        this.isSubmitted = false  
      }
      (err) => {
        this.loader = false;
        this.alert.error(err,true);
      }
    })
  }
  nameValidations(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  numbersOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  materialChange(value) {
    if (value == 'Material') {
      this.individualDonateRegForm.get('PersonalDonateMaterial').setValidators([Validators.required])
      this.individualDonateRegForm.get('PersonalDonateMaterial').updateValueAndValidity()
    }
    else {
      this.individualDonateRegForm.get('PersonalDonateMaterial').clearValidators()
      this.individualDonateRegForm.get('PersonalDonateMaterial').updateValueAndValidity()
    }
  }
  // public resizeImage() {
  //   let imgFile;
  //   if (typeof this.uploadStudentImage === "string") {
  //     imgFile = this.dataURLtoFile(this.uploadStudentImage, "myfile.jpg");
  //   } else {
  //     imgFile = this.uploadStudentImage;
  //   }
  //   if (imgFile && imgFile.size > 204800) {
  //     const src_img: any = document.getElementById("myprofileimg");
  //     const percentage = (200 * 100) / (imgFile.size / 1024);
  //     const targetImg = jic.compress(src_img, Math.floor(percentage), "jpg")
  //       .src;
  //     const file = this.dataURLtoFile(targetImg, imgFile.name);
  //     this.uploadStudentImage = file;
  //     // this.imageToVerify = file;
  //     src_img.src = targetImg;
  //     if (file && file.size > 204800) {
  //       this.resizeImage();
  //     } else {
  //       this.individualDonateRegForm.get('PersonalProfile').patchValue(this.uploadStudentImage)
  //       this.reader.readAsDataURL(this.uploadStudentImage);
  //     }
  //   } else {
  //     // debugger
  //     this.individualDonateRegForm.get('PersonalProfile').patchValue(this.uploadStudentImage)
  //   }
  // }
  // dataURLtoFile(dataurl, filename) {
  //   // tslint:disable-next-line:prefer-const
  //   let arr = dataurl.split(","),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     // tslint:disable-next-line:prefer-const
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // }
  onApprove(data: OnApproveData, actions: OnApproveActions) {

    console.log('Transaction Approved:', data);

    // Captures the trasnaction
    return actions.order.capture().then(details => {

      console.log('Transaction completed by', details);

      // Call your server to handle the transaction
      return Promise.reject('Transaction aborted by the server');
    });
  }

  onCancel(data: OnCancelData) {

    console.log('Transaction Cancelled:', data);
  }

  onError(data: OnErrorData) {

    console.log('Transaction Error:', data);
  }
  amountDonate(value) {
    console.log(value)
  }
}

