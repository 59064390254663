import { Component , OnInit } from '@angular/core';

@Component({
    selector:'university-continental-reg',
    templateUrl:'./university-continental-reg.component.html'
})
export class UniversityContinentalRegComponent implements OnInit { 
constructor(){

}
ngOnInit(){
    
}
}