import { Component, OnInit } from "@angular/core";
import { HomeSecondService } from "../../_service/home-second.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
  password;
  show: boolean = false;
  public user: Object = {
    Password: "",
    NewPassword: "",
    ConfirmPassword: ""
  };
 

  constructor(private service: HomeSecondService, private route: Router) {}

  ngOnInit() {
    this.password = 'password'
  }
  onSubmit(submittedForm) {
    var requestData = JSON.parse(JSON.stringify(this.user));
    // console.log(submittedForm.value)
    requestData.Password = btoa(submittedForm.value.Password);
    requestData.NewPassword = btoa(submittedForm.value.NewPassword);
    requestData.ConfirmPassword = btoa(submittedForm.value.ConfirmPassword);
    // console.log(requestData)
    this.service.resetPassword(requestData).subscribe(
      res => {
        alert(res);
        if (res !== undefined && res === "Password changed successfully") {
          localStorage.clear();
          this.route.navigate(["/"]);
        } else {
          this.route.navigate(["/change-password"]);
        }
      },
      err => {
        alert("error" + err);
      }
    );
  }
  showPassword() {
    if (this.password === "password") {
      this.password = "text";
      this.show = true;
    } else {
      this.password = "password";
      this.show = false;
    }
  }
}
