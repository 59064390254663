import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "../_service/alert.service";

@Injectable()
export class SchoolRegistrationGuard implements CanActivate {
  constructor(private router: Router, private alert: AlertService) {}
  canActivate() {
    if (this.isRegistrationActive()) {
      return true;
    } else {
      this.alert.error("Registration is closed.", true);
      this.router.navigate(["/school/schooldashboard"]);
    }
  }

  isRegistrationActive() {
    let endDate = new Date(
      JSON.parse(
        localStorage.getItem("logincredential")
      ).body.RegistrationEndDate
    );
    return endDate >= new Date() ? true : false;
  }
}
