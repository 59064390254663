


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { RouterModule } from "@angular/router";
import { AlertComponent } from './alert/alert.component';
// import {AuthGuardService} from '../_services/auth-guard.service';
// import {AlertService} from '../../services/alert.service';
// import {ErrorHandlerService} from '../_services/error-handler.service';
import { ShareDataService } from "src/app/_shared/share-data.service";
import { FooterComponent } from "src/app/_shared/footer/footer.component";
import { AlertService } from "src/app/_service/alert.service";
import { SchoolRegistrationComponent } from './school-registration/school-registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomValidation } from './school-registration/PasswordValidation';
import { DataGridComponent } from './data-grid/data-grid.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { QuillModule } from 'ngx-quill';
import { ChangePasswordComponent } from './change-password/change-password.component';
import{ CompareValidatorDirective } from './comparevalidatordirective';
import { MatSlideToggleModule, MatCardModule } from '@angular/material';
import { TruncatePipePipe } from '../pipe/truncate-pipe.pipe';
import { SyllabusComponent } from './syllabus/syllabus.component';
import { ShowHidePassword } from './show-hide-password-directive';
import { SponsorComponent } from '../sponsor/sponsor.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { ClickOutsideDirective } from '../Directive/click-outside.directive';
import { AboutQuicklearningComponent } from './footer/footer-subcomponent/about-quicklearning/about-quicklearning.component';
import { ContactUsComponent } from './footer/footer-subcomponent/contact-us/contact-us.component';
import { CareersComponent } from './footer/footer-subcomponent/careers/careers.component';
import { ChatBoxComponent } from './footer/footer-subcomponent/chat-box/chat-box.component';
import { TermsOfUseComponent } from './footer/footer-subcomponent/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './footer/footer-subcomponent/privacy-policy/privacy-policy.component';
import { AdvanceSignupComponent } from './advance-signup/advance-signup.component';
import { MyDatePickerModule } from 'mydatepicker';
import { PastQuestionsComponent } from '../admin_management/general/past-questions/past-questions.component';
import { HomeComponent } from '../home/home.component';
import { WebcamModule } from 'ngx-webcam';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    MatSlideToggleModule,
    MatCardModule,
    MyDatePickerModule,
    WebcamModule
  ],
  exports: [
    LoaderComponent,
    HeaderComponent,
    AlertComponent,
    FooterComponent,
    DataGridComponent,
    SchoolRegistrationComponent,
    GuidelinesComponent,
    ChangePasswordComponent,TruncatePipePipe,
    SponsorComponent,
    LoadingPageComponent,
    ClickOutsideDirective
  ],
  declarations: [
    HeaderComponent,
    LoaderComponent,
    SchoolRegistrationComponent,
    AlertComponent,
    FooterComponent,
    DataGridComponent,
    TruncatePipePipe,
    GuidelinesComponent,
    ChangePasswordComponent,
    CompareValidatorDirective,
    SyllabusComponent,
    ShowHidePassword,
    SponsorComponent,
    LoadingPageComponent,
    ClickOutsideDirective,
    AboutQuicklearningComponent,
    ContactUsComponent,
    CareersComponent,
    ChatBoxComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    AdvanceSignupComponent,
    PastQuestionsComponent,
    // HomeComponent
  ],
  providers: [
    AlertService,
    ShareDataService
  ],
})
export class SharedModule { }

