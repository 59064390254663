import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdvanceFinalPublishResultFilter, advanceRsultFinalistModel, AdvanceTopRequestParam, FinalPublishResultFilter, resultFinalistModel, TopRequestParam } from 'src/app/admin_management/models/models';
import { GridColumnType, PaginationModel, PropertyMetaModel } from 'src/app/_models/pagination-model';
import { AlertService } from 'src/app/_service/alert.service';
import { HomeServiceService } from 'src/app/_service/home-service.service';
import { SchoolService } from 'src/app/_service/school-service.service';

@Component({
  selector: 'app-continental-view-result',
  templateUrl: './continental-view-result.component.html',
  styleUrls: ['./continental-view-result.component.css']
})
export class ContinentalViewResultComponent implements OnInit {
  public advanceFinalistRequestParam: advanceRsultFinalistModel = new advanceRsultFinalistModel();
  public advanceTop3RequestParam: AdvanceTopRequestParam = new AdvanceTopRequestParam();
  public advanceFinalPublishRequestParam: AdvanceFinalPublishResultFilter = new AdvanceFinalPublishResultFilter();

  public loader: boolean = false;
  public juniorCntGridData: any[] = [];
  public seniorCntGridData: any[] = [];
  public displayColumns: PropertyMetaModel[];
  public top3displayColumns: PropertyMetaModel[];
  public finalistdisplayColumns: PropertyMetaModel[];
  public finalPublishDisplayColumns: PropertyMetaModel[];
  public isResultPublished: any;

  public isJuniorDivVisble: boolean;
  public type: any;

  public juniorPageInfo: PaginationModel = {
    PageSize: 50,
    CurrentPage: 1,
    TotalRecord: 0
  };

  public seniorPageInfo: PaginationModel = {
    PageSize: 50,
    CurrentPage: 1,
    TotalRecord: 0
  };

  public PageInfo: PaginationModel = {
    PageSize: 50,
    CurrentPage: 1,
    TotalRecord: 0
  };
  juniorGridData: any[];

  constructor(
    private schoolService: SchoolService,
    private homeService: HomeServiceService,
    private ref: ChangeDetectorRef,
    private alert: AlertService,
    private router: ActivatedRoute
  ) {
    (this.finalistdisplayColumns = [
      // { PropertyName: "CandidateRegistrationNo", ColumnName: "Examination No.", PropertyType: GridColumnType.Text, IsSortable: true },
      // { PropertyName: "link", ColumnName: "Name", PropertyType: GridColumnType.DisplayLink, DisplayText: "Name", IsSortable: true },
      // { PropertyName: "Email", ColumnName: "Email", PropertyType: GridColumnType.Text, IsSortable: true },
      {
        PropertyName: "Name",
        ColumnName: "Name",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "SchoolName",
        ColumnName: "School",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "CandidateNumber",
        ColumnName: "CandidateNumber",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "Score",
        ColumnName: "Candidate Score",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "status",
        ColumnName: "Qualification Status",
        PropertyType: GridColumnType.Text
      }
  
    ]),
      (this.top3displayColumns = [
      
        {
          PropertyName: "StateCode",
          ColumnName: "Province",
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: "SchoolName",
          ColumnName: "Candidate School",
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: "Name",
          ColumnName: "Candidate Name",
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: "CandidateRegistrationNo",
          ColumnName: "Candidate No.",
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: "Score",
          ColumnName: "Score",
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: "Rank",
          ColumnName: "Rank",
          PropertyType: GridColumnType.Text
        }

      ]),
      (this.finalPublishDisplayColumns = [
      
        {
          PropertyName: "StateCode",
          ColumnName: "Province",
          PropertyType: GridColumnType.Text,
          IsSortable: true
        },
        {
          PropertyName: "SchoolName",
          ColumnName: "Candidate School",
          PropertyType: GridColumnType.Text,
          IsSortable: true
        },
        {
          PropertyName: "Name",
          ColumnName: "Candidate Name",
          PropertyType: GridColumnType.Text,
          IsSortable: true
        },
        {
          PropertyName: "CandidateRegistrationNo",
          ColumnName: "Candidate No.",
          PropertyType: GridColumnType.Text,
          IsSortable: true
        },
        {
          PropertyName: "Score",
          ColumnName: "Score",
          PropertyType: GridColumnType.Text,
          IsSortable: true
        }
        
      ]);
    this.advanceTop3RequestParam.pageno = this.PageInfo.CurrentPage;
    this.advanceTop3RequestParam.pagesize = this.PageInfo.PageSize;
    this.advanceFinalPublishRequestParam.pagesize = this.PageInfo.PageSize;
    this.advanceFinalPublishRequestParam.pageno = this.PageInfo.CurrentPage;
  }

  ngOnInit() {
    this.router.params.subscribe(param => {
      this.type = param["type"];
      this.advanceFinalPublishRequestParam = new AdvanceFinalPublishResultFilter();
      if (this.type == "finalists") {
        this.getAllJuniorFinalists();
      } else if (this.type == "top3") {
        this.getAllTop3JuniorFinalists();
      } else if (this.type == "Junior") {
        this.getOnlyJuniorFinalists();
      } else {
        this.getAllJuniorFinalists();
      }
    });
    this.getIsResultPublished();
  }

  ngOnDestroy() {}

  getAllJuniorFinalists() {
    this.advanceFinalistRequestParam.Category = "Junior";
    this.advanceFinalistRequestParam.RegistrationType = "Continental"
    this.advanceFinalistRequestParam.pageno = this.juniorPageInfo.CurrentPage;
    this.advanceFinalistRequestParam.pagesize = this.juniorPageInfo.PageSize;
    this.loader = true;
    this.homeService.getUniversityPublishResult(this.advanceFinalistRequestParam).subscribe(
      (res: any) => {
        this.loader = false;
        if (res && res.List) {
          res.List.forEach(x => {
            x.Name = x.Name.toUpperCase();
            x.SchoolName = x.SchoolName.toUpperCase();
          });
          this.juniorGridData = res.List;
          this.displayColumns = this.finalistdisplayColumns;
          this.isJuniorDivVisble =
            this.juniorGridData.length > 0 ? true : false;
          this.juniorPageInfo.TotalRecord = res.TotalRecords;
        } else {
          this.juniorGridData = [];
        }
      },
      error => {
        this.loader = false;
        this.juniorGridData = [];
        this.alert.error(error);
      }
    );
  }


  getAllTop3JuniorFinalists() {
    this.loader = true;
    this.advanceTop3RequestParam.pageno = this.juniorPageInfo.CurrentPage;
    this.advanceTop3RequestParam.pagesize = this.juniorPageInfo.PageSize;
    this.advanceTop3RequestParam.Category = "Junior";
    this.advanceTop3RequestParam.RegistrationType = "Continental";
    this.homeService.getAdvanceTop3Result(this.advanceTop3RequestParam).subscribe(
      (res: any) => {
        this.loader = false;
        if (res && res.List) {
          res.List.forEach(x => {
            x.Name = x.Name.toUpperCase();
            x.SchoolName = x.SchoolName.toUpperCase();
            x.StateCode = x.StateCode.toUpperCase();
          });
          this.juniorGridData = res.List;
          this.displayColumns = this.top3displayColumns;
          this.isJuniorDivVisble =
            this.juniorGridData.length > 0 ? true : false;
          this.juniorPageInfo.TotalRecord = res.TotalRecords;
        } else {
          this.juniorGridData = [];
        }
      },
      error => {
        this.loader = false;
        this.juniorGridData = [];
        this.alert.error(error);
      }
    );
  }



  getOnlyJuniorFinalists() {
    this.loader = true;
    this.advanceFinalPublishRequestParam.pageno = this.juniorPageInfo.CurrentPage;
    this.advanceFinalPublishRequestParam.pagesize = this.juniorPageInfo.PageSize;
    this.advanceFinalPublishRequestParam.SortBy = this.juniorPageInfo.SortBy;
    this.advanceFinalPublishRequestParam.SortOrder = this.juniorPageInfo.SortOrder;
    this.advanceFinalPublishRequestParam.Category = "";
    this.advanceFinalPublishRequestParam.RegistrationType = "";
    this.homeService
      .getAdvanceFinalPublishResult(this.advanceFinalPublishRequestParam)
      .subscribe(
        (res: any) => {
          this.loader = false;
          if (res && res.List) {
            res.List.forEach(x => {
              x.Name = x.Name.toUpperCase();
              x.SchoolName = x.SchoolName.toUpperCase();
              x.StateCode = x.StateCode.toUpperCase();
            });
            this.juniorGridData = res.List;
            this.displayColumns = this.finalPublishDisplayColumns;
            this.isJuniorDivVisble =
              this.juniorGridData.length > 0 ? true : false;
            this.juniorPageInfo.TotalRecord = res.TotalRecords;
          } else {
            this.juniorGridData = [];
          }
        },
        error => {
          this.loader = false;
          this.juniorGridData = [];
          this.alert.error(error);
        }
      );
  }

  getIsResultPublished() {
    this.loader = true;
    this.schoolService.getIsResultPublished().subscribe(
      (res: any) => {
        this.loader = false;
        if (res && res.List) {
          this.isResultPublished = res.List[0];
          this.ref.detectChanges();
        }
      },
      error => {
        this.loader = false;
      }
    );
  }

  juniorPageInfoChange(event) {
    this.juniorPageInfo.SortBy = event.SortBy;
    this.juniorPageInfo.SortOrder = event.SortOrder;
    this.juniorPageInfo.CurrentPage = event.CurrentPage;
    this.juniorPageInfo.PageSize = event.PageSize;
    if(this.juniorPageInfo.CurrentPage > (this.juniorPageInfo.TotalRecord/ this.juniorPageInfo.PageSize)+1){
      this.juniorPageInfo.CurrentPage = 1
    }
    if (this.type == "finalists") this.getAllJuniorFinalists();
    else if (this.type == "top3") this.getAllTop3JuniorFinalists();
    else this.getOnlyJuniorFinalists();
  }
  action(event) {}

  Filter(value) {
    
    this.getOnlyJuniorFinalists();
  }
}
