import { AuthServiceService } from './auth-service.service';
import { Injectable } from '@angular/core';
import {
HttpRequest,
HttpHandler,
HttpEvent,
HttpInterceptor,
HttpErrorResponse,
HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{
  public authToken : any;
constructor(public auth: AuthServiceService,private route:Router) {
}
intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

request = request.clone({
setHeaders: {
token: `${this.auth.getToken()}`
}
});
return next.handle(request)
.pipe(
tap(event => {
if (event instanceof HttpResponse) {
// var usertoken = event.headers.get('X-XSS-Protection');
// if(usertoken){
// localStorage.setItem('token',usertoken);
// }
return event;
}
}, error => {
// http response status code
return this.handleAuthError(error);

})
)

}

private handleAuthError(err: HttpErrorResponse): Observable<any> {
//handle your auth error or rethrow
if (err.status === 401) {
  localStorage.clear();
//navigate /delete cookies or whatever
//this.events.publish('UNAUTHORIZED'); 
// MyApp.navigateToWelcomePage()
 this.route.navigateByUrl('/login');
// if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
return of(err.message);
}
throw err;
}
}
