import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-audio-play-pause',
  templateUrl: './audio-play-pause.component.html',
  styleUrls: ['./audio-play-pause.component.css']
})
export class AudioPlayPauseComponent implements OnInit {
  audio: HTMLAudioElement;
  // counter: any = 0;

  constructor() { }

  ngOnInit() {
    this.playAudio();
  }
  playAudio(): any {    

      this.audio = new Audio('../../../assets/CruxLearng.wav');
      // this.audio.play();
//     this.audio.addEventListener('ended', function() {
//     this.currentTime = 0;
//     this.play();
// }, false);
  }

  stopAudio(){
    this.audio.pause();
    this.audio.currentTime = 0;
  }

}
