export class AdvanceDetails{
    Id: any;
    RegistrationType: string;
    CandidateNumber: number;
    ProfilePic: string | ArrayBuffer;
    FirstName: string;
    LastName: string;
    CandidateEmail: string;
    CandidatePhone: number;
    Dob: any;
    IsFiftyFourQualify: string;
    Score: string;
    State: string;
    Country: string;
    Gender: string;
    CourseOfStudy: string;
    LevelOfStudy: string;
    Address:string;
    SchoolName: string;
    SchoolEmail: string;
    SchoolPhone: number;
    OriginState: string;
    ResidenceState: string;
    Password: string;
    ConfirmPassword: string;
    PostalAddress: number;
    ParentName: string;
    ParentEmail: string;
    ParentPhone: number;
    IsQualify : any;
    MathTutorName: string;
    MathTutorEmail: string;
    MathTutorPhone: number;
    preferedstartTime:string | number;
    preferedEndTime:string | number
    prepClass : boolean
 
}