import { Component, OnInit, AfterViewInit } from "@angular/core";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError
} from "@angular/router";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "app";
  showLoadingPage: boolean = false
  header: boolean = true;
  loader: boolean;
  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.loader = true;
        // console.log(event.url)
      } else if (event instanceof NavigationEnd) {
        if (event.url == "/") {
          this.header = false
        }
        else if (event.url.includes("/404") || event.urlAfterRedirects == '/404') {
          this.header = false
        }
        else if (event.url.includes("/main")) {
        this.showLoadingPage = true
        this.header = false
         }
        else if (event.url.includes("/home")) {
          this.showLoadingPage = false
          this.header = true
        }
        // Hide loading indicator
        else if (
          (event.url.includes("/school") &&
            !event.url.includes("school-registration")) ||
          event.url.includes("/admin")
        ) {
          this.header = false;
        } else if (
          event.url.includes("/accountManager") ||
          event.url.includes("/Manager")
        ) {
          this.header = false;
        } else if (event.url.includes("/examiner")) {
          this.header = false;
        }
        else if (event.url.includes("/exam-authorizer")) {
          this.header = false;
        }
        else if (event.url.includes("/report-admin")) {
          this.header = false;
        } else if (event.url.includes("/advance/") ||
          (event.url.includes("/advance") && event.url.includes("Id"))) {
          this.header = false;
        }

        else if (event.url.includes("/continetal")) {
          this.header = false;
        }
        // else if (event.url.includes("/africa")) {
        //   this.header = false;
        // }
        // else if (event.url.includes("/university")) {
        //   this.header = false;
        // }
        else if (event.url.includes("/super-admin")) {
          this.header = false;

        }

        else {
          this.header = true;
        }
        this.loader = false;
      } else if (event instanceof NavigationError) {
        this.loader = false;
      }
      // else if(event instanceof NavigationCancel){
      //   this.loader = false
      // }
    });
  }

  constructor(private router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        if (event.url.includes("/")) {
          this.showLoadingPage = false
          // setTimeout(() => {
          //   this.showLoadingPage = false
          //   // console.log("hiii i am called", this.showLoadingPage)
          // }, 3000)
        }
        else if (event.url.includes("/main")) {
          this.showLoadingPage = true
          this.header = false
          // setTimeout(() => {
          //   this.showLoadingPage = false
          //   // console.log("hiii i am called", this.showLoadingPage)
          // }, 3000)
        }
        if (event.url.includes("/home")) {
          this.showLoadingPage = false
          // setTimeout(() => {
          //   this.showLoadingPage = false
          //   // console.log("hiii i am called", this.showLoadingPage)
          // }, 3000)
        }
        if (
          (event.url.includes("/school") &&
            !event.url.includes("school-registration")) ||
          event.url.includes("/admin")
        ) {
          this.header = false;
        }
        else if
          (event.url.includes("/advance")) {
          this.showLoadingPage = false;
        }
        else if
          (event.url.includes("/university")) {
          this.showLoadingPage = false;
        }
        else if
          (event.url.includes("/africa")) {
          this.showLoadingPage = false;
        } else {
          this.header = false;
        }
      }

      if (event instanceof NavigationError) {
      }
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }
}
//

//   ngOnInit(): void {
//     this.router.events.subscribe((event: Event) => {
//       if (event instanceof NavigationStart) {
//         // Show loading indicator
//         this.loader = true;
//         // console.log(event.url)
//       } else if (event instanceof NavigationEnd) {
//         // Hide loading indicator
//         if (
//           (event.url.includes("/school") &&
//             !event.url.includes("school-registration")) ||
//           event.url.includes("/admin")
//         ) {
//           this.header = false;
//         } else if (
//           event.url.includes("/accountManager") ||
//           event.url.includes("/Manager")
//         ) {
//           this.header = false;
//         } else if (event.url.includes("/examiner")) {
//           this.header = false;
//         } else if (event.url.includes("/exam-authorizer")) {
//           this.header = false;
//         }
//         else if (event.url.includes("/report-admin")) {
//           this.header = false;
//         } else if (event.url.includes("/advance/") || 
//             (event.url.includes("/advance") && event.url.includes("Id"))){
//           this.header = false;
//         }else if (event.url.includes("/super-admin")) {
//           this.header = false;
//         }else {
//           this.header = true;
//         }
//         this.loader = false;
//       } else if (event instanceof NavigationError) {
//         this.loader = false;
//       }
//     });
//   }

//   constructor(private router: Router) {
//     router.events.subscribe((event: Event) => {
//       if (event instanceof NavigationStart) {
//         // Show loading indicator
//       }

//       if (event instanceof NavigationEnd) {
//         if(event.url.includes("/home")){
//           this.showLoadingPage = true
//           setTimeout(() => {
//             this.showLoadingPage = false
//             // console.log("hiii i am called", this.showLoadingPage)
//           }, 3000)
//         }
//         if (
//           (event.url.includes("/school") &&
//             !event.url.includes("school-registration")) ||
//           event.url.includes("/admin")
//         ) {
//           this.header = false;
//         } else {
//           this.header = true;
//         }
//       }

//       if (event instanceof NavigationError) {
//       }
//     });
//   }


// }