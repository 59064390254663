import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
} from "@angular/forms";
import { AdvanceDetails } from 'src/app/_models/advance-model';
import { AlertService } from "../../_service/alert.service";
import * as pattern from "../../model/user";
import { IMyDpOptions, IMyDateModel, IMyDefaultMonth } from 'mydatepicker';
import { HomeServiceService } from 'src/app/_service/home-service.service';
import { MustMatch } from '../school-registration/password-match.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from 'src/app/_service/school-service.service';
import { Observable, Subject, zip } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';
import { DuplicateMail, DuplicatePhone } from './duplicate-email.validator';
declare var $: any;
declare var jic: any;
@Component({
  selector: 'app-advance-signup',
  templateUrl: './advance-signup.component.html',
  styleUrls: ['./advance-signup.component.css']
})
export class AdvanceSignupComponent implements OnInit {
  public AdvanceDetails: AdvanceDetails = new AdvanceDetails();
  trigger: Subject<void> = new Subject<void>();
  webcamImage: WebcamImage = null;
  webActive: boolean;
  webcamImageEnable: boolean;
  public loader: boolean;
  countryList: any = [];
  africaCountryList: any = [];
  allCountryList: any = [];
  id = '';
  statesList: any = [];
  registrationType: string;
  candidateRegForm: FormGroup;
  password;
  fileList: FileList;
  uploadStudentImage: any;
  reader = new FileReader();
  tempImg: any;
  timeDropDownList = [];
  show: boolean = false;
  isSubmitted: boolean = false;
  public getClass: any;
  checkboxEnabled: boolean = false;
  submittedError: boolean = false;
  url: any;
  public passwordType: string = "password";
  levelOfStudy: any = [{ id: "100", name: "100" },
  { id: "200", name: "200" },
  { id: "300", name: "300" },
  { id: "400", name: "400" },
  { id: "500", name: "500" }
  ]
  public LastDate: Date = new Date();
  public PreviousDate: Date = new Date();
  public defaultMonth: IMyDefaultMonth = {
    defMonth: `0${this.LastDate.getMonth()}/${this.LastDate.getFullYear() - 15}`
  };
  public mytime: Date = new Date();
  currentYear: any = this.mytime.getUTCFullYear();
  currentDate: any = this.mytime.getUTCDate();
  currentMonth: any = this.mytime.getUTCMonth() + 1;
  public myDatePickerOptions: IMyDpOptions = {
    inline: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    disableSince: {
      year: this.currentYear ,
      month: this.currentMonth ,
      day: this.currentDate
    },
    disableUntil: {
      year: this.PreviousDate.getFullYear() - 40,
      month: this.PreviousDate.getMonth() + 1,
      day: this.PreviousDate.getDate()
    },
    dateFormat: 'dd-mmm-yyyy',
  };
  public passportDatePickerOptions: IMyDpOptions = {
    inline: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    dateFormat: 'dd-mmm-yyyy',
  };
  public startDate: Object = {
    date: {
      year: 2008,
      month: this.PreviousDate.getMonth(),
      day: this.PreviousDate.getDate()
    }
  };
  enable: boolean;
  getTimeSlot: { id: number; value: string; }[];
  classList: any;
  affiliatedList: any;
  userProfile: any;

  constructor(
    private fb: FormBuilder,
    private service: HomeServiceService,
    private ref: ChangeDetectorRef,
    private alertService: AlertService,
    private _router: Router,
    private el: ElementRef,
    private activeRoute: ActivatedRoute,
    private schoolService: SchoolService
  ) { }

  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem("logincredential"));
    this.id = this.activeRoute.snapshot.paramMap.get("id")
    console.log(this.id)
    this.getTimeSlot = [{ id: 1, value: '9am-10am' },
    { id: 2, value: '12pm-1pm' },
    { id: 3, value: '3pm-4pm' }]
    this.getAllClasses()
    this.getStatesname();
    this.password = 'password'
    this.candidateForm();
    // this.setValidation()
    // this.setDetailsValidation()
    this.getCountry()
    this.getAllCountry()
  }
  programType: any = [{ "id": '1', value: 'Half Time' }, { "id": '2', value: 'Full Time' },];
  courseType: any = [{ "id": '1', value: 'BSC' }, { "id": '2', value: 'MSC' }, { "id": "3", value: "phd" }];
  secschooldata: any = [{ "id": '1', value: 'School Aves' },];
  ssecschooldata: any = [{ "id": '1', value: 'wereje' }, { "id": '2', value: 'Lagos' },];
  selectyear: any = [{ "id": '1', value: '2012' }, { "id": '1', value: '2013' },];
  IdentificationId: any = [{ "id": '1', value: 'Pancard' }, { "id": '2', value: 'Passport' },];
  cardNumber: any = [{ "id": '1', value: '1001' },];
  relationShip: any = [{ "id": '1', value: 'Father' }, { "id": '2', value: 'Brother' }, { "id": '1', value: 'Mother' }, { "id": '1', value: 'Sister' }, { "id": '1', value: 'Aunty' }, { "id": '1', value: 'Uncle' },{ "id": '1', value: 'Others' }];
  candidateForm() {
    this.candidateRegForm = this.fb.group({
      Id: [this.id],
      file: ["", [Validators.required]],
      FirstName: ["", [Validators.required]],
      LastName: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      CountryOfOrigin: ["", [Validators.required]],
      CountryOfBirth: ["", [Validators.required]],
      CountryResidence: ["", [Validators.required]],
      CandidateEmail: ["", [Validators.required, Validators.email]],
      Gender: ["", [Validators.required]],
      CandidatePhone: [
        "",
        [
          Validators.minLength(7),
          // Validators.maxLength(11),
          Validators.required,
          Validators.pattern(pattern.phonePattern),
        ]],
      CountryCode: [""],
      CandidateAlternatePhone: ["", [
        Validators.minLength(7),
        // Validators.maxLength(11),
        Validators.pattern(pattern.phonePattern),
      ]],
      SchoolName: ["", [Validators.required]],
      SchoolAddress: ["", [Validators.required]],
      SchoolCountry: ["", [Validators.required]],
      CurrentClass: ["", [Validators.required]],
      NameOfContact: ["", [Validators.required]],
      SchoolCountryCode: [""],
      SchoolPhone: ["", [Validators.required,
      Validators.minLength(7),
      // Validators.maxLength(11)
      ]],
      SchoolEmail: ["", [Validators.required, Validators.email]],
      SchoolAlternateEmail: ["", [Validators.email]],
      // PassportFirstName: [""],
      // PassportMiddleName: [""],
      // PassportSurname: [""],
      // PassportNumber: [""],
      // PassportNationality: [""],
      // PassportDateOfIssue: [""],
      // PassportDateOfExpiry: [""],
      // Passport2FirstName: [""],
      // Passport2MiddleName: [""],
      // Passport2Surname: [""],
      // Passport2Number: [""],
      // Passport2Nationality: [""],
      // Passport2DateOfIssue: [""],
      // Passport2DateOfExpiry: [""],
      Password: [""],
      ConfirmPassword: [""],
      PostalAddress: [""],
      ParentFirstName: ["", [Validators.required]],
      ParentSurname: [""],
      ParentPhone: ["", [
        Validators.minLength(7),
        // Validators.maxLength(11),
        Validators.required,
        Validators.pattern(pattern.phonePattern),
      ]
      ],
      ParentLocation: ["", [Validators.required]],
      ParentCountryCode:[""],
      ParentRelation: ["", [Validators.required]],
      ParentEmail: ["", [Validators.required, Validators.email]],
      // Parent2FirstName: [""],
      // Parent2Surname: [""],
      // Parent2Relation: [""],
      // Parent2Phone: ["", [
      //   Validators.minLength(7),
      //   // Validators.maxLength(11),
      // ]],
      // Parent2Email: [""],
      // Parent2Location: [""],
      // Parent2CountryCode:[""],
      PreferedTime: [""],
      prepClass: [""],
      FlagSubmit: [true]
    },
      { validator:[MustMatch("Password", "ConfirmPassword"),DuplicateMail("CandidateEmail","SchoolEmail","ParentEmail"),DuplicatePhone("CandidatePhone","CandidateAlternatePhone","SchoolPhone","ParentPhone")]},
    );
    if (this.id != '' && this.id != null) {
      this.getCandidateDetails();
      this.candidateRegForm.get('Password').clearValidators();
      this.candidateRegForm.get('Password').updateValueAndValidity();
      this.candidateRegForm.get('ConfirmPassword').clearValidators();
      this.candidateRegForm.get('ConfirmPassword').updateValueAndValidity();
    }
    else if (!this.id) {
      this.candidateRegForm.get('Password').setValidators([Validators.required, Validators.minLength(6)]);
      this.candidateRegForm.get('Password').updateValueAndValidity();
      this.candidateRegForm.get('ConfirmPassword').setValidators([Validators.required]);
      this.candidateRegForm.get('ConfirmPassword').updateValueAndValidity();
    }
  }

  CandidateRegistration(form) {
    console.log(form.value)
    form.value.Id = this.id
    this.isSubmitted = true;
    // this.setValidation();
    // this.setDetailsValidation()
    // if (this.candidateRegForm.value.PassportDateOfIssue && this.candidateRegForm.value.PassportDateOfExpiry && (this.candidateRegForm.value.PassportDateOfIssue.jsdate > this.candidateRegForm.value.PassportDateOfExpiry.jsdate)) {
    //   this.candidateRegForm.controls["PassportDateOfExpiry"].setErrors({ "dateCheck": true });
    // }
    // else {
    //   this.candidateRegForm.controls["PassportDateOfExpiry"].setErrors({ "dateCheck": null });
    //   this.candidateRegForm.controls["PassportDateOfExpiry"].updateValueAndValidity();
    // }
    // if (this.candidateRegForm.value.PassportDateOfExpiry && ((new Date().setMonth(new Date().getMonth() + 2)) > this.candidateRegForm.value.PassportDateOfExpiry.jsdate)) {
    //   this.candidateRegForm.controls["PassportDateOfExpiry"].setErrors({ "expiryDateCheck": true });
    // }
    // else {
    //   this.candidateRegForm.controls["PassportDateOfExpiry"].setErrors({ "expiryDateCheck": null });
    //   this.candidateRegForm.controls["PassportDateOfExpiry"].updateValueAndValidity();
    // }
    // if (this.candidateRegForm.value.Passport2DateOfIssue && this.candidateRegForm.value.Passport2DateOfExpiry && (this.candidateRegForm.value.Passport2DateOfIssue.jsdate > this.candidateRegForm.value.Passport2DateOfExpiry.jsdate)) {
    //   this.candidateRegForm.controls["Passport2DateOfExpiry"].setErrors({ "dateCheck2": true });
    // }
    // else {
    //   this.candidateRegForm.controls["Passport2DateOfExpiry"].setErrors({ "dateCheck2": null });
    //   this.candidateRegForm.controls["Passport2DateOfExpiry"].updateValueAndValidity();
    // }
    // if (this.candidateRegForm.value.Passport2DateOfExpiry && ((new Date().setMonth(new Date().getMonth() + 2)) > this.candidateRegForm.value.PassportDateOfExpiry.jsdate)) {
    //   this.candidateRegForm.controls["Passport2DateOfExpiry"].setErrors({ "expiryDateCheck2": true });
    // }
    // else {
    //   this.candidateRegForm.controls["Passport2DateOfExpiry"].setErrors({ "expiryDateCheck2": null });
    //   this.candidateRegForm.controls["Passport2DateOfExpiry"].updateValueAndValidity();
    // }
    if (this.id != '' && this.id != null) {
      this.candidateRegForm.get('ConfirmPassword').setErrors(null);
      this.candidateRegForm.get('ConfirmPassword').clearValidators();
    }
    if (this.candidateRegForm.invalid) {
      this.submittedError = true;
      for (const key of Object.keys(this.candidateRegForm.controls)) {
        if (this.candidateRegForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          $(invalidControl).focus();
          break;
        }
      }
      return;
    }
    if (form.value.dob.date) {
      let dateofbirth = form.value.dob.date;
      form.value.dob = new Date(dateofbirth.year, dateofbirth.month - 1, dateofbirth.day).toISOString()
    } else {
      let dateofbirth = form.value.dob
      form.value.dob = new Date(dateofbirth).toISOString();
    } 
    // if (form.value.PassportDateOfIssue.date != undefined) {
    //   let dateofissue = form.value.PassportDateOfIssue.date;
    //   form.value.PassportDateOfIssue = new Date(dateofissue.year, dateofissue.month - 1, dateofissue.day).toISOString()
    // } else {
    //   form.value.PassportDateOfIssue = ""
    // }
    // if (form.value.PassportDateOfExpiry.date != undefined) {
    //   console.log(form.value.PassportDateOfExpiry.date)
    //   let dateofexpiry = form.value.PassportDateOfExpiry.date;
    //   form.value.PassportDateOfExpiry = new Date(dateofexpiry.year, dateofexpiry.month - 1, dateofexpiry.day).toISOString()
    // } else {
    //   form.value.PassportDateOfExpiry = ""
    // }
    // if (form.value.Passport2DateOfIssue.date != undefined) {
    //   let dateofissue2 = form.value.Passport2DateOfIssue.date;
    //   form.value.Passport2DateOfIssue = new Date(dateofissue2.year, dateofissue2.month - 1, dateofissue2.day).toISOString()
    // } else {
    //   form.value.Passport2DateOfIssue = ""
    // }
    // if (form.value.Passport2DateOfExpiry.date != undefined) {
    //   console.log(form.value.PassportDateOfExpiry.date)
    //   let dateofexpiry2 = form.value.Passport2DateOfExpiry.date;
    //   if(dateofexpiry2){
    //     form.value.Passport2DateOfExpiry = new Date(dateofexpiry2.year, dateofexpiry2.month - 1, dateofexpiry2.day).toISOString()
    //   }
    // } else {
    //   form.value.Passport2DateOfExpiry = ""
    // }
    form.value.Password = btoa(this.candidateRegForm.value.Password);
    form.value.ConfirmPassword = btoa(this.candidateRegForm.value.ConfirmPassword); 
    this.loader = true;
    console.log(this.candidateRegForm.value)
    console.log(this.candidateRegForm.value.Picture)
    this.service.CandidateRegister(this.candidateRegForm.value).subscribe((res) => {
      this.loader = false;
      if (res) {
        if (this.id && this.userProfile.body.RoleName == "AfricaStudent") {
          this._router.navigateByUrl("advance/africa-dashboard");
          this.alertService.success("Updated Successfully")
        } 
       else if(this.userProfile && this.userProfile.body.RoleName == "Admin") {
          this.alertService.success("Added Successfully");
          this._router.navigateByUrl("admin/africa-candidate");
        }
        else if(this.userProfile && this.userProfile.body.RoleName == "SuperAdmin") {
          this.alertService.success("Added Successfully");
          this._router.navigateByUrl("super-admin/africa-candidate");
        }
        else {
          this._router.navigateByUrl("login");
          this.alertService.success("Registration Successful, Please verify the account using the verification link send to email provided.");
        }
      }
    },
      (error) => {
        this.loader = false;
        this.alertService.error(error, true);
      });

  }
  get s() {
    return this.candidateRegForm.controls;
  }
  // setValidation() {
  //   if (this.isSubmitted) {
  //     if (this.candidateRegForm.value.Passport2FirstName != '') {
  //       this.candidateRegForm.get('Passport2FirstName').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Passport2FirstName').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2MiddleName').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Passport2MiddleName').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2Number').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Passport2Number').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2Nationality').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Passport2Nationality').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2DateOfIssue').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Passport2DateOfIssue').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2Surname').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Passport2Surname').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2DateOfExpiry').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Passport2DateOfExpiry').updateValueAndValidity();

  //     }

  //     else {
  //       // debugger
  //       this.candidateRegForm.get('Passport2FirstName').clearValidators();
  //       this.candidateRegForm.get('Passport2FirstName').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2MiddleName').clearValidators();
  //       this.candidateRegForm.get('Passport2MiddleName').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2Surname').clearValidators();
  //       this.candidateRegForm.get('Passport2Surname').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2Number').clearValidators();
  //       this.candidateRegForm.get('Passport2Number').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2Nationality').clearValidators();
  //       this.candidateRegForm.get('Passport2Nationality').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2DateOfIssue').clearValidators();
  //       this.candidateRegForm.get('Passport2DateOfIssue').updateValueAndValidity();
  //       this.candidateRegForm.get('Passport2DateOfExpiry').clearValidators();
  //       this.candidateRegForm.get('Passport2DateOfExpiry').updateValueAndValidity();
  //     }
  //   }
  // }
  // setDetailsValidation() {
  //   if (this.isSubmitted) {
  //     if (this.candidateRegForm.value.Parent2FirstName != '') {
  //       this.candidateRegForm.get('Parent2FirstName').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Parent2FirstName').updateValueAndValidity();
  //       this.candidateRegForm.get('Parent2Surname').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Parent2Surname').updateValueAndValidity();
  //       this.candidateRegForm.get('Parent2Relation').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Parent2Relation').updateValueAndValidity();
  //       this.candidateRegForm.get('Parent2Phone').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Parent2Phone').updateValueAndValidity();
  //       this.candidateRegForm.get('Parent2Email').setValidators([Validators.required]);
  //       this.candidateRegForm.get('Parent2Email').updateValueAndValidity();
  //     }
  //     else {
  //       this.candidateRegForm.get('Parent2FirstName').clearValidators();
  //       this.candidateRegForm.get('Parent2FirstName').updateValueAndValidity();
  //       this.candidateRegForm.get('Parent2Surname').clearValidators();
  //       this.candidateRegForm.get('Parent2Surname').updateValueAndValidity();
  //       this.candidateRegForm.get('Parent2Relation').clearValidators();
  //       this.candidateRegForm.get('Parent2Relation').updateValueAndValidity();
  //       this.candidateRegForm.get('Parent2Phone').clearValidators();
  //       this.candidateRegForm.get('Parent2Phone').updateValueAndValidity();
  //       this.candidateRegForm.get('Parent2Email').clearValidators();
  //       this.candidateRegForm.get('Parent2Email').updateValueAndValidity();
  //     }
  //   }
  // }

  getClassbyCategory(event) {
    var cat = event.target.value;
    this.schoolService.getClass(cat).subscribe(
      res => {
        this.getClass = res;
      },
      error => { }
    );
  }
  numbersOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.tempImg = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.ref.markForCheck();
    }

    this.fileList = event.target.files;
    this.uploadStudentImage = event.target.files[0];
    if (this.fileList.length > 0) {
      let file: File = this.fileList[0];
    }
  }
  public resizeImage() {
    let imgFile;
    if (typeof this.uploadStudentImage === "string") {
      imgFile = this.dataURLtoFile(this.uploadStudentImage, "myfile.jpg");
    } else {
      imgFile = this.uploadStudentImage;
    }
    if (imgFile && imgFile.size > 204800) {
      const src_img: any = document.getElementById("myprofileimg");
      const percentage = (200 * 100) / (imgFile.size / 1024);
      const targetImg = jic.compress(src_img, Math.floor(percentage), "jpg")
        .src;

      const file = this.dataURLtoFile(targetImg, imgFile.name);
      this.uploadStudentImage = file;
      src_img.src = targetImg;
      if (file && file.size > 204800) {
        this.resizeImage();
      } else {
        this.candidateRegForm
          .get("file")
          .setValue(this.uploadStudentImage);
        this.reader.readAsDataURL(this.uploadStudentImage);
      }
    }
    else if (this.webcamImageEnable) {
      console.log(this.tempImg.imageAsDataUrl.split(",")[1])
      const cameraFile = this.dataURLtoFile(this.tempImg.imageAsDataUrl, "my.jpeg");
      this.candidateRegForm
        .get("file")
        .setValue(cameraFile);
    }
    else {
      this.candidateRegForm
        .get("file")
        .setValue(this.uploadStudentImage);
    }
  }

  dataURLtoFile(dataurl, filename) {
    // tslint:disable-next-line:prefer-const
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      // tslint:disable-next-line:prefer-const
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    console.log([u8arr])
    return new File([u8arr], filename, { type: mime });
  }

  getCountry() {
    this.service.CountryListApi().subscribe(
      (res: any) => {
        this.countryList = res
        for (let i = 0; i < res.length; i++) {
          if (res[i].Continent == 'Africa') {
            this.africaCountryList.push(res[i])
          }
        }
      },
      (error) => { }
    );
  }
  getAllCountry() {
    this.service.getCountries().subscribe(
      (res: any) => {
        this.allCountryList = res.data;
      },
      (error) => { }
    );
  }

  getStatesname() {
    this.service.getStatefromCountry(1).subscribe(
      (res: any) => {
        this.statesList = res;
      },
      (error) => { }
    );
  }
  showPassword() {
    if (this.password === "password") {
      this.password = "text";
      this.show = true;
    } else {
      this.password = "password";
      this.show = false;
    }
  }
  nameValidations(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  
  getCandidateDetails() {
    this.loader = true;
    if (this.id) {
      this.service.getAfricaCandidateDetail(this.id).subscribe((res: any) => {
        this.getStatesname();

        var start = new Date(res.dob);
        var x: Object = {
          date: {
            year: start.getFullYear(),
            month: start.getMonth() + 1,
            day: start.getDate(),
            formatted: start.toUTCString()
          }
        };
        // var star1 = new Date(res.PassportDateOfExpiry);
        // var y: Object = {
        //   date: {
        //     year: star1.getFullYear(),
        //     month: star1.getMonth() + 1,
        //     day: star1.getDate(),
        //     formatted: star1.toUTCString()
        //   }
        // };
        // var star2 = new Date(res.PassportDateOfIssue);
        // var z: Object = {
        //   date: {
        //     year: star2.getFullYear(),
        //     month: star2.getMonth() + 1,
        //     day: star2.getDate(),
        //     formatted: star2.toUTCString()
        //   }
        // };
        this.candidateRegForm.patchValue(res);
        if (res.PreferedTime != null && res.PreferedTime != "") {
          this.checkboxEnabled = true
          this.candidateRegForm.patchValue({
            prepClass: true,
            PreferedTime: res.PreferedTime,
            file: res.Picture
          })
        }
        this.countryCode(res.CountryResidence)
        this.schoolcountryCode(res.SchoolCountry)
        this.parent1Code(res.ParentLocation)
        // this.parent2Code(res.Parent2Location)
        this.candidateRegForm.patchValue({
          file: res.Picture,
          dob: x,
          // PassportDateOfExpiry: y,
          // PassportDateOfIssue: z,
          // file: res.file
        })
        this.url = res.Picture
        this.loader = false;
      },
        (error) => {
          this.loader = false;
        })
    }
    else {
      this.id = "";
    }
  }

  chckbox() {
    var el = document.getElementById('checkTick') as HTMLInputElement
    if (el.checked) {
      console.log('checked')
      this.checkboxEnabled = true
      this.candidateRegForm.controls['PreferedTime'].setValidators(Validators.required)
      this.candidateRegForm.controls['PreferedTime'].updateValueAndValidity();
    } else {
      this.checkboxEnabled = false
      this.candidateRegForm.controls['PreferedTime'].clearValidators()
      this.candidateRegForm.controls['PreferedTime'].updateValueAndValidity();
    }
  }

  dashboard() {
    this._router.navigateByUrl("advance/africa-dashboard");
  }
  countryCode(value) {
    this.service.CountryListApi().subscribe(
      (resp: any) => {
        resp.forEach(element => {
          if (element.Id == value) {
            this.candidateRegForm.get('CountryCode').setValue(element.CountryPhoneCode)
          }
        });
      },
      (error) => { }
    );
  }
  schoolcountryCode(value) {
    this.service.CountryListApi().subscribe(
      (resp: any) => {
        resp.forEach(element => {
          if (element.Id == value) {
            this.candidateRegForm.get('SchoolCountryCode').setValue(element.CountryPhoneCode)
          }
        });
      },
      (error) => { }
    );
  }
  parent1Code(value){
    this.service.CountryListApi().subscribe(
      (resp: any) => {
        resp.forEach(element => {
          if (element.Id == value) {
            this.candidateRegForm.get('ParentCountryCode').setValue(element.CountryPhoneCode)
          }
        });
      },
      (error) => { }
    );
  }
  // parent2Code(value){
  //   this.service.CountryListApi().subscribe(
  //     (resp: any) => {
  //       resp.forEach(element => {
  //         if (element.Id == value) {
  //           this.candidateRegForm.get('Parent2CountryCode').setValue(element.CountryPhoneCode)
  //         }
  //       });
  //     },
  //     (error) => { }
  //   );
  // }
  getAllClasses() {
    this.loader = true;
    this.schoolService.advanceGetClass().subscribe(res => {
      this.classList = res;
      this.loader = false;
    },
      (error) => {
        this.loader = false;
      });
  }
  triggerSnapshot(): void {
    this.webActive = true
    this.trigger.next();
  }
  camera() {
    this.tempImg = "";
    $('#webCameraModel').modal('show')
    this.webActive = true
  }
  handleImage(webcamImage: WebcamImage): void {
    this.tempImg = "";
    console.info("received webcam image", webcamImage);
    this.webcamImageEnable = true
    this.webActive = false
    $("#webCameraModel").modal("hide");
    this.tempImg = webcamImage;
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
}
