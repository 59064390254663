import { Component , OnInit } from '@angular/core';

@Component({
    selector:'university-contact-report',
    templateUrl:'./university-contact-report.component.html'
})
export class UniversityContactReportComponent implements OnInit { 
constructor(){

}
ngOnInit(){

}
}