import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(private router: Router) {}
   canActivate() {
    if (this.isUserValid()) {
      return true;
    } else {
      if(localStorage.getItem('Role') == 'SuperAdmin')
      this.router.navigate(['/super-admin']);
      else if(localStorage.getItem('Role') == 'Admin')
      this.router.navigate(['/admin']);
    else if(localStorage.getItem('Role') == 'SchoolRepresentative')
    this.router.navigate(['/school/schooldashboard']);
    else if(localStorage.getItem('Role') == 'Examiner'){
      this.router.navigate(['examiner/view-candidate']);
      }
      else if(localStorage.getItem('Role') == 'Examiner Authorizer'){
        this.router.navigate(['exam-authorizer/view-candidate']);
        }
        else if(localStorage.getItem('Role') == 'VerificationManager'){
          this.router.navigate(['accountManager/Manager']);
          }
          else if(localStorage.getItem('Role') == 'ReportAdmin'){
            this.router.navigate(['report-admin/school-type']);
            }
    }
    
  }

  isUserValid() {
          return !localStorage.getItem('Role');
  }
}
