import { Component, OnInit } from "@angular/core";
import { AlertService } from "../../_service/alert.service";
import { HomeSecondService } from "../../_service/home-second.service";
import { Router } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-guidelines",
  templateUrl: "./guidelines.component.html",
  styleUrls: ["./guidelines.component.css"]
})
export class GuidelinesComponent implements OnInit {
  public description_modules: any;
  public guideLine = { FAQs: "" };
  public userProfile: Object;

  constructor(
    public alert: AlertService,
    public service: HomeSecondService,
    private route: Router
  ) {}

  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem("logincredential"));
    this.loadGuidlines();
  }

  loadGuidlines(): void {
    this.service.getGuideLine().subscribe(
      res => {
        this.guideLine.FAQs = res;
        $("#preview").html("");
        $("#preview").append(this.guideLine.FAQs);
      },
      err => {
        console.log("Error", err);
      }
    );
  }

  edit(): void {
    $("#guidlineEditModel").modal("show");
  }

  save(): void {
    this.service.updateGuideline(this.guideLine).subscribe(
      res => {
        this.loadGuidlines();
        $("#guidlineEditModel").modal("hide");
        this.alert.success("faq is updated successfully")
      },
      err => {
        console.log("Error", err);
      }
    );
  }
}
