import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  resultFinalistModel,
  TopRequestParam,
  FinalPublishResultFilter
} from "../admin_management/models/models";
import {
  PropertyMetaModel,
  GridColumnType,
  PaginationModel
} from "../_models/pagination-model";
import { SchoolService } from "../_service/school-service.service";
import { HomeServiceService } from "../_service/home-service.service";
import { AlertService } from "../_service/alert.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-view-result",
  templateUrl: "./view-result.component.html",
  styleUrls: ["./view-result.component.css"]
})
export class ViewResultComponent implements OnInit {
  public finalistRequestParam: resultFinalistModel = new resultFinalistModel();
  public top3RequestParam: TopRequestParam = new TopRequestParam();
  public finalPublishRequestParam: FinalPublishResultFilter = new FinalPublishResultFilter();

  public loader: boolean = false;
  public juniorGridData: any[] = [];
  public seniorGridData: any[] = [];
  public displayColumns: PropertyMetaModel[];
  public top3displayColumns: PropertyMetaModel[];
  public finalistdisplayColumns: PropertyMetaModel[];
  public finalPublishDisplayColumns: PropertyMetaModel[];
  public isResultPublished: any;

  public isJuniorDivVisble: boolean;
  public isSeniorDivVisble: boolean;
  public type: any;

  public juniorPageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 1,
    TotalRecord: 0
  };

  public seniorPageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 1,
    TotalRecord: 0
  };

  public PageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 1,
    TotalRecord: 0
  };
  IsQualifyPublished: any;
  IsprePublished: any;

  constructor(
    private schoolService: SchoolService,
    private homeService: HomeServiceService,
    private ref: ChangeDetectorRef,
    private alert: AlertService,
    private router: ActivatedRoute
  ) {
    (this.finalistdisplayColumns = [
      {
        PropertyName: "StateCode",
        ColumnName: "Province",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "Name",
        ColumnName: "Candidate Name",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "SchoolName",
        ColumnName: "School",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "CandidateNumber",
        ColumnName: "CandidateNumber",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "Score",
        ColumnName: "Candidate Score",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "status",
        ColumnName: "Qualification Status",
        PropertyType: GridColumnType.Text
      }
    ]),
      (this.top3displayColumns = [
        {
          PropertyName: "StateCode",
          ColumnName: "Province",
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: "SchoolName",
          ColumnName: "Candidate School",
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: "Name",
          ColumnName: "Candidate Name",
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: "CandidateRegistrationNo",
          ColumnName: "Candidate No.",
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: "Score",
          ColumnName: "Score",
          PropertyType: GridColumnType.Text
        },
        {
          PropertyName: "Rank",
          ColumnName: "Rank",
          PropertyType: GridColumnType.Text
        }
      ]),
      (this.finalPublishDisplayColumns = [
        {
          PropertyName: "StateCode",
          ColumnName: "Province",
          PropertyType: GridColumnType.Text,
          IsSortable: true
        },
        {
          PropertyName: "SchoolName",
          ColumnName: "Candidate School",
          PropertyType: GridColumnType.Text,
          IsSortable: true
        },
        {
          PropertyName: "Name",
          ColumnName: "Candidate Name",
          PropertyType: GridColumnType.Text,
          IsSortable: true
        },
        {
          PropertyName: "CandidateRegistrationNo",
          ColumnName: "Candidate No.",
          PropertyType: GridColumnType.Text,
          IsSortable: true
        },
        {
          PropertyName: "Score",
          ColumnName: "Score",
          PropertyType: GridColumnType.Text,
          IsSortable: true
        }
       
      ]);
    this.top3RequestParam.pageno = this.PageInfo.CurrentPage;
    this.top3RequestParam.pagesize = this.PageInfo.PageSize;
    this.finalPublishRequestParam.pagesize = this.PageInfo.PageSize;
    this.finalPublishRequestParam.pageno = this.PageInfo.CurrentPage;
  }

  ngOnInit() {
    this.router.params.subscribe(param => {
      this.type = param["type"];
      this.finalPublishRequestParam = new FinalPublishResultFilter();
      if (this.type == "finalists") {
        this.getAllJuniorFinalists();
        this.getAllSeniorFinalists();
      } else if (this.type == "top3") {
        this.getAllTop3JuniorFinalists();
        this.getAllTop3SeniorFinalists();
      } else if (this.type == "Junior") {
        this.getOnlyJuniorFinalists();
      } else {
        this.getOnlySeniorFinalists();
      }
    });
    this.getIsResultPublished();
  }

  ngOnDestroy() {}

  getAllJuniorFinalists() {
    this.finalistRequestParam.Category = "Junior";
    this.finalistRequestParam.pageno = this.juniorPageInfo.CurrentPage;
    this.finalistRequestParam.pagesize = this.juniorPageInfo.PageSize;
    this.loader = true;
    this.homeService.getPublishResult(this.finalistRequestParam).subscribe(
      (res: any) => {
        this.loader = false;
        if (res && res.List) {
          res.List.forEach(x => {
            x.Name = x.Name.toUpperCase();
            x.SchoolName = x.SchoolName.toUpperCase();
            x.StateCode = x.StateCode.toUpperCase();
          });
          this.juniorGridData = res.List;
          this.displayColumns = this.finalistdisplayColumns;
          this.isJuniorDivVisble =
            this.juniorGridData.length > 0 ? true : false;
          this.juniorPageInfo.TotalRecord = res.TotalRecords;
        } else {
          this.juniorGridData = [];
        }
      },
      error => {
        this.loader = false;
        this.juniorGridData = [];
        this.alert.error(error);
      }
    );
  }
  getAllSeniorFinalists() {
    this.loader = true;
    this.finalistRequestParam.Category = "Senior";
    this.finalistRequestParam.pageno = this.seniorPageInfo.CurrentPage;
    this.finalistRequestParam.pagesize = this.seniorPageInfo.PageSize;
    this.homeService.getPublishResult(this.finalistRequestParam).subscribe(
      (res: any) => {
        this.loader = false;
        if (res && res.List) {
          res.List.forEach(x => {
            x.Name = x.Name.toUpperCase();
            x.SchoolName = x.SchoolName.toUpperCase();
            x.StateCode = x.StateCode.toUpperCase();
          });
          this.seniorGridData = res.List;
          this.displayColumns = this.finalistdisplayColumns;
          this.seniorPageInfo.TotalRecord = res.TotalRecords;
          this.isSeniorDivVisble =
            this.seniorGridData.length > 0 ? true : false;
        } else {
          this.seniorGridData = [];
        }
      },
      error => {
        this.loader = false;
        this.seniorGridData = [];
        this.alert.error(error);
      }
    );
  }

  getAllTop3JuniorFinalists() {
    this.loader = true;
    this.top3RequestParam.pageno = this.juniorPageInfo.CurrentPage;
    this.top3RequestParam.pagesize = this.juniorPageInfo.PageSize;
    this.top3RequestParam.Category = "Junior";
    this.homeService.getTop3Result(this.top3RequestParam).subscribe(
      (res: any) => {
        this.loader = false;
        if (res && res.List) {
          res.List.forEach(x => {
            x.Name = x.Name.toUpperCase();
            x.SchoolName = x.SchoolName.toUpperCase();
            x.StateCode = x.StateCode.toUpperCase();
          });
          this.juniorGridData = res.List;
          this.displayColumns = this.top3displayColumns;
          this.isJuniorDivVisble =
            this.juniorGridData.length > 0 ? true : false;
          this.juniorPageInfo.TotalRecord = res.TotalRecords;
        } else {
          this.juniorGridData = [];
        }
      },
      error => {
        this.loader = false;
        this.juniorGridData = [];
        this.alert.error(error);
      }
    );
  }

  getAllTop3SeniorFinalists() {
    this.loader = true;
    this.top3RequestParam.Category = "Senior";
    this.top3RequestParam.pageno = this.seniorPageInfo.CurrentPage;
    this.top3RequestParam.pagesize = this.seniorPageInfo.PageSize;
    this.homeService.getTop3Result(this.top3RequestParam).subscribe(
      (res: any) => {
        this.loader = false;
        if (res && res.List) {
          res.List.forEach(x => {
            x.Name = x.Name.toUpperCase();
            x.SchoolName = x.SchoolName.toUpperCase();
            x.StateCode = x.StateCode.toUpperCase();
          });
          this.seniorGridData = res.List;
          this.displayColumns = this.top3displayColumns;
          this.seniorPageInfo.TotalRecord = res.TotalRecords;
          this.isSeniorDivVisble =
            this.seniorGridData.length > 0 ? true : false;
        } else {
          this.seniorGridData = [];
        }
      },
      error => {
        this.loader = false;
        this.seniorGridData = [];
        this.alert.error(error);
      }
    );
  }

  getOnlySeniorFinalists() {
    this.loader = true;
    this.finalPublishRequestParam.pageno = this.seniorPageInfo.CurrentPage;
    this.finalPublishRequestParam.pagesize = this.seniorPageInfo.PageSize;
    this.finalPublishRequestParam.SortBy = this.seniorPageInfo.SortBy;
    this.finalPublishRequestParam.SortOrder = this.seniorPageInfo.SortOrder;
    this.finalPublishRequestParam.Category = "Senior";
    this.homeService
      .getFinalPublishResult(this.finalPublishRequestParam)
      .subscribe(
        (res: any) => {
          this.loader = false;
          if (res && res.List) {
            res.List.forEach(x => {
              x.Name = x.Name.toUpperCase();
              x.SchoolName = x.SchoolName.toUpperCase();
              x.StateCode = x.StateCode.toUpperCase();
            });
            this.seniorGridData = res.List;
            this.displayColumns = this.finalPublishDisplayColumns;
            this.seniorPageInfo.TotalRecord = res.TotalRecords;
            this.isSeniorDivVisble =
              this.seniorGridData.length > 0 ? true : false;
          } else {
            this.seniorGridData = [];
          }
        },
        error => {
          this.loader = false;
          this.seniorGridData = [];
          this.alert.error(error);
        }
      );
  }

  getOnlyJuniorFinalists() {
    this.loader = true;
    this.finalPublishRequestParam.pageno = this.juniorPageInfo.CurrentPage;
    this.finalPublishRequestParam.pagesize = this.juniorPageInfo.PageSize;
    this.finalPublishRequestParam.SortBy = this.juniorPageInfo.SortBy;
    this.finalPublishRequestParam.SortOrder = this.juniorPageInfo.SortOrder;
    this.finalPublishRequestParam.Category = "Junior";
    this.homeService
      .getFinalPublishResult(this.finalPublishRequestParam)
      .subscribe(
        (res: any) => {
          this.loader = false;
          if (res && res.List) {
            res.List.forEach(x => {
              x.Name = x.Name.toUpperCase();
              x.SchoolName = x.SchoolName.toUpperCase();
              x.StateCode = x.StateCode.toUpperCase();
            });
            this.juniorGridData = res.List;
            this.displayColumns = this.finalPublishDisplayColumns;
            this.isJuniorDivVisble =
              this.juniorGridData.length > 0 ? true : false;
            this.juniorPageInfo.TotalRecord = res.TotalRecords;
          } else {
            this.juniorGridData = [];
          }
        },
        error => {
          this.loader = false;
          this.juniorGridData = [];
          this.alert.error(error);
        }
      );
  }

  getIsResultPublished() {
    this.loader = true;
    this.schoolService.getIsResultPublished().subscribe(
      (res: any) => {
        this.loader = false;
        if (res && res.List) {
          this.isResultPublished = res.List[0];
          this.IsprePublished = res.List[0].IsprePublished
          this.IsQualifyPublished = res.List[0].IsQualifyPublished
          this.ref.detectChanges();
        }
      },
      error => {
        this.loader = false;
      }
    );
  }

  juniorPageInfoChange(event) {
    this.juniorPageInfo.SortBy = event.SortBy;
    this.juniorPageInfo.SortOrder = event.SortOrder;
    this.juniorPageInfo.CurrentPage = event.CurrentPage;
    this.juniorPageInfo.PageSize = event.PageSize;
    if(this.juniorPageInfo.CurrentPage > (this.juniorPageInfo.TotalRecord/ this.juniorPageInfo.PageSize)+1){
      this.juniorPageInfo.CurrentPage = 1
    }
    if (this.type == "finalists") this.getAllJuniorFinalists();
    else if (this.type == "top3") this.getAllTop3JuniorFinalists();
    else this.getOnlyJuniorFinalists();
  }

  seniorPageInfoChange(event) {
    this.seniorPageInfo.SortBy = event.SortBy;
    this.seniorPageInfo.SortOrder = event.SortOrder;
    this.seniorPageInfo.CurrentPage = event.CurrentPage;
    this.seniorPageInfo.PageSize = event.PageSize;
    if(this.seniorPageInfo.CurrentPage > (this.seniorPageInfo.TotalRecord/ this.seniorPageInfo.PageSize)+1){
      this.seniorPageInfo.CurrentPage = 1
    }
    if (this.type == "finalists") this.getAllSeniorFinalists();
    else if (this.type == "top3") this.getAllTop3SeniorFinalists();
    else this.getOnlySeniorFinalists();
  }

  action(event) {}

  Filter(value) {
    if (value == "Junior") this.getOnlyJuniorFinalists();
    else this.getOnlySeniorFinalists();
  }
}
