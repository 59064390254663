import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.css']
})
export class SponsorComponent implements OnInit {
  countryType:string;

  constructor() { }

  ngOnInit() {
    this.countryType = localStorage.getItem('type')
    if (this.countryType) {
      this.countryType = localStorage.getItem('type')
    }
    else {
      this.countryType = 'Nigeria'
    
  }
    
  }

}
