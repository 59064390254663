import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from "src/app/_service/home-service.service";
import { hallOfFame } from '../model/config';
import { HallOfFame, HallOfFameFilter } from '../models/home-page-model/home-page-model.module';
import { AlertService } from '../_service/alert.service';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-hall-of-fame',
  templateUrl: './hall-of-fame.component.html',
  styleUrls: ['./hall-of-fame.component.css']
})
export class HallOfFameComponent implements OnInit {
  type:any
  year : any = []
  selectedYear : HallOfFameFilter = new HallOfFameFilter()
  dispYear: any
  data : HallOfFame = new HallOfFame()
  loader : boolean = false
  serviceBaseUrl: string;

  constructor(public service: HomeServiceService,
    private alertService: AlertService,

    ) { 
      if(isDevMode){
        this.serviceBaseUrl = 'https://testserver.quicklearning.africa';
      }
      else {
        this.serviceBaseUrl = 'https://mathserver.quicklearning.africa'
      }
    }
  ngOnInit() {
    this.generateYear()
    this.getHallOfFame()
  }
  generateYear(){
    var currentYear = (new Date()).getFullYear();
    for (var i = 2000; i <= currentYear; i++) {
      this.year.push(i);
  }
  }
  getHallOfFame(){
    this.loader = true

    this.service.HallOfFameApi(this.selectedYear).subscribe((res:any)=>{
      this.loader = false;
      this.data = res;
      this.selectedYear.totalPage = res.TotalRecords
        this.selectedYear.totalPage = <number>Math.ceil(this.selectedYear.totalPage /3);
      this.dispYear = this.data.List[0].Year

    },
    (error) => {
      this.loader = false;
      this.alertService.error(error, true);
    });
  }
  reset(){
    this.selectedYear.Year = null
    this.getHallOfFame()
  }

  previous(){
    this.selectedYear.PageNo = this.selectedYear.PageNo - 1
    this.getHallOfFame()
  }
   next(){
    this.selectedYear.PageNo = this.selectedYear.PageNo + 1
    this.getHallOfFame()
   }
}
