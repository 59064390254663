import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";
import * as url from "src/app/model/config";
import 'rxjs/add/operator/map';
import { Observable, Observer } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class HomeSecondService {
  resendLinktoEmail(resendLink): any {
    const Url = url.resendLink+resendLink;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.get(Url,httpOptions);
  }
   
  africaResendLinktoEmail(resendLink): any {
    const Url = url.africaResendLink+resendLink;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.get(Url,httpOptions);
  }

  universityResendLinktoEmail(resendLink): any {
    const Url = url.universityResendLink+resendLink;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.get(Url,httpOptions);
  }
  // 
  updateEmail(changeEmail: { SchoolEmail: string; Id: string; }): any {
    const Url = url.updateEmail;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.post(Url,changeEmail, httpOptions);
  } 

  updateAfricaCandidateEmail(changeCandidateEmail: { CandidateEmail: string; Id: string; }): any {
    const Url = url.africaUpdateEmail;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.post(Url,changeCandidateEmail, httpOptions);
  } 
  updateUnversityCandidateEmail(changeCandidateEmail: { CandidateEmail: string; Id: string; }): any {
    const Url = url.universityUpdateEmail;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.post(Url,changeCandidateEmail, httpOptions);
  } 
  

  constructor(private httpClient: HttpClient) { }


  getGuideLine = function () {
    const Url = url.getGuideLineURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.get(Url, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  };


  updateGuideline = function (data) {
    const Url = url.updateGuidelineURI;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json"
      })
    };
    return this.httpClient.post(Url, data).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  };


  getallFaqs = function () {
    const Url = url.getallfaqURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.get(Url, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }



  getYears = function (data) {

    const Url = url.getYear;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.post(Url, httpOptions, data).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }




  LoadQuestions = function (data) {
    const Url = url.loadPastQuestions;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.post(Url, data, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }



  candidates = function (data) {

    const Url = url.candidateGetAllcandidate;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.post(Url, data, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  };
  // getPdfURI = function (data) {

  //   const Url = url.getPdfURI;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json"
  //     })
  //   };
  //   return this.httpClient.post(Url, data, httpOptions).map(response => response)
  //     .catch((error: any) => {
  //       return Observable.throw(error.error);
  //     });
  // };
  getPdfURIApi(filter) {
    const Url = url.getPdfURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.post(Url, filter, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  // getPdfURI = function (data) {

  //   const Url = url.getPdfURI;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json"
  //     })
  //   };
  //   return this.httpClient.post(Url, data, httpOptions).map(response => response)
  //     .catch((error: any) => {
  //       return Observable.throw(error.error);
  //     });
  // };
  
  candidatesAdvance = function (data) {

    const Url = url.candidateGetAlladvancecandidate;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.post(Url, data, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  };
  candidatesUniversity = function (data) {

    const Url = url.candidateGetAllUniversitycandidate;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.post(Url, data, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  };
  
  getRegistrationStatus = function (id: number) {
    const Url = url.getRegistrationStatusURI + '?school=' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient.get(Url, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  registerAllCandidate = function (data: Object, captureDevice: any) {

    const Url = url.candidateRegisterAllURI + captureDevice;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }
    return this.httpClient.post(Url, data, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      })
  }
  getSchoolList = function (data: Object) {
    const Url = url.schoolListingURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }
    return this.httpClient.post(Url, data, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      })
  }
  getSchool = function (id: number) {
    const Url = url.getSchoolDetails;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }
    return this.httpClient.get(`${Url}?id=${id}`, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      })
  }
  uploadFileService = function (file) {
    const Url = url.validateSchools;
    return this.httpClient.post(Url, file).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      })
  }

  exportExcelFile = function () {
    const Url = url.getValidateSchools;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }
    return this.httpClient.get(Url, httpOptions).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      })
  }
  deleteSchool = function (id) {
    const Url = url.schoolDeleteURI;
    return this.httpClient.post(Url + '?id=' + id).map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error)
      })
  }
  updateSchool = function (data) {
    const Url = url.schoolUpdateURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, data, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  deRegisterCandidateApi(id){
    const Url = url.deRegisterCandidate + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getYear() {
    var body: Object = { headers: { normalizedNames: {}, lazyUpdate: null } };
    const Url = url.getYear;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, body, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  resetPassword(data) {
    const Url = url.resetPasswordURI;
    return this.httpClient.post(Url, data)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getSettingData() {
    const Url = url.getSettings;
    return this.httpClient.get(Url)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  submitForm(data) {
    const Url = url.updatesettings;
    return this.httpClient.post(Url, data)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getConcerns() {
    const Url = url.concernTypeURI;
    return this.httpClient.get(Url)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      })
  }
  getfeedback(data) {
    const Url = url.feedbacksURI;
    return this.httpClient.post(Url, data)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      })
  }
  getSeasons(data) {
    const Url = url.getPortalseasonURI;
    return this.httpClient.post(Url, data)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      })
  }
  updateArchivedSeason(data){
    const Url = url.updateArchiveSeasonURI;
    return this.httpClient.post(Url, data)
    .map(response => response)
    .catch((error: any) => {
      return Observable.throw(error.error);
    })
  }
  deleteSeason(data){
    const Url = url.deleteSeasonURI;
    return this.httpClient.post(Url, data)
    .map(response => response)
    .catch((error: any) => {
      return Observable.throw(error.error);
    })
  }
  publishResult(data) {
    const Url = url.updatePublishSeasonURI;
    return this.httpClient.post(Url, data)
    .map(response => response)
    .catch((error: any) => {
      return Observable.throw(error.error);
    })
  }
  updateSeason(data) {
    const Url = url.modifySeasonURI;
    return this.httpClient.post(Url, data)
    .map(response => response)
    .catch((error: any) => {
      return Observable.throw(error.error);
    })
  }
  addSeason(data) {
    const Url = url.modifySeasonURI;
    return this.httpClient.post(Url, data)
    .map(response => response)
    .catch((error: any) => {
      return Observable.throw(error.error);
    })
  }

//Phase 2 Services
getCompendiumYears()
{
  const Url = url.getCompendiumYears;
  const httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };
  return this.httpClient.get(Url, httpOptions).map(response => response)
    .catch((error: any) => {
      return Observable.throw(error.error);
    });
}
getCompendiumVolumes()
{
  const Url = url.getCompendiumVolumes;
  const httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };
  return this.httpClient.get(Url, httpOptions).map(response => response)
    .catch((error: any) => {
      return Observable.throw(error.error);
    });
}

getSyllabusYears()
{
  const Url = url.getSyllabusYears;
  const httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };
  return this.httpClient.get(Url, httpOptions).map(response => response)
    .catch((error: any) => {
      return Observable.throw(error.error);
    });
}

LoadAnswers(data)
{
  const Url = url.loadAnswers;
  const httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };
  return this.httpClient.post(Url, data, httpOptions).map(response => response)
    .catch((error: any) => {
      return Observable.throw(error.error);
    });
}

LoadSyllabus(data)
{
  const Url = url.getSyllabus;
  const httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };
  return this.httpClient.post(Url, data, httpOptions).map(response => response)
    .catch((error: any) => {
      return Observable.throw(error.error);
    });
}


}
