import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
} from "@angular/forms";
import { AdvanceDetails } from 'src/app/_models/advance-model';
import * as pattern from "../model/user"
import { IMyDpOptions, IMyDateModel, IMyDefaultMonth } from 'mydatepicker';
import { HomeServiceService } from 'src/app/_service/home-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from 'src/app/_service/school-service.service';
import { AlertService } from '../_service/alert.service';
import { MustMatch } from '../_shared/school-registration/password-match.validator';
declare var $: any;
declare var jic: any;

@Component({
  selector: 'app-advance-signup-university',
  templateUrl: './advance-signup-university.component.html',
  styleUrls: ['./advance-signup-university.component.css']
})
export class AdvanceSignupUniversityComponent implements OnInit {
  public AdvanceDetails: AdvanceDetails = new AdvanceDetails();
  public loader: boolean;
  countryList: any = [];
  id = '';
  statesList: any = [];
  registrationType: string;
  candidateRegForm: FormGroup;
  password;
  fileList: FileList;
  uploadStudentImage: any;
  reader = new FileReader();
  tempImg: string | ArrayBuffer;
  timeDropDownList = [];
  show: boolean = false;
  isSubmitted: boolean = false;
  public getClass: any;
  checkboxEnabled: boolean = false;
  submittedError: boolean = false;
  url: any;
  public passwordType: string = "password";
  // affiliatedList: any = [
  //   { value: "Junior", name: "Junior" },
  //   { value: "Senior", name: "Senior" },
  // ];
  levelOfStudy: any = [{ id: "100", name: "100" },
  { id: "200", name: "200" },
  { id: "300", name: "300" },
  { id: "400", name: "400" },
  { id: "500", name: "500" }
  ]
  public LastDate: Date = new Date();
  public PreviousDate: Date = new Date();
  public defaultMonth: IMyDefaultMonth = {
    defMonth: `0${this.LastDate.getMonth()}/${this.LastDate.getFullYear() - 15}`
  };
  public mytime: Date = new Date();
  currentYear: any = this.mytime.getUTCFullYear();
  currentDate: any = this.mytime.getUTCDate();
  currentMonth: any = this.mytime.getUTCMonth() + 1;
  public myDatePickerOptions: IMyDpOptions = {
    inline: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    disableSince: {
      year: this.currentYear - 15,
      month: this.currentMonth + 1,
      day: this.currentDate
    },
    disableUntil: {
      year: this.PreviousDate.getFullYear() - 40,
      month: this.PreviousDate.getMonth() + 1,
      day: this.PreviousDate.getDate()
    },
    dateFormat: 'dd-mmm-yyyy',
  };
  public startDate: Object = {
    date: {
      year: 2008,
      month: this.PreviousDate.getMonth(),
      day: this.PreviousDate.getDate()
    }
  };
  enable: boolean;
  getTimeSlot: { id: number; value: string; }[];
  classList: any;
  affiliatedList: any;

  constructor(
    private fb: FormBuilder,
    private service: HomeServiceService,
    private ref: ChangeDetectorRef,
    // private secondService: HomeSecondService,
    private alertService: AlertService,
    // private route: ActivatedRoute,
    private _router: Router,
    private el: ElementRef,
    private activeRoute: ActivatedRoute,
    private schoolService: SchoolService
  ) { }
  ngOnInit() {
    // this.registrationType = 'Advance';
    // this.registrationType = 'Continental';
    this.activeRoute.queryParams.subscribe((params) => {
      if (params["type"] === "advn") {
        this.registrationType = 'Advance';
      }
      else {
        this.registrationType = 'Continental';
      }
      if (params["Id"]) {
        this.id = JSON.parse(localStorage.getItem("logincredential")).body.Id;
      }
    });
    this.getTimeSlot = [{ id: 1, value: '9am-10am' },
    { id: 2, value: '12pm-1pm' },
    { id: 3, value: '3pm-4pm' }]
    this.getCourseOfStudy()
    // this.getTimeDropDownList();
    this.password = 'password'
    this.candidateForm();
    this.setValidators();

  }

  candidateForm() {
    this.candidateRegForm = this.fb.group({
      Id: [this.id],
      RegistrationType: [this.registrationType],
      ProfilePic: ["", [Validators.required]],
      FirstName: ["", [Validators.required]],
      LastName: ["", [Validators.required]],
      CandidateEmail: ["", [Validators.required, Validators.email]],
      CandidatePhone: [
        "",
        [
          Validators.minLength(7),
          Validators.required,
          Validators.pattern(pattern.phonePattern),
        ],
        // this.service.phoneExistValidator(),
      ],
      Dob: ["", [Validators.required]],
      State: ["", [Validators.required]],
      Country: ["", [Validators.required]],
      Gender: ["", [Validators.required]],
      CourseOfStudy: ["", [Validators.required]],
      LevelOfStudy: ["", [Validators.required]],
      Address: ["", [Validators.required]],
      SchoolName: ["", [Validators.required]],
      SchoolEmail: ["", [Validators.required, Validators.email]],
      SchoolPhone: [""
        // this.service.phoneExistValidator(),
      ],
      OriginState: ["", [Validators.required]],
      ResidenceState: ["", [Validators.required]],
      Password: [""],
      ConfirmPassword: [""],
      PostalAddress: [""],
      ParentName: [""],
      ParentEmail: [""],
      ParentPhone: ["",
        // this.service.phoneExistValidator(),
      ],
      MathTutorName: [""],
      MathTutorEmail: [""],
      MathTutorPhone: [""
        // this.service.phoneExistValidator(),
      ],
      PreferedTime: [""],
      // preferedEndTime: [""],
      prepClass: [""]
    },
      { validator:MustMatch("Password", "ConfirmPassword") }
    );
    this.getCountry();

    if (this.id != '' && this.id != null) {
      // debugger
      this.getCandidateDetails();
      this.candidateRegForm.get('Password').clearValidators();
      this.candidateRegForm.get('Password').updateValueAndValidity();
      this.candidateRegForm.get('ConfirmPassword').clearValidators();
      this.candidateRegForm.get('ConfirmPassword').updateValueAndValidity();
      // this.candidateRegForm.get('ConfirmPassword').setErrors({mustMatch: null});
    // }
    }
    else if (!this.id) {
      this.candidateRegForm.get('Password').setValidators([Validators.required, Validators.minLength(6)]);
      this.candidateRegForm.get('Password').updateValueAndValidity();
      this.candidateRegForm.get('ConfirmPassword').setValidators([Validators.required]);
      this.candidateRegForm.get('ConfirmPassword').updateValueAndValidity();
      // this.candidateRegForm.get('validator'),(MustMatch("Password", "ConfirmPassword"));
    }
  }

  CandidateRegistration(form) {
    // debugger
    form.value.Id = this.id
    this.isSubmitted = true;
    if (this.id != '' && this.id != null) {
    this.candidateRegForm.get('ConfirmPassword').setErrors(null);
    this.candidateRegForm.get('ConfirmPassword').clearValidators();
    }
    if (this.candidateRegForm.invalid) {
      this.submittedError = true;
      for (const key of Object.keys(this.candidateRegForm.controls)) {
        if (this.candidateRegForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          $(invalidControl).focus();
          break;
        }
      }
      return;
    }

    if (form.value.Dob.date) {
      let dateofbirth = form.value.Dob.date;
      form.value.Dob = new Date(dateofbirth.year, dateofbirth.month - 1, dateofbirth.day).toISOString()
    } else {
      let dateofbirth = form.value.Dob
      form.value.Dob = new Date(dateofbirth).toISOString();
    }

    form.value.Password = btoa(this.candidateRegForm.value.Password);
    form.value.ConfirmPassword = btoa(this.candidateRegForm.value.ConfirmPassword);
    this.loader = true;
    console.log(form.value)
    // this.setModelValue(form.value);
    this.service.CandidateRegister(this.candidateRegForm.value).subscribe((res) => {
      this.loader = false;
      if (res) {
        if (this.id) {
          this._router.navigateByUrl("advance/advance-dashboard");
          this.alertService.success("Updated Successfully");

        } else {
          this._router.navigateByUrl("login");
          this.alertService.success("Signup Successful");
        }
      }
      // if (res) this.alertService.success(res.toString(), true);

    },
      (error) => {
        this.loader = false;
        this.alertService.error(error, true);
      });

  }

  get s() {
    return this.candidateRegForm.controls;
  }
  setValidators() {
    if (this.registrationType === "Continental") {
      this.candidateRegForm.get('ParentName').setValidators([Validators.required]);
      this.candidateRegForm.get('ParentName').updateValueAndValidity();
      this.candidateRegForm.get('ParentEmail').setValidators([Validators.required, Validators.email]);
      this.candidateRegForm.get('ParentEmail').updateValueAndValidity();
      this.candidateRegForm.get('ParentPhone').setValidators([Validators.required,
      Validators.minLength(7),
      Validators.pattern(pattern.phonePattern),]);
      this.candidateRegForm.get('ParentPhone').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorName').setValidators([Validators.required]);
      this.candidateRegForm.get('MathTutorName').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorEmail').setValidators([Validators.required, Validators.email]);
      this.candidateRegForm.get('MathTutorEmail').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorPhone').setValidators([Validators.required,
      Validators.minLength(7),
      Validators.pattern(pattern.phonePattern)]);
      this.candidateRegForm.get('MathTutorPhone').updateValueAndValidity();
      this.candidateRegForm.get('SchoolEmail').setValidators([Validators.required, Validators.email]);
      this.candidateRegForm.get('SchoolEmail').updateValueAndValidity();
      this.candidateRegForm.get('SchoolPhone').setValidators([Validators.required,
      Validators.minLength(7),
      Validators.pattern(pattern.phonePattern)]);
      this.candidateRegForm.get('SchoolPhone').updateValueAndValidity();
      // this.candidateRegForm.get('ProfilePic').setValidators([Validators.required]);
      // this.candidateRegForm.get('ProfilePic').updateValueAndValidity();
    }
    else {
      this.candidateRegForm.get('ParentName').clearValidators();
      this.candidateRegForm.get('ParentName').updateValueAndValidity();
      this.candidateRegForm.get('ParentEmail').clearValidators();
      this.candidateRegForm.get('ParentEmail').updateValueAndValidity();
      this.candidateRegForm.get('ParentPhone').clearValidators();
      this.candidateRegForm.get('ParentPhone').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorName').clearValidators();
      this.candidateRegForm.get('MathTutorName').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorEmail').clearValidators();
      this.candidateRegForm.get('MathTutorEmail').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorPhone').clearValidators();
      this.candidateRegForm.get('MathTutorPhone').updateValueAndValidity();
      this.candidateRegForm.get('SchoolEmail').clearValidators();
      this.candidateRegForm.get('SchoolEmail').updateValueAndValidity();
      this.candidateRegForm.get('SchoolPhone').clearValidators();
      this.candidateRegForm.get('SchoolPhone').updateValueAndValidity();
      // this.candidateRegForm.get('ProfilePic').clearValidators();
      // this.candidateRegForm.get('ProfilePic').updateValueAndValidity();
    }
  }

  // setModelValue(data){
  //   this.AdvanceDetails.Id = data.Id
  //   this.AdvanceDetails.RegistrationType = data.RegistrationType
  //   this.AdvanceDetails.ProfilePic = data.ProfilePic
  //   this.AdvanceDetails.FirstName =data.FirstName
  //   this.AdvanceDetails.LastName =data.LastName
  //   this.AdvanceDetails.CandidateEmail =data.CandidateEmail
  //   this.AdvanceDetails.CandidatePhone =data.CandidatePhone
  //   this.AdvanceDetails.Dob =data.Dob
  //   this.AdvanceDetails.State =data.State
  //   this.AdvanceDetails.Country  =data.Country
  //   this.AdvanceDetails.Gender =data.Gender
  //   this.AdvanceDetails.CourseOfStudy =data.CourseOfStudy
  //   this.AdvanceDetails.LevelOfStudy =data.LevelOfStudy
  //   this.AdvanceDetails.Address =data.Address
  //   this.AdvanceDetails.SchoolName =data.SchoolName
  //   this.AdvanceDetails.SchoolEmail =data.SchoolEmail
  //   this.AdvanceDetails.SchoolPhone =data.SchoolPhone
  //   this.AdvanceDetails.OriginState =data.OriginState
  //   this.AdvanceDetails.ResidenceState =data.ResidenceState
  //   this.AdvanceDetails.Password =data.Password
  //   this.AdvanceDetails.ConfirmPassword =data.ConfirmPassword
  //   this.AdvanceDetails.PostalAddress =data.PostalAddress
  //   this.AdvanceDetails.ParentName =data.ParentName
  //   this.AdvanceDetails.ParentEmail =data.ParentEmail
  //   this.AdvanceDetails.ParentPhone =data.ParentPhone
  //   this.AdvanceDetails.MathTutorName =data.MathTutorName
  //   this.AdvanceDetails.MathTutorEmail =data.MathTutorEmail
  //   this.AdvanceDetails.MathTutorPhone =data.MathTutorPhone
  //   this.AdvanceDetails.preferedstartTime =data.preferedstartTime
  //   this.AdvanceDetails.preferedEndTime =data.preferedEndTime
  //   this.AdvanceDetails.prepClass =data.prepClass
  // }

  getClassbyCategory(event) {
    // debugger
    var cat = event.target.value;
    this.schoolService.getClass(cat).subscribe(
      res => {
        this.getClass = res;
      },
      error => { }
    );
  }
  numbersOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // onFileChange(event) {
  //   if (event.target.files && event.target.files.length) {
  //     const reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       this.tempImg = (<FileReader>event.target).result;
  //       this.candidateRegForm.patchValue({
  //         ProfilePic: event.target.result
  //       });
  //       this.alertService.success("uploaded successfully")
  //     };
  //     reader.readAsDataURL(event.target.files[0]);
  //     this.ref.markForCheck();
  //   }

  // }
  onFileChange(event) {

    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.tempImg = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.ref.markForCheck();
    }

    this.fileList = event.target.files;
    this.uploadStudentImage = event.target.files[0];
    if (this.fileList.length > 0) {
      let file: File = this.fileList[0];
      // if(file.size > 204800){
      //   this.resizeImage(file);
      // }
      // else {
      // // this.candidateRegForm.get("ProfilePic").setValue(file);
      // }
    }
  }
  public resizeImage() {

    let imgFile;
    if (typeof this.uploadStudentImage === "string") {
      imgFile = this.dataURLtoFile(this.uploadStudentImage, "myfile.jpg");
    } else {
      imgFile = this.uploadStudentImage;
    }
    if (imgFile && imgFile.size > 204800) {
      const src_img: any = document.getElementById("myprofileimg");
      const percentage = (200 * 100) / (imgFile.size / 1024);
      const targetImg = jic.compress(src_img, Math.floor(percentage), "jpg")
        .src;

      const file = this.dataURLtoFile(targetImg, imgFile.name);
      this.uploadStudentImage = file;
      // this.imageToVerify = file;
      src_img.src = targetImg;
      if (file && file.size > 204800) {
        this.resizeImage();
      } else {
        this.candidateRegForm
          .get("ProfilePic")
          .setValue(this.uploadStudentImage);
        this.reader.readAsDataURL(this.uploadStudentImage);
      }
    } else {

      this.candidateRegForm
        .get("ProfilePic")
        .setValue(this.uploadStudentImage);
    }
  }

  dataURLtoFile(dataurl, filename) {
    // tslint:disable-next-line:prefer-const
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      // tslint:disable-next-line:prefer-const
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getCountry() {
    this.service.getCountry().subscribe(
      (res: any) => {
        this.countryList = res;
      },
      (error) => { }
    );
  }
  getStatesname(id) {
    this.service.getStatefromCountry(id).subscribe(
      (res: any) => {
        this.statesList = res;
      },
      (error) => { }
    );
  }
  showPassword() {
    if (this.password === "password") {
      this.password = "text";
      this.show = true;
    } else {
      this.password = "password";
      this.show = false;
    }
  }
  nameValidations(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  // prep classes
  // getTimeAsNumberOfMinutes(time) {
  //   var timeParts = time.split(":");

  //   var timeInMinutes = (timeParts[0] * 60) + timeParts[1];

  //   return timeInMinutes;
  // }

  // getTimeDropDownList() {
  //   for (let i = 1; i <= 24; i++) {
  //     for (let j = 1; j <= 2; j++) {
  //       let time = {
  //         value: '',
  //         viewValue: ''
  //       }
  //       if (j == 1) {
  //         time.value = i < 12 ? i + ':' + '00' + ' AM' : i + ':' + '00' + ' PM'
  //         time.viewValue = i < 12 ? i + ':' + '00' + ' AM' : i + ':' + '00' + ' PM'
  //         this.timeDropDownList.push(time)
  //       } else {
  //         if (i != 24) {
  //           time.value = i < 12 ? i + ':' + '30' + ' AM' : i + ':' + '30' + ' PM'
  //           time.viewValue = i < 12 ? i + ':' + '30' + ' AM' : i + ':' + '30' + ' PM'
  //           this.timeDropDownList.push(time)

  //         }
  //       }
  //     }
  //   }
  // }
  // timeChange(value, type) {
  //   if (type == 'startTime') {
  //     this.candidateRegForm.value.preferedstartTime = value
  //   }
  //   else {
  //     this.candidateRegForm.value.preferedEndTime = value
  //   }
  //   let t1 = this.getTimeAsNumberOfMinutes(this.candidateRegForm.value.preferedstartTime)
  //   let t2 = this.getTimeAsNumberOfMinutes(this.candidateRegForm.value.preferedEndTime)
  //   if (t1 >= t2) {
  //     this.candidateRegForm.controls['preferedEndTime'].setErrors({ timeError: true })
  //   }
  //   else {
  //     this.candidateRegForm.controls['preferedEndTime'].setErrors(null)
  //   }
  // }
  getCandidateDetails() {
    this.loader = true;

    if (this.id) {
      this.service.getCandidateDetail(this.id).subscribe((res: any) => {
        this.getStatesname(res.Country);

        var start = new Date(res.Dob);
        var x: Object = {
          date: {
            year: start.getFullYear(),
            month: start.getMonth() + 1,
            day: start.getDate(),
            formatted: start.toUTCString()
          }
        };
        this.candidateRegForm.patchValue(res);
        if (res.PreferedTime != null && res.PreferedTime != "") {
          this.checkboxEnabled = true
          this.candidateRegForm.patchValue({
            prepClass: true,
            PreferedTime: res.PreferedTime,
          })
        }
        this.candidateRegForm.patchValue({
          Dob: x,
          ProfilePic: res.Picture
        })
        this.url = res.Picture
        this.loader = false;
      },
        (error) => {
          this.loader = false;
        })
    }
    else {
      this.id = "";
    }
  }


  chckbox() {
    var el = document.getElementById('checkTick') as HTMLInputElement
    if (el.checked) {
      console.log('checked')
      this.checkboxEnabled = true
      this.candidateRegForm.controls['PreferedTime'].setValidators(Validators.required)
      this.candidateRegForm.controls['PreferedTime'].updateValueAndValidity();
    } else {
      this.checkboxEnabled = false
      this.candidateRegForm.controls['PreferedTime'].clearValidators()
      this.candidateRegForm.controls['PreferedTime'].updateValueAndValidity();
    }
  }

  dashboard() {
    this._router.navigateByUrl("advance/advance-dashboard");
  }
  getCourseOfStudy() {
    this.service.getCourseOfStudyApi().subscribe((res: any) => {
      if (res) {
        this.classList = res
      }
    })
  }
}
