import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { validateConfig } from "@angular/router/src/config";
import { IMyDpOptions, IMyDefaultMonth } from "mydatepicker";
import { AlertService } from "src/app/_service/alert.service";
import { HomeServiceService } from "src/app/_service/home-service.service";
declare var $: any;
declare var jic: any;

@Component({
  selector: "app-help-registration",
  templateUrl: "./help-registration.component.html",
  styleUrls: ["./help-registration.component.css"],
})
export class HelpRegistrationComponent implements OnInit {
  // @ViewChild(FormGroupDirective) formhelp: FormGroupDirective;
  countryList: any = [];
  statesList: any =[];
  isSubmitted: boolean;
  uploadStudentImage: any;
  tempImg: string | ArrayBuffer;
  images: any=[];
  tempnomImg: string | ArrayBuffer;
  uploadnomImage: any;
  uploadImage: any;
  videoName: any;
  filenames: any =[];
  stateId: any;
  public LastDate: Date = new Date();
  public defaultMonth: IMyDefaultMonth = {
    defMonth: `0${this.LastDate.getMonth()}/${this.LastDate.getFullYear() - 15}`
  };
  public Date: Date = new Date();
  currentYear: any = this.Date.getUTCFullYear();
  currentDate: any = this.Date.getUTCDate();
  currentMonth: any = this.Date.getUTCMonth() + 1;
  public myDatePickerOptions: IMyDpOptions = {
    inline: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    disableSince: {
      year: this.currentYear,
      month: this.currentMonth,
      day: this.currentDate - 1},
  
    dateFormat: 'dd-mmm-yyyy',
  };
  loader: boolean;
  constructor(
    private fb: FormBuilder, 
    private ref: ChangeDetectorRef,
    private service: HomeServiceService, 
    private route : ActivatedRoute,
    private alert: AlertService,
    private router: Router,
    private el: ElementRef,
    ) {}
  helpRegForm: FormGroup;
  reader = new FileReader();
  fileList: FileList;
  type : string;
registrartionTypeList:any=[
  {name:"self",value:"Are you applying for self"},
  {name:"behalf",value:"Or on behalf of someone"}
]
materialList:any=[
  {name:"Text Books",value:"Text Books"},
  {name:"Exercise Books",value:"Exercise Books"},
  {name:"Uniform",value:"Uniform"}
];
reasonList:any=[{name:"schoolfees",value:"School Fees"},{name:"afterschoolless",value:"After School Lesson"},{name:"material",value:"Material"}]
  ngOnInit() {
    this.route.queryParams
    .filter(params => params.order)
    .subscribe(params => { 
       this.type = params.type; 
    });
    this.helpRegForm = this.fb.group({
      RegistrationType :["",Validators.required],
      BenificiaryProfile:['',[Validators.required]],
      Id: [""],
      NominatorFullName: ["", Validators.required],
      NominatorPhone: ["", Validators.required],
      NominatorEmail: ["", [Validators.required, Validators.email]],
      NominatorOccupation: ["", Validators.required],
      NominatorAddress:["",Validators.required],
      BenficiaryFirstName: ["", Validators.required],
      BenificiaryLastName: ["", Validators.required],
      BenificiaryMiddleName: ["", Validators.required],
      BenificaryGender: ["", Validators.required],
      BenficiaryDob: ["", Validators.required],
      BenficiarySchool: ["", Validators.required],
      BenficiaryEmail:["",[Validators.required, Validators.email]],
      BenficiaryClass: ["", Validators.required],
      BenficiaryPhone:["",Validators.required],
      BenficiaryAddress: ["", Validators.required],
      BenficiaryStateOfOrigin: ["", Validators.required],
      BenificiaryNationality: ["", Validators.required],
      PrincipalName: ["", Validators.required],
      PrincipalPhone: ["", Validators.required],
      PrincipalEmail: ["", [Validators.required, Validators.email]],
      SchoolLocation: ["", Validators.required],
      GuardianName: ["", Validators.required],
      ParentPhone: ["", Validators.required],
      ParentEmail: ["", [Validators.required, Validators.email]],
      ParentOccupation: ["", Validators.required],
      ParentAddress: ["", Validators.required],
      yourDetails: ["", Validators.required],
      ReasonforHelp: ["", Validators.required],
      AmountNeeded: ["",  [Validators.required, Validators.min(1)]],
      videoFile: ["", [Validators.required]],
      ResultSheets: ["", Validators.required],
      // HelpDetail :[""],
      NominatorProfile:['',[Validators.required]],
      HelpDetail:new FormArray([], [Validators.required]),
      agreed:["",Validators.required],
      file1:[''],
      file2:['']
    });
    
    this.route.queryParams.subscribe((params) => {
      if (params["self"] === "true") {
        this.helpRegForm.controls["RegistrationType"].patchValue("self");
        this.helpRegForm.get('NominatorFullName').clearValidators()
        this.helpRegForm.get('NominatorFullName').updateValueAndValidity()
        this.helpRegForm.get('NominatorPhone').clearValidators()
        this.helpRegForm.get('NominatorPhone').updateValueAndValidity()
        this.helpRegForm.get('NominatorEmail').clearValidators()
        this.helpRegForm.get('NominatorEmail').updateValueAndValidity()
        this.helpRegForm.get('NominatorOccupation').clearValidators()
        this.helpRegForm.get('NominatorOccupation').updateValueAndValidity()
        this.helpRegForm.get('NominatorAddress').clearValidators()
        this.helpRegForm.get('NominatorAddress').updateValueAndValidity()
        this.helpRegForm.get('NominatorProfile').clearValidators()
        this.helpRegForm.get('NominatorProfile').updateValueAndValidity()

      } else if (params["self"] === "falses") {
        this.helpRegForm.controls["RegistrationType"].patchValue(
          "forSomeone"
        );
      }
    });
    this.getCountry()
  }
  get f(){
    return this.helpRegForm.controls;
  }
  regChange(value){
    if(value == 'self'){
      // location.reload()
      this.helpRegForm.reset({
        RegistrationType:'self',
        Id:""
      }); 
        this.helpRegForm.get('NominatorFullName').clearValidators()
        this.helpRegForm.get('NominatorFullName').updateValueAndValidity()
        this.helpRegForm.get('NominatorPhone').clearValidators()
        this.helpRegForm.get('NominatorPhone').updateValueAndValidity()
        this.helpRegForm.get('NominatorEmail').clearValidators()
        this.helpRegForm.get('NominatorEmail').updateValueAndValidity()
        this.helpRegForm.get('NominatorOccupation').clearValidators()
        this.helpRegForm.get('NominatorOccupation').updateValueAndValidity()
        this.helpRegForm.get('NominatorAddress').clearValidators()
        this.helpRegForm.get('NominatorAddress').updateValueAndValidity()
        this.helpRegForm.get('NominatorProfile').clearValidators()
        this.helpRegForm.get('NominatorProfile').updateValueAndValidity()
    }
    else {
      // this.helpRegForm.clearValidators()
      // location.reload()
      this.helpRegForm.reset({
        RegistrationType:'forSomeone',
      });
    
        this.helpRegForm.get('NominatorFullName').setValidators([Validators.required])
        this.helpRegForm.get('NominatorFullName').updateValueAndValidity()
        this.helpRegForm.get('NominatorPhone').setValidators([Validators.required])
        this.helpRegForm.get('NominatorPhone').updateValueAndValidity()
        this.helpRegForm.get('NominatorEmail').setValidators([Validators.required])
        this.helpRegForm.get('NominatorEmail').updateValueAndValidity()
        this.helpRegForm.get('NominatorOccupation').setValidators([Validators.required])
        this.helpRegForm.get('NominatorOccupation').updateValueAndValidity()
        this.helpRegForm.get('NominatorAddress').setValidators([Validators.required])
        this.helpRegForm.get('NominatorAddress').updateValueAndValidity()
        this.helpRegForm.get('NominatorProfile').setValidators([Validators.required])
        this.helpRegForm.get('NominatorProfile').updateValueAndValidity()
    }

  }
  getStatesname(value) {
    if(value){
      for(let i=0;i<this.countryList.length;i++){
        if(value == this.countryList[i].CountryName){
          this.stateId = this.countryList[i].Id
        }
      }
    }
    this.service.getStatefromCountry(this.stateId).subscribe(
      (res: any) => {
        this.statesList = res;
      },
      (error) => {}
    );
  }
  onCheckboxChange(event) {
    const formArray: FormArray = this.helpRegForm.get(
      "HelpDetail"
    ) as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
    /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }
  nameValidations(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  numbersOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  registerHelp(form){
    this.isSubmitted = true
    // debugger
    if(this.tempnomImg){
      this.helpRegForm.value.NominatorProfile = this.tempnomImg
    }
    if(this.tempImg){
      this.helpRegForm.value.BenificiaryProfile = this.tempImg
    }
    if(this.helpRegForm.invalid){
      for (const key of Object.keys(this.helpRegForm.controls)) {
        if (this.helpRegForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
       }
      }
      return
    }
    if(this.helpRegForm.value.BenficiaryDob.date){
      let dateofbirth = this.helpRegForm.value.BenficiaryDob.date;
      this.helpRegForm.value.BenficiaryDob = new Date(dateofbirth.year, dateofbirth.month -1, dateofbirth.day).toISOString()
      }else{
        let dateofbirth = form.value.Dob
        this.helpRegForm.value.BenficiaryDob= new Date (dateofbirth).toISOString();
      }
    // this.helpRegForm.patchValue(form.value)
    // console.log(form.value)
    this.loader = true
    this.service.helpNeedDatta(this.helpRegForm.value).subscribe((res:any)=>{
      console.log(res)
      this.loader = false
      this.alert.success("Registered successfully")
      this.router.navigate(["/soliciting-help"])
    }, err =>{
      this.loader = false
      this.alert.error(err, true);
    })

  }
  onFileChange(event,type) {

    if(type== 'Nominator'){
      // this.helpRegForm.patchValue({
      //   NominatorProfile: null
      // });
      if (event.target.files && event.target.files.length) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.tempnomImg = (<FileReader>event.target).result;
          // this.helpRegForm.patchValue({
          //   NominatorProfile: event.target.result
          // });
          this.alert.success("uploaded successfully")
        };
        reader.readAsDataURL(event.target.files[0]);
        this.ref.markForCheck();
      }
      // this.fileList = event.target.files;
      // this.uploadnomImage = event.target.files[0];
      // if (this.fileList.length > 0) {
      //   let file: File = this.fileList[0];
      //   this.helpRegForm.get('NominatorProfile').patchValue(file)
      //   this.reader.readAsDataURL(this.uploadnomImage)
      // }
    }
   
    else if(type== 'Beneficiary'){
      // this.helpRegForm.patchValue({
      //   BenificiaryProfile: null
      // });
      if (event.target.files && event.target.files.length) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.tempImg = (<FileReader>event.target).result;
          // this.helpRegForm.patchValue({
          //   BenificiaryProfile: event.target.result
          // });
          this.alert.success("uploaded successfully")
        };
        reader.readAsDataURL(event.target.files[0]);
        this.ref.markForCheck();
      }
  
      // this.fileList = event.target.files;
      // this.uploadImage = event.target.files[0];
      // if (this.fileList.length > 0) {
      //   let file: File = this.fileList[0];
      //   this.helpRegForm.get('profileImg').patchValue(file)
      //   this.reader.readAsDataURL(this.uploadImage)
      // }
    }
    
  }
  // dataURLtoFile(dataurl, filename) {
  //   // tslint:disable-next-line:prefer-const
  //   let arr = dataurl.split(","),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     // tslint:disable-next-line:prefer-const
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // }
  // public resizeImage() {
  //   let imgFile;
  //   if (typeof this.uploadImage === "string") {
  //     imgFile = this.dataURLtoFile(this.uploadImage, "myfile.jpg");
  //   } else {
  //     imgFile = this.uploadImage;
  //   }
  //   if (imgFile && imgFile.size > 204800) {
  //     const src_img: any = document.getElementById("myprofileimg");
  //     const percentage = (200 * 100) / (imgFile.size / 1024);
  //     const targetImg = jic.compress(src_img, Math.floor(percentage), "jpg")
  //       .src;
  //     const file = this.dataURLtoFile(targetImg, imgFile.name);
  //     this.uploadStudentImage = file;
  //     // this.imageToVerify = file;
  //     src_img.src = targetImg;
  //     if (file && file.size > 204800) {
  //       this.resizeImage();
  //     } else {
  //       this.helpRegForm
  //         .get("NominatorProfile")
  //         .setValue(this.uploadImage);
  //       this.reader.readAsDataURL(this.uploadImage);
  //     }
  //   } 
  // }
  onSelectFile(event){
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (
        file.type == "video/mp4" ||
        file.type == ""
       ){
        var video = document.createElement("video");
        video.preload = "metadata";
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          var duration = video.duration;
          if (duration <= 60) {
            // this.uploadFiles(type, index)
            var reader = new FileReader();
            reader.onload = (event:any) => {
              // this.helpRegForm.patchValue({
              //   videoFile: event.target.result
              // });
              this.videoName = file.name
              // console.log(event.target.files[0])
            }
            reader.readAsDataURL(event.target.files[0]);
            this.alert.success("uploaded successfully",true)
          } else {
            this.alert.error(
              "Video duration should be less than 60 sec or 1 min",
              
            );
          }
       }
       }
        else {
          this.alert.error(
            "Upload Video file",
            
          );
        }
  }
  }
  multipleFileChange(event) {
    if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        var input = document.getElementById('sheetFile');
        var output = document.getElementById('sheetList');
        var list = "";
        for(let i = 0; i < filesAmount; i++){
          list += '<span>' +  event.target.files[i].name + ','+ '</span>';
        }
        output.innerHTML = '<span>'+list+'</span>';
        for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();

                reader.onload = (event:any) => {
                  console.log(event.target.result);
                   this.images.push(event.target.result); 
                   console.log(this.images)
                   if(this.images.length>= 1){
                    // this.helpRegForm.patchValue({
                    //   ResultSheets: this.images
                    //  });
                    this.alert.success("uploaded successfully")
                   }
                   else {
                    this.alert.error("uplaod atleast two files")
                  }      
                }
                reader.readAsDataURL(event.target.files[i]);
        }   
    }
  }
  materialChange(value){
    if(value == 'Material'){
      this.helpRegForm.get('HelpDetail').setValidators([Validators.required])
      this.helpRegForm.get('HelpDetail').updateValueAndValidity()
    }
    else {
      this.helpRegForm.get('HelpDetail').clearValidators()
      this.helpRegForm.get('HelpDetail').updateValueAndValidity()
    }
  }
  getCountry() {
    this.service.getCountry().subscribe(
      (res: any) => {
        this.countryList = res;
      },
      (error) => {}
    );
  }
}