import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  registration = new Subject<any>()

  userProfile = new Subject<any>();
 private messageSource = new BehaviorSubject(undefined);
  currentMessage = this.messageSource.asObservable();


  // myBool$: Observable <any>;
  // private boolSubject: Subject<any>;

  constructor() {
    // this.boolSubject = new Subject<any>();
    //     this.myBool$ = this.boolSubject.asObservable();
   }
 changeMessage(message: string) {
    this.messageSource.next(message);
  }

// addmessage(message :any)
// {
//   this.boolSubject.next(message);
// }

}


// 1) for configuring the message service just copy this file and put in provide in module.

// 2) for using it just import it to any component and use that in constructor

// 3)for sending message
//  this.data.changeMessage(res);

//  4) for reciving message
//   this.data.currentMessage.subscribe(message => this.message = message);
//     console.log("register component",this.message);