import { GridColumnType } from "src/app/_models/pagination-model";
import { PropertyMetaModel } from "src/app/_models/pagination-model";
import { Component, OnInit } from "@angular/core";
import { HomeServiceService } from "src/app/_service/home-service.service";
import { AlertService } from "src/app/_service/alert.service";
import { SchoolService } from "src/app/_service/school-service.service";
import { PaginationModel } from "src/app/_models/pagination-model";
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationEnd
} from "@angular/router";
import {
  SchoolDropdownModel,
  ExamCentreDropdownModel
} from "../../models/models";
declare var $: any;
@Component({
  selector: "app-university-view-candidates",
  templateUrl: "./university-view-candidates.component.html",
})
export class UniversutyViewCandidatesComponent implements OnInit {
  public displayColumns: PropertyMetaModel[];
  public viewCandidateDisplayColumns: PropertyMetaModel[];
  public candidatesDisplayColumns: PropertyMetaModel[];
  public deleteId: any;
  public schoolName: any;
  isRegistered:Boolean = false
  schoolDropDownList: SchoolDropdownModel[];
  examDropDownList: ExamCentreDropdownModel[];
  public examCentreName: any;
  urltoInclue: string;
  CountryId: any;
  dropdownExamCenterList: any;
  getExamCentre: any;
  statname: any;
  constructor(
    private service: HomeServiceService,
    private alert: AlertService,
    private homeService: SchoolService,
    private route: Router,
    private router: ActivatedRoute,
    private schoolService: SchoolService
  ) {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes("/admin")) {
          this.urltoInclue = "admin";
        } else if (event.url.includes("/examiner")) {
          this.urltoInclue = "examiner";
        } else if (event.url.includes("/exam-authorizer")) {
          this.urltoInclue = "exam-authorizer";
        }
        else if (event.url.includes("/super-admin")) {
          this.urltoInclue = "super-admin";
        }
      }
    });

    this.viewCandidateDisplayColumns = [
      {
        PropertyName: "CandidateRegistrationNo",
        ColumnName: "Examination No.",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "link",
        ColumnName: "Name",
        PropertyType: GridColumnType.DisplayLink,
        DisplayText: "Name",
        IsSortable: true
      },
      {
        PropertyName: "Email",
        ColumnName: "Email",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "SchoolName",
        ColumnName: "School",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "Category",
        ColumnName: "Category",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "Gender",
        ColumnName: "Gender",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "AddCaptureDevice",
        ColumnName: "Candidate Added Device",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "RegistrationCaptureDevice",
        ColumnName: "Registration Capture Device",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "PdfLink",
        ColumnName: "Slip",
        PropertyType: GridColumnType.Icon,
        DisplayText: "Download"
      },
      {
        PropertyName: "IsSubmit",
        ColumnName: "Registered",
        PropertyType: GridColumnType.Boolean
      },
      {
        PropertyName: "FlagDeleted",
        ColumnName: "Delete",
        PropertyType: GridColumnType.Icon,
        DisplayText: "Delete"
      }
    ];
    this.candidatesDisplayColumns = [
      {
        PropertyName: "CandidateRegistrationNo",
        ColumnName: "Examination No.",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "link",
        ColumnName: "Name",
        PropertyType: GridColumnType.DisplayLink,
        DisplayText: "Name",
        IsSortable: true
      },
      {
        PropertyName: "Email",
        ColumnName: "Email",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "SchoolName",
        ColumnName: "School",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "Category",
        ColumnName: "Category",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "Gender",
        ColumnName: "Gender",
        PropertyType: GridColumnType.Text,
        IsSortable: true
      },
      {
        PropertyName: "PdfLink",
        ColumnName: "Slip",
        PropertyType: GridColumnType.Link,
        DisplayText: "Download"
      },
      {
        PropertyName: "IsSubmit",
        ColumnName: "Registered",
        PropertyType: GridColumnType.Boolean
      }
    ];
  }

  keyword: any = "";
  season: any = "";
  state: any = "";
  examCentre: any = "";
  school: any = "";
  category: any = "Junior";
  class: any = "";
  loader: boolean;
  Country:any ="";

  seasonList: any;
  stateList: any;
  examCenterList: any;
  schoolList: any;
  categoryList: any;
  classList: any;
  candidateList: any;
  countryList:any = [];
  statesList:any = [];

  public pageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 1,
    TotalRecord: 0
  };

  public centreId: any = "";
  ngOnInit() {
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.centreId = params["id"];
        this.examCentre = params["id"];
      }
    });
    if (this.centreId) this.displayColumns = this.candidatesDisplayColumns;
    else this.displayColumns = this.viewCandidateDisplayColumns;
    this.getSeason();
    this.getSchoolByState();
    this.getClass();
    this.getCandidateList();
    this.getCountry();
  }
  getSeason() {
    this.loader = true;
    this.homeService.getSeason('University').subscribe(
      res => {
        var resp = JSON.parse(JSON.stringify(res));
        this.seasonList = resp;
        this.loader = false;
      },
      err => {
        this.loader = false;
        console.log("get season negative", err);
      }
    );
  }
  filter(){
    this.pageInfo.CurrentPage = 1
    this.getCandidateList()
  }
  // examFilterFunction(event) {
  //   if (event.target.value == "") {
  //     this.examCentre = "";
  //     $("#examDropDown").removeClass("show");
  //   } else {
  //     var examCentreName = event.target.value;
  //     if (examCentreName) {
  //       var list = Object.assign([], this.examCenterList);

  //       examCentreName.split(" ").forEach(word => {
  //         var item = word.toLowerCase();
  //         list = list.filter(exm =>
  //           exm.Name.split(" ").some(x => x.toLowerCase().startsWith(item))
  //         );
  //       });
  //     }

  //     this.examDropDownList = list.splice(0, 10);
  //     if (this.examDropDownList.length == 0)
  //       $("#examDropDown").removeClass("show");
  //     else $("#examDropDown").addClass("show");
  //   }
  // }

  getSchoolByState() {
    this.loader = true;
    this.homeService.getSchoolByState(this.state).subscribe(
      res => {
        var resp = JSON.parse(JSON.stringify(res));
        this.schoolList = resp;
        this.loader = false;
      },
      err => {
        this.loader = false;
        console.log("get getSchoolByState positive", err);
      }
    );
  }

  schoolFilterFunction(event) {
    if (event.target.value == "") {
      this.school = "";
      $("#schoolDropDown").removeClass("show");
    } else {
      var schoolName = event.target.value;
      if (schoolName) {
        var list = Object.assign([], this.schoolList);

        schoolName.split(" ").forEach(word => {
          var item = word.toLowerCase();
          list = list.filter(sch =>
            sch.SchoolName.split(" ").some(x =>
              x.toLowerCase().startsWith(item)
            )
          );
        });
      }

      this.schoolDropDownList = list.splice(0, 10);
      if (this.schoolDropDownList.length == 0)
        $("#schoolDropDown").removeClass("show");
      else $("#schoolDropDown").addClass("show");
    }
  }

  selectCategory() {
    this.getClass();
  }

  getClass() {
    this.loader = true;
    this.homeService.getClass(this.category).subscribe(
      res => {
        var resp = JSON.parse(JSON.stringify(res));
        this.classList = resp;
        this.loader = false;
      },
      err => {
        this.loader = false;
        console.log("get class positive", err);
      }
    );
  }

  onStateSelectedEvent() {
    this.getSchoolByState();
    this.getExamCentreByState();
  }

  onStateExamCentreEvent(event) {
    this.examCentre = event.target.value;
  }

  getCandidateList() {
    let seasonName = this.seasonList
      ? this.seasonList.find(x => x.Id == this.season)
      : null;
    this.candidateList = [];
    var data = {
      Category: this.category,
      Class: this.class,
      FlagGradeApproved: 2,
      IsPublished: "",
      School: this.school,
      State: this.examCentre ? null : this.state,
      ExamCentre: this.examCentre,
      isactive: "",
      keyword: this.keyword,
      pageno: this.pageInfo.CurrentPage,
      pagesize: this.pageInfo.PageSize,
      sortby: this.pageInfo.SortBy,
      sortorder: this.pageInfo.SortOrder,
      Season: seasonName ? seasonName.Season : null,
      Country:this.Country,
      isRegistered: this.isRegistered
    };
    this.loader = true;
    this.service.getUniversityCandidateList(data).subscribe(
      (res: any) => {
        if (res && res.List) {
          var resp = JSON.parse(JSON.stringify(res));
          resp.List.forEach(x => {
            x["link"] = `${this.urltoInclue}/candidate/` + x.Id;
            if (x.Gender == "f") x.Gender = "female";
            else if (x.Gender == "m") x.Gender = "male";
          });
          this.candidateList = resp;
          this.pageInfo.TotalRecord = resp.TotalRecords;
          this.loader = false;
        } else {
          this.pageInfo.TotalRecord = 0;
          this.candidateList.List = [];
          this.loader = false;
        }
      },
      err => {
        this.loader = false;
        console.log("get all candidate positive", err);
      }
    );
  }
  public PageChange($event) {
    this.pageInfo = $event;
    this.getCandidateList();
  }
  clear() {
    this.keyword = "";
    this.season = "";
    this.state = "";
    this.examCentre = "";
    this.school = "";
    this.category = "Junior";
    this.class = "";
    this.examCentreName = "";
    this.Country="";
    this.isRegistered = true
    $("#examDropDown").removeClass("show");
    this.school = "";
    this.schoolName = "";
    $("#schoolDropDown").removeClass("show");
    this.getCandidateList();
  }
  addNewCandidate() {
    this.route.navigate([`${this.urltoInclue}/candidate/0`]);
  }
  action(event) {
    if (event.action == "PdfLink") {
      window.open(event.value.PdfLink, "blank");
    } else if (event.action == "FlagDeleted") {
      this.deleteId = event.value.Id;
      $("#candidateModel").modal("show");
    }
  }

  deleteRecord() {
    $("#candidateModel").modal("hide");
    this.loader = true;
    this.service.deleteCandidate(this.deleteId).subscribe(
      res => {
        this.loader = false;
        this.getCandidateList();
      },
      error => {
        this.loader = false;
      }
    );
  }

  viewDetail() {
    $("#candidateModel").modal("hide");
    // this.route.navigateByUrl("school/candidate/" + this.deleteId);
  }
  getCountry(){
    this.service.getCountry().subscribe((res:any)=>{
      this.countryList = res
    console.log(this.countryList)
    },error=>{})
  }
  getStatesname(id){
    this.CountryId = id
    this.service.getStatefromCountry(id).subscribe(
      (res:any) => {
        this.statesList = res
      },
      error => {}
    );
  }
  getExamCentreByState() {
    this.schoolService.getExamCentreByState(this.state,this.Country,true).subscribe(
      res => {
        this.getExamCentre = res;
        this.dropdownExamCenterList = res;
      },
      error => {}
    );
  }
}
