import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class ManagerAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (this.isUserValid()) {
      return true;
    } else {
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

  isUserValid() {
        return localStorage.getItem('Role') == 'VerificationManager';
  }
}
