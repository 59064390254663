import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class HomePageModelModule { 

}
export class CompendiumModel
{
  constructor()
  {
    this.Category="";
    this.PageNo=1;
    this.PageSize=10;
    this.SortBy="";
    this.SortOrder="";
    this.Volume="";
    this.Year="";
  }
Category:any;
PageNo:any;
PageSize:any;
SortBy:any;
SortOrder:any;
Volume:any;
Year:any;
}


export class SyllabusModel
{
  constructor()
  {
    this.SeasonType="";
    this.ClassName="";
    this.Category="";
    this.PageNo=1;
    this.PageSize=10;
    this.SortBy="";
    this.SortOrder="";
    this.Year="";
  }
  SeasonType:any;
  ClassName:any;
Category:any;
PageNo:any;
PageSize:any;
SortBy:any;
SortOrder:any;
Year:any;
}

export class HallOfFameFilter{
  constructor(){
    this.Year =  null
    this.PageNo = 1
    this.PageSize = 3
  }
  Year:any
  PageNo:any;
PageSize:any;
totalPage:any
}
export class HallOfFame{
  constructor(){
    this.List = new Array<HallOfFameData>();
  }
  List: HallOfFameData[];
}

export class HallOfFameData{
  Age: any
  CompetitionType: any
  Id: any
  Name: any
  Picture: any
  Year:any
}
export class honoursRoll{
  constructor()
  {
    this.PageNo=1;
    this.PageSize=10;
    this.Year = "2021";
  }
PageNo:any;
PageSize:any;
Year:any;
currentPage:any
totalPage:any

}
export class candidateDetails{
  constructor(){
    this.List = new Array<candidateDetailsData>();

  }
  List : candidateDetailsData[];
  TotalRecords:any
}
export class candidateDetailsData{
  Year:any
  Percent:any
  Name:any
  Image:any
  Age:any
  Type:any
}

