import { Component, OnInit, OnDestroy } from "@angular/core";
import { AlertService } from "src/app/_service/alert.service";
import { HomeServiceService } from "src/app/_service/home-service.service";
import * as url from "src/app/model/config";
import { HomeSecondService } from "../_service/home-second.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject, timer, interval, Subscription } from "rxjs";
import { SchoolService } from "../_service/school-service.service";
declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit, OnDestroy {
  [x: string]: any;
  isPublished: any;
  categoryType: any;
  Category = [
    { Id: "Junior", CategoryName: "Junior" },
    { Id: "Senior", CategoryName: "Senior" }
  ];
  showRegisterSeniorButton: any;
  showRegisterJuniorButton: any;
  Filters = {
    pageno: 1,
    pagesize: "5",
    isactive: "",
    sortorder: "",
    sortby: "",
    Season: "",
    Category: ""
  };
  seasonList = [];
  SelectedItem = [];
  SelectedItemArray = [];
  List = { TotalRecords: 0, List: [] };
  hideLabelText = null;
  year: any;
  BaseAddress: any;
  noRecordFoundText: any;
  States = [];
  regClosed: boolean = false;
  userProfile: any;
  countDown: Subscription;
  subt: Subscription;
  count;
  registartionStartDate;
  registartionEndDate;
  homeText = "";
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  counter: any;
  currentDate = new Date()
  examinationDate;
  countryType: any;
  regestrationDetails:any;
  constructor(
    public alert: AlertService,
    public service: HomeServiceService,
    public serviceSecond: HomeSecondService,
    private activatedRoute: ActivatedRoute,
    private schoolService: SchoolService,
    private router: Router
  ) { }

  ngOnInit() {
    this.countryType = localStorage.getItem('type')
    if(this.countryType){
      this.countryType = localStorage.getItem('type')
    }
    else {
      this.countryType = 'Nigeria'
    }
    if (this.countryType == 'Nigeria') {
      this.scrollTo()
    }
    this.service.getHomeSeasonDetail().subscribe((res: any) => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          if (res[i].SeasonType == 'Nigeria') {
            this.regestrationDetails = res[i]
            this.registartionStartDate = new Date(this.regestrationDetails.RegistrationStartDate)
            this.registartionEndDate = new Date(this.regestrationDetails.RegistrationEndDate)
            // this.registartionEndDate = new Date (this.registartionEndDate.setHours(this.registartionEndDate.getHours() + 24));
            this.regClosed = false;
            this.regestrationDetails = res[i]
            this.categoryType = res[i].Category;
            this.checkCartegory(this.categoryType)
            if (this.registartionStartDate > this.currentDate) {
              this.homeText = "Registartion Opens from"
              this.examinationDate = new Date(this.regestrationDetails.RegistrationStartDate).getTime()
              // this.registartionStartDate = new Date(this.regestrationDetails.RegistrationStartDate)
              // this.registartionEndDate = new Date(this.regestrationDetails.RegistrationEndDate)
              this.regDay = this.registartionStartDate.getDay()
            }
            else if (this.registartionStartDate < this.currentDate && this.registartionEndDate > this.currentDate) {
              this.homeText = "Registration Closes by"
              // this.registartionEndDate = new Date (this.registartionEndDate.setHours(this.registartionEndDate.getHours() + 24));
              this.examinationDate = new Date(this.registartionEndDate).getTime()
              // this.registartionStartDate = new Date(this.regestrationDetails.RegistrationStartDate)
              // this.registartionEndDate = new Date(this.regestrationDetails.RegistrationEndDate)
              this.regDay = this.registartionStartDate.getDay()
            }
            else {
              this.regClosed = true;
              this.homeText = "Registration Closed for the Current Season"
            }

          }
          else if(this.regestrationDetails == undefined){
            this.regClosed = true ;
          this.homeText = "Registration Closed for the Current Season"
        }
        }
        if(res.length == 0){
          this.regClosed = true ;
        this.homeText = "Registration Closed for the Current Season"
        }
      }
      else {
        this.regClosed = true ;
        this.homeText = "Registration Closed for the Current Season"
      }
      this.service.regClosedBySeason.next(this.regClosed)
    })

    this.counter = setInterval(() => {
      this.getExaminationCounter();
    }, 1000);
    this.loadScript('assets/js/own.js');
    this.service.getIsResultPublishedURI().subscribe(
      (res: any) => {
        if (res) {
          if (res && res.List && res.List[0]) {
            this.isPublished = res.List[0].IsPublished;
          }
          localStorage.setItem("isResultPublished", this.isPublished);
        }
      },
      err => { }
    );
    this.getYearsList();
    this.userProfile = JSON.parse(localStorage.getItem("ls.userProfile"));

    if (this.countryType == 'Nigeria') {
      this.sectionScroll()
    }
  }

  ngOnDestroy() {
    clearInterval(this.counter);
  }

  getExaminationCounter() {
    var now = new Date().getTime();
    //console.log(now)

    // Find the distance between now and the count down date
    
    //this.examinationDate =this.examinationDate.setHours(this.examinationDate.getHours() + 24);
    var distance = this.examinationDate - now;

    // Time calculations for days, hours, minutes and seconds
    this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(this.counter);
    }
  }

  loadScript(src) {
    // var script = document.createElement("script");
    // script.type = "text/javascript";
    // document.getElementsByTagName("head")[0].appendChild(script);
    // script.src = src;
  }

  categoryChange = function (CategoryID) {
    this.Filters.Category = CategoryID;
  };

  setNoRecordMessage = function () {
    if (this.List.TotalRecords == 0 || this.List.TotalRecords == undefined)
      this.noRecordFoundText = "No Question document found.";
    else this.noRecordFoundText = "";
  };

  clearAll = function () {
    this.List.TotalRecords = 0;
    this.List.List = [];
    this.hideLabelText = null;
    this.Filters.Category = "";
    this.year = "";
  };

  loadList = function () {
    this.serviceSecond.LoadQuestions(this.Filters).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.List = res;
          this.BaseAddress = url.portalDocumentLocation;
          this.hideLabelText = res.List.length;
          this.setNoRecordMessage();
        } else {
          this.List.TotalRecords = 0;
          this.List.List = [];
          this.hideLabelText = null;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.setNoRecordMessage();
        this.hideLabelText = null;
        this.List = { TotalRecords: 0, List: [] };
      }
    );
  };

  getYearsList = function () {
    this.serviceSecond.getYears(this.Filters).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.year = res.List;
        } else {
          this.List.TotalRecords = 0;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.List = { TotalRecords: 0, List: [] };
        this.setNoRecordMessage();
      }
    );
  };

  yearChange = function (year) {
    this.Filters.Season = year;
  };

  scrollTo() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['type'] == 'objective') {
        $("html, body").animate(
          {
            scrollTop: $("#objective-wrapper").position().top - 90,
            smoothBehavior: 'smooth'
          },
          0
        );
      }
      if (params['type'] == 'target') {
        $("html, body").animate(
          {
            scrollTop: $("target-wrapper").position().top - 110,
            smoothBehavior: 'smooth'
          },
          0
        );
      }
      if (params['type'] == 'Registration') {
        $("html, body").animate(
          {
            scrollTop: $("#register-wrapper").position().top + 80,
            smoothBehavior: 'smooth'
          },
          0
        );
      }
      if (params['type'] == 'stages') {
        $("html, body").animate(
          {
            scrollTop: $("#stage-wrapper").position().top - 110,
            smoothBehavior: 'smooth'
          },
          0
        );
      }
      if (params['type'] == 'prizes') {
        $("html, body").animate(
          {
            scrollTop: $("#prize-wrapper").position().top - 110,
            smoothBehavior: 'smooth'
          },
          0
        );
      }
      if (params['type'] == 'about-cruxlearning') {
        $("html, body").animate(
          {
            scrollTop: $("#aboutcruxlearning-wrapper").position().top - 80,
            smoothBehavior: 'smooth'
          },
          0
        );
      }
    })
  }

  setTimer() {
    let currentDate = new Date();
    let futureDate = new Date("june 1, 2021 10:00:00");
    this.day = Math.floor(
      futureDate.getTime() / (1000 * 60 * 60 * 24) -
      currentDate.getTime() / (1000 * 60 * 60 * 24) -
      1
    );
    this.day = this.day <= 0 ? 0 : this.day
    this.hour = currentDate.getHours() - futureDate.getHours() - 1;
    this.hour = this.hour <= 0 ? 0 : this.hour
    this.minutes = 60 - (currentDate.getMinutes() - futureDate.getMinutes());
    this.seconds = 60 - (currentDate.getSeconds() - futureDate.getSeconds());
    this.myTimer();
  }

  myTimer() {
    //
    //this.count = 100;
    this.countDown = timer(0, 1000).subscribe((x) => {
      --this.count;
      if (this.hour == 0) {
        this.hour = 24;
        if (!(this.day <= 0)) {
          this.day--;
        }
        // if(this.day >= 0){
        //   this.day = 0
        // }
      }
      if (this.minutes == 0 && this.hour > 0) {
        this.minutes = 59;
        this.seconds = 60;
        this.hour = this.hour - 1;
        // this.hour = (this.hour - 1) >= 0 ? 0 : this.hour - 1;
      }
      if (this.seconds == 0) {
        this.seconds = 60;
        this.minutes = this.minutes - 1;
      }

      this.seconds = this.seconds - 1;
      // this.count = this.count - 1;
    });

    this.subt = interval(500).subscribe((x) => {
      if (this.count === 0) {
        this.subt.unsubscribe();
        this.countDown.unsubscribe();

        // this.submitTest();
      }
    });
  }
  getHomeSeasonDetail() {
    this.service.getHomeSeasonDetail().subscribe((res: any) => {
      this.regestrationDetails = res[0]
    })
  }
  checkCartegory(categoryType) {
    if (categoryType == "Both") {
      this.showRegisterJuniorButton = true;
      this.showRegisterSeniorButton = true;
    }
    if (categoryType == "Junior") {
      this.showRegisterJuniorButton = true;
      this.showRegisterSeniorButton = false;
    }
    if (categoryType == "Senior") {
      this.showRegisterSeniorButton = true;
      this.showRegisterJuniorButton = false;
    }

  }

  slides = [
    { img: "./../assets/img/Unicef-logo.png" },
    { img: "./../assets/img/The-washingtonLogo.png" },
    { img: "./../assets/img/CNN-logo.png" },
    { img: "./../assets/img/Federal-education.png" },
    { img: "./../assets/img/Reuters-logo.png" }
  ];
  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "enabled": true,
    "autoplay": true,
    "autoplaySpeed": 2000,
    "arrows": true,
  };


  afterChange(e) {
    console.log('afterChange');
  }

  countryRedirect(value) {
    this.countryType = value
    localStorage.setItem('type', this.countryType)
    this.service.countryType.next(value)
  }
  sectionScroll() {
    $("#obejctive-section").click((e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#objective-wrapper").position().top - 90,
          smoothBehavior: 'smooth'
        },
        500
      );
    });
    $("#targetSection").click((e) => {
      e.preventDefault();

      $("html, body").animate(
        {
          scrollTop: $("#target-wrapper").position().top - 110,
          smoothBehavior: 'smooth'
        },
        500
      );

    });
    $("#stageSection").click((e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#stage-wrapper").position().top - 110,
          smoothBehavior: 'smooth'
        },
        500
      );

    });
    $("#regSectionWrapper").click((e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#register-wrapper").position().top + 80,
          smoothBehavior: 'smooth'
        },
        500
      );

    });
    $("#prizesSection").click((e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#prize-wrapper").position().top - 10,
          smoothBehavior: 'smooth'
        },
        500
      );

    });
    $("#aboutSection").click((e) => {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#aboutcruxlearning-wrapper").position().top,
          smoothBehavior: 'smooth'
        },
        500
      );

    });
  }
}
