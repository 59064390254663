import { Component, OnInit } from "@angular/core";
import { HomeSecondService } from "../_service/home-second.service";
import { reduce } from "rxjs/operators";

@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.css"]
})
export class FaqsComponent implements OnInit {
  public AllFaq: any;
  clickClass = {};
  testPage: number = 1;
  schPage: number = 1;
  cruxPage: any = 1;
  stgPage: number = 1; 
  resultPage: number = 1;
  prizePage: number = 1;
  mathPage: number = 1;
  countryType:string;
  constructor(private service: HomeSecondService) {}
  faqSponserPAge = 1;
  faqregPage = 1
  ngOnInit() {
    // this.service.getallFaqs().subscribe(
    //   res => {
    //     this.AllFaq = res;
    //   },
    //   err => {}
    // );
    this.countryType = localStorage.getItem('type')
    if (this.countryType) {
      this.countryType = localStorage.getItem('type')
    }
    else {
      this.countryType = 'Nigeria'
    
  }
}
  prevPage(){

    if(this.faqSponserPAge >1){
      this.faqSponserPAge --;
    }
  }
  nextPage(){
    if(this.faqSponserPAge < 9){
      this.faqSponserPAge ++;
    }
  }
  
}
  // toggle(type){
  //   if(type === 'first'){
  //     let id_one = document.getElementById('id_one');
  //     let collapseOne = document.getElementById('collapseOne')
  //     if(collapseOne && collapseOne.classList.contains('in')){
  //       id_one.innerHTML = '<i class="fa fa-long-arrow-up mine-arrow" aria-hidden="true"></i>'
  //       id_one.click()
  //     }else{
  //       id_one.innerHTML = '<i class="fa fa-long-arrow-down mine-arrow" aria-hidden="true"></i>'
  //       id_one.click()
  //     }
  //   }else if(type === 'two'){
  //     let id_two = document.getElementById('id_two');
  //     let collapseTwo = document.getElementById('collapseTwo')
  //     if(collapseTwo && collapseTwo.classList.contains('in')){
  //       id_two.innerHTML = '<i class="fa fa-long-arrow-down mine-arrow" aria-hidden="true"></i>'
  //       id_two.click()
  //     }else{
  //       id_two.innerHTML = '<i class="fa fa-long-arrow-up mine-arrow" aria-hidden="true"></i>'
  //       id_two.click()
  //     }
  //   }else if(type === 'three'){
  //     let id_three = document.getElementById('id_three');
  //     let collapseThree = document.getElementById('collapseThree')
  //     if(collapseThree && collapseThree.classList.contains('in')){
  //       id_three.innerHTML = '<i class="fa fa-long-arrow-down mine-arrow" aria-hidden="true"></i>'
  //       id_three.click()
  //     }else{
  //       id_three.innerHTML = '<i class="fa fa-long-arrow-up mine-arrow" aria-hidden="true"></i>'
  //       id_three.click()
  //     }
  //   }
  // }

