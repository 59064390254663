import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams
} from "@angular/common/http";
import * as url from "src/app/model/config";
import "rxjs/add/operator/map";
import { Observable, Observer } from "rxjs/Rx";
@Injectable({
  providedIn: "root"
})
export class SchoolService {
  constructor(private httpClient: HttpClient) {}

  getSchoolDetails() {
    const Url =
      url.getSchoolDetails + "?id=" + localStorage.getItem("SchoolId");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getClass(category) {
    const Url = url.getClass + "?category=" + category;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  advanceGetClass(){
    const Url = url.getClass;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getIsResultPublished() {
    const Url = url.getIsResultPublished;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getSeason(data) {
    const Url = url.getSeason+ "?SeasonType=" + data;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getState(flag) {
    const Url = url.getState + "?flag=" + flag;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getExamCentreByStateO(state, FlagFilter) {
    const Url =
      url.getExamCentreByState +
      "?state=" +
      state +
      "&FlagFilter=" +
      FlagFilter;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  // api/portal/secure/candidate/getexamcentrebystate?flagFilter=""&country=""&state=
  
  getExamCentreByState(state,country ,FlagFilter) {
    const Url =
      url.getExamCentreByState +
      "?flagFilter=" +FlagFilter+
      "&country="+country+
      "&state=" + state
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getexamcentreWithstate(FlagFilter) {
    const Url = url.getexamcentreWithstate + "?FlagFilter=" + FlagFilter;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getSchoolByState(state) {
    const Url = url.getSchoolByState + "?state=" + state;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getallSchoolWithState() {
    const Url = url.getallSchoolWithState;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  saveCandidateData(data, captureDevice) {
    const Url = url.saveCandidateData + captureDevice;
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (key == "Picture") {
        var x = data[key];
        formData.append("file", x);
      } else formData.append(key, data[key]);
    });

    return this.httpClient
      .post(Url, formData)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getCandidateDetail(Id) {
    const Url = url.getCandidateData + "?Id=" + Id; ;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getAfricaCandidateDetail(Id) {
    const Url = url.getAfricaCandidateData + "?Id=" + Id; ;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getUniversityCandidateDetail(Id) {
    const Url = url.getUniversityCandidateData + "?Id=" + Id; ;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getExcellenceCertificate(body){
    const Url = url.getExcellencePdf+"?schoolId="+body.schoolId+"&candidateId="+body.candidateId;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/pdf",
        "Content-Type": "application/json"
      })
    };
    
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  GeneratePDFCertificateOfExcellenceAfricaApi(candidateId){
    const Url = url.GeneratePDFCertificateOfExcellenceAfrica + candidateId;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/pdf",
        "Content-Type": "application/json"
      })
    };
    
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  GeneratePDFCertificateOfAchievementAfricaApi(candidateId){
    const Url = url.GeneratePDFCertificateOfAchievementAfrica + candidateId;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/pdf",
        "Content-Type": "application/json"
      })
    };
    
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  GeneratePDFCertificateOfClassOfYearApi(){
    const Url = url.GeneratePDFCertificateOfClassOfYear;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/pdf",
        "Content-Type": "application/json"
      })
    };
    
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  GeneratePDFCertificateOfAchievementUniversityApi(candidateId){
    const Url = url.GeneratePDFCertificateOfAchievementUniversity+ '?candidateId=' + candidateId;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/pdf",
        "Content-Type": "application/json"
      })
    };
    
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  GeneratePDFCertificateOfExcellenceUniversityApi(candidateId){
    const Url = url.GeneratePDFCertificateOfExcellenceUniversity + '?candidateId='+ candidateId;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/pdf",
        "Content-Type": "application/json"
      })
    };
    
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  
  getBatchCertificate(body){
    // debugger
    const Url = url.getPdfURI;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/pdf",
        "Content-Type": "application/json"
      })
    };
    
    return this.httpClient
      .post(Url,body, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  getAcievmentCertificate(body){
    const Url = url.getAchievmentPdf+"?schoolId="+body.schoolId+"&candidateId="+body.candidateId;
    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/pdf",
        "Content-Type": "application/json"
      })
    };
    
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }



  getCertificateDetails(Id) {

    const Url = url.getCertificateDetails + Id; ;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getCandidateData(candidateToken) {
    const Url = url.getAddCandidateData + "?id=" + candidateToken;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .get(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getAllCandidates(filter) {
    const Url = url.getAllCandidate;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, filter, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  approveScore(body) {
    const Url = url.approveScore;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, body, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  rejectScore(body) {
    const Url = url.rejectScore;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, body, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  getRecipientType(body) {
    const Url = url.getRecipientType;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, body, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }

  sendMessageInBulk(body) {
    const Url = url.sendMessageInBulk;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, body, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
  deleteRecord(Id) {
    const Url = url.deleteManualRecord + "?id=" + Id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.httpClient
      .post(Url, httpOptions)
      .map(response => response)
      .catch((error: any) => {
        return Observable.throw(error.error);
      });
  }
}
