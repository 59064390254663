import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ready-help',
  templateUrl: './ready-help.component.html',
  styleUrls: ['./ready-help.component.css']
})
export class ReadyHelpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
