import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
} from "@angular/forms";
import { AdvanceDetails } from 'src/app/_models/advance-model';
import { AlertService } from "../../_service/alert.service";
import * as pattern from "../../model/user";
import { IMyDpOptions, IMyDateModel, IMyDefaultMonth } from 'mydatepicker';
import { HomeServiceService } from 'src/app/_service/home-service.service';
import { MustMatch } from '../school-registration/password-match.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolService } from 'src/app/_service/school-service.service';
import { Observable, Subject, zip } from 'rxjs';
import { DuplicateMailVerify } from './duplicateemail-verify.validator';
import { WebcamImage } from 'ngx-webcam';
declare var $: any;
declare var jic: any;
@Component({
  selector: 'app-university-student-registration',
  templateUrl: './university-student-registration.component.html',
  styleUrls: ['./university-student-registration.component.css']
})
export class UniversityStudentRegistrationComponent implements OnInit {
  public AdvanceDetails: AdvanceDetails = new AdvanceDetails();
  public loader: boolean;
  countryList: any = [];
  id = '';
  statesList: any = [];
  africaCountryList: any = [];
  registrationType: string;
  candidateRegForm: FormGroup;
  password;
  fileList: FileList;
  uploadStudentImage: any;
  reader = new FileReader();
  tempImg: any;
  timeDropDownList = [];
  show: boolean = false;
  isSubmitted: boolean = false;
  public getClass: any;
  checkboxEnabled: boolean = false;
  submittedError: boolean = false;
  url: any;
  public passwordType: string = "password";
  universityList:any = [{ id:"1", name: "Others"},
  { id:"2", name: "A.d. Rufai College Of Edu. For Legal And Islamic Studies, Misau, Bauchi State"},
  { id:"3", name: "Abdu Gusau Polytechnic, Talata Mafara"},
  { id:"4", name: "Abdullahi Maikano College Of Education, Wase, Plateau State"},
  { id:"5", name: "Abia State College Of Education (Technical), Abia State"},
  { id:"6", name: "Abia State polytechnic"},
  { id:"7", name: "Abia State University, Uturu"},
  { id:"8", name: "ABTI-American University of Nigeria"},
  { id:"9", name: "Abubakar Tafawa Balewa University"},
  { id:"10", name: "Abubakar Tatari Ali Polytechnic, Bauchi, Bauchi State (Nce Awarding)"},
  { id:"11", name: "Achievers University, Owo"},
  { id:"12", name: "Adamawa State polytechnic, Yola"},
  { id:"13", name: "Adamawa State University, Mubi"},
  { id:"14", name: "Adamu Augie College Of Education, Argungu, Kebbi State"},
  { id:"15", name: "Adamu Garkuwa College Of Education, Toro, Bauchi State"},
  { id:"16", name: "Adamu Tafawa Balewa College Of Education, Kangere, Bauchi State"},
  { id:"17", name: "Adekunle Ajasin University"},
  { id:"18", name: "Adeleke University, Ede"},
  { id:"19", name: "Adeniran Ogunsanya College of Education"},
  { id:"20", name: "Adeshina College Of Education, Share, Kwara State"},
  { id:"21", name: "Adeyemi College of Education, Ondo"},
  { id:"22", name: "Admiralty University, Ibusa Delta State"},
  { id:"23", name: "Afe Babalola University, Ado-Ekiti – Ekiti State"},
  { id:"24", name: "African Thinkers Community Of Inquiry College Of Education, Enugu State"},
  { id:"25", name: "African University of Science & Technology, Abuja"},
  { id:"26", name: "Agz College Of Education, Bajoga, Gombe State"},
  { id:"27", name: "Ahmadu Bello University"},
  { id:"28", name: "Ahmadu Bello University, Zaria"},
  { id:"29", name: "Ahman Pategi University, Kwara State"},
  { id:"30", name: "Air Force Institute of Technology, Kaduna"},
  { id:"31", name: "Airforce Institute of Technology (AFIT),NAF Base Kaduna"},
  { id:"32", name: "Ajayi Crowther University, Ibadan"},
  { id:"33", name: "Ajayi Crowther University, Oyo"},
  { id:"34", name: "Ajayi Polytechnic"},
  { id:"35", name: "Akanu Ibiam Federal polytechnic, Unwana"},
  { id:"36", name: "Akwa Ibom State College of Art & Science"},
  { id:"37", name: "Akwa Ibom State polytechnic"},
  { id:"38", name: "Akwa-ibom State College Of Education, Afaha-nsit, Akwa Ibom State"},
  { id:"39", name: "Al-Ansar University, Maiduguri, Borno"},
  { id:"40", name: "Al-Bayan University, Ankpa, Kogi State"},
  { id:"41", name: "Aletheia University, Ago-Iwoye Ogun State"},
  { id:"42", name: "Alex Ekwueme University, Ndufu-Alike, Ebonyi State"},
  { id:"43", name: "Alhikma College Of Education, Ankpa, Kogi State"},
  { id:"44", name: "Al-ibadan College Of Education, Ibadan, Oyo State"},
  { id:"45", name: "Al-Istiqama University, Sumaila, Kano State"},
  { id:"46", name: "Allover Central Polytechnic, Sango Ota,"},
  { id:"47", name: "Allover central polytechnic, Sango-Ota Ogun State"},
  { id:"48", name: "Al-Muhibbah Open University, Abuja"},
  { id:"49", name: "Alvan Ikoku Federal College Of Education, Owerri, Imo State"},
  { id:"50", name: "Amadeus University, Amizi, Abia State"},
  { id:"51", name: "Amaj University, Kwali, Abuja"},
  { id:"52", name: "Ambrose Alli University"},
  { id:"53", name: "Ameenudeen College Of Education, Kano, Kano State"},
  { id:"54", name: "Ameer Shehu Idris College Of Advanced Studies, Zaria, Kaduna State"},
  { id:"55", name: "American University of Nigeria, Yola"},
  { id:"56", name: "Aminu Kano College Of Education, Kano, Kano State"},
  { id:"57", name: "Aminu Kano College Of Islamic And Legal Studies, Kano, Kano State"},
  { id:"58", name: "Aminu Saleh College Of Education, Azare, Bauchi State"},
  { id:"59", name: "Anan University, Kwall, Plateau State"},
  { id:"60", name: "Anchor University Ayobo Lagos State"},
  { id:"61", name: "Angel Crown College Of Education, Gidan-daya, Fct-abuja"},
  { id:"62", name: "Annur Institute For Islamic Educ, Kwalajawa, Rangaza, Kano State"},
  { id:"63", name: "Ansar-ud-deen College Of Education, Isolo, Lagos State"},
  { id:"64", name: "Apa College Of Education, Aido Godo-okpoga, Benue State"},
  { id:"65", name: "Arabic And Islamic College Of Education, Potiskum, Yobe State"},
  { id:"66", name: "Arthur Javis University Akpoyubo Cross river State"},
  { id:"67", name: "Ashi Polytechnic"},
  { id:"68", name: "Assanusiyah College Of Education, Ipetumodu, Osun State"},
  { id:"69", name: "Atiba University Oyo"},
  { id:"70", name: "Atiku Abubakar College Of Legal And Islamic Studies, Nguru, Yobe State"},
  { id:"71", name: "Auchi polytechnic, Auchi"},
  { id:"72", name: "Augustine University"},
  { id:"73", name: "Ave Maria University, Piyanko, Nasarawa State"},
  { id:"74", name: "Awori District College Of Education, Ogun State"},
  { id:"75", name: "Azman University, Kano State"},
  { id:"76", name: "Baba Ahmed University, Kano State"},
  { id:"77", name: "Babcock University,Ilishan-Remo"},
  { id:"78", name: "Bauchi Institute For Arabic And Islamic Studies, Bauchi State"},
  { id:"79", name: "Bayelsa State Polytechnic,"},
  { id:"80", name: "Bayero University, Kano"},
  { id:"81", name: "Bayo Tijani College Of Education, Egan-igando, Lagos State"},
  { id:"82", name: "Bellarks Polytechnic"},
  { id:"83", name: "Bells University"},
  { id:"84", name: "Bells University of Technology, Otta"},
  { id:"85", name: "Benson Idahosa University"},
  { id:"86", name: "Benue State Polytechnic"},
  { id:"87", name: "Best Legacy College Of Education, Ogbomoso, Oyo State"},
  { id:"88", name: "Best Solution Polytechnic, Akure"},
  { id:"89", name: "Bethel College Of Education, Ijare, Ondo State"},
  { id:"90", name: "Biga College Of Education, Arkilla, Federal-low Cost, Nasarawa, Sokoto State"},
  { id:"91", name: "Bilyamin Othman College Of Education, Dass, Bauchi State"},
  { id:"92", name: "Bingham University, New Karu"},
  { id:"93", name: "Binyaminu Usman Polytechnic, Hadejia,Jigawa State"},
  { id:"94", name: "Bolmor Polytechnic, GPO Box 11221 Dugbe, Ibadan, Oyo"},
  { id:"95", name: "Bornu State University, Maiduguri"},
  { id:"96", name: "Bowen University, Iwo"},
  { id:"97", name: "Bowen University, Iwo"},
  { id:"98", name: "British Canadian University, Obufu Cross River State"},
  { id:"99", name: "BStudents and exam malpracticesock University"},
  { id:"100", name: "Calvary Polytechnic, Owa-Oyibu"},
  { id:"101", name: "Calvin Foundation College Of Education, Naka, Benue State"},
  { id:"102", name: "Canadian University of Nigeria, Abuja"},
  { id:"103", name: "Capital City University, Kano State"},
  { id:"104", name: "Caritas University, Enugu"},
  { id:"105", name: "Cetep City University, Lagos"},
  { id:"106", name: "Chrisland University"},
  { id:"107", name: "Christopher University Mowe"},
  { id:"108", name: "Citi Polytechnic"},
  { id:"109", name: "City College Of Education, Garki, Abuja, Fct"},
  { id:"110", name: "Claretian University of Nigeria, Nekede, Imo State"},
  { id:"111", name: "Clifford University Owerrinta Abia State"},
  { id:"112", name: "Coal City University Enugu State"},
  { id:"113", name: "Coastal Polytechnic Apapa"},
  { id:"114", name: "College Of Education, (Tech), Lafiagi, Kwara State"},
  { id:"115", name: "College of Education, Afaha-Nsit"},
  { id:"116", name: "College of Education, Agbor"},
  { id:"117", name: "College of Education, Agbor"},
  { id:"118", name: "College of Education, Akwanga"},
  { id:"119", name: "College of Education, Akwanga"},
  { id:"120", name: "College Of Education, Ankpa, Kogi State"},
  { id:"121", name: "College of Education, Azare"},
  { id:"122", name: "College Of Education, Billiri, Gombe State"},
  { id:"123", name: "College Of Education, Darazo, Bauchi State"},
  { id:"124", name: "College of Education, Ekiadolor"},
  { id:"125", name: "College Of Education, Ero, Akure, Ondo State"},
  { id:"126", name: "College Of Education, Foreign Links Campus Moro, Ife North, Osun State"},
  { id:"127", name: "College Of Education, Gidan-waya, Kafanchan, Kaduna State"},
  { id:"128", name: "College of Education, Gindiri"},
  { id:"129", name: "College Of Education, Hong, Adamawa State"},
  { id:"130", name: "College Of Education, Igueben, Edo State"},
  { id:"131", name: "College of Education, Ikere Ekiti"},
  { id:"132", name: "College Of Education, Ilesha, Osun State"},
  { id:"133", name: "College Of Education, Irra Rd. Ilemona, Kwara State"},
  { id:"134", name: "College of Education, Katsina Ala"},
  { id:"135", name: "College of Education, Katsina-Ala"},
  { id:"136", name: "College Of Education, Katsina-ala, Benue State"},
  { id:"137", name: "College Of Education, Kura, Kano State"},
  { id:"138", name: "College Of Education, Lanlate, Oyo State"},
  { id:"139", name: "College Of Education, Maru, Zamfara State"},
  { id:"140", name: "College Of Education, Minna, Niger State"},
  { id:"141", name: "College Of Education, Oju, Benue State"},
  { id:"142", name: "College of Education, Oro, Kwara State"},
  { id:"143", name: "College Of Education, Waka-biu, Borno State"},
  { id:"144", name: "College of Education, Warri"},
  { id:"145", name: "College Of Education, Zing, Taraba State"},
  { id:"146", name: "College of Education, Zuba"},
  { id:"147", name: "College of Petroleum and Energy Studies, Kaduna State"},
  { id:"148", name: "Confluence University of Science and Technology Osara, Kogi"},
  { id:"149", name: "Cornerstone College Of Education, Lagos, Lagos State"},
  { id:"150", name: "Corona College Of Education, Lekki, Lagos State"},
  { id:"151", name: "Cosmopolitan University Abuja"},
  { id:"152", name: "Covenant College Of Education, Aba, Abia State"},
  { id:"153", name: "Covenant Polytechnic, Aba,"},
  { id:"154", name: "Covenant University"},
  { id:"155", name: "Covenant University Ota"},
  { id:"156", name: "Crawford University Igbesa"},
  { id:"157", name: "Crawford University,Oye Ekiti"},
  { id:"158", name: "Crescent University"},
  { id:"159", name: "Crest Field College Of Education, Erin Osun, Osun State"},
  { id:"160", name: "Cross River State College Of Education, Akamkpa, Cross River State"},
  { id:"161", name: "Cross River State Institute of Technology And Management"},
  { id:"162", name: "Cross River University of Technology"},
  { id:"163", name: "Crown Hill University Eiyenkorin, Kwara State"},
  { id:"164", name: "Crown Polytechnic, Ado-Ekiti"},
  { id:"165", name: "D.S. Adegbenro ICT Polytechnic, Itori-Ewekoro"},
  { id:"166", name: "Delar College Of Education, Ibadan, Oyo State"},
  { id:"167", name: "Delta State College Of Physical Education, Mosogar, Delta State"},
  { id:"168", name: "Delta State Polytechnic, Ogwashi-Uku"},
  { id:"169", name: "Delta state polytechnic, Ozoro"},
  { id:"170", name: "Delta State School of Marine Technology, Burutu"},
  { id:"171", name: "Delta State University, Abraka"},
  { id:"172", name: "Delta University of Science and Technology, Ozoro"},
  { id:"173", name: "Dennis Osadebe University, Asaba"},
  { id:"174", name: "Diamond College Of Education, Aba, Abia State"},
  { id:"175", name: "Dominican University Ibadan Oyo State"},
  { id:"176", name: "Dominion University Ibadan, Oyo State"},
  { id:"177", name: "Dorben polytechnic (formerly Abuja School of Accountancy and Computer Studies)"},
  { id:"178", name: "Eastern Polytechnic"},
  { id:"179", name: "Ebenezer College Of Education, Edda, Ebonyi State"},
  { id:"180", name: "Ebonyi State College Of Education, Ikwo, Ebonyi State"},
  { id:"181", name: "Ebonyi State University"},
  { id:"182", name: "Ecwa College Of Education, Igbaja, Kwara State"},
  { id:"183", name: "Ecwa College Of Education, Jos, Plateau State"},
  { id:"184", name: "Edo State College Of Education, Edo State"},
  { id:"185", name: "Edo State Polytechnic, Usen"},
  { id:"186", name: "Edo State University Uzairue"},
  { id:"187", name: "Edusoko University, Bida, Niger State"},
  { id:"188", name: "Edwin Clark University, Kaigbodo"},
  { id:"189", name: "Ekiti State College of Agriculture and Technology"},
  { id:"190", name: "Ekiti State University"},
  { id:"191", name: "Eko University of Medical and Health Sciences Ijanikin, Lagos"},
  { id:"192", name: "El-Amin University, Minna, Niger State"},
  { id:"193", name: "Elder Oyama Memorial College Of Education, Ofat, Obubra, Cross River State"},
  { id:"194", name: "Elizabeth Memorial College Of Education, Odenigbo, Nsukka, Enugu State"},
  { id:"195", name: "Elrazi Medical University Yargaya University, Kano State"},
  { id:"196", name: "Emmanuel Alayande College Of Education, Oyo, Oyo State"},
  { id:"197", name: "Emmanuel Ebije Ikwue College Of Education, Otukpo, Benue State"},
  { id:"198", name: "Enugu State College Of Education (Tech.) Enugu Abakaliki Rd. Enugu State"},
  { id:"199", name: "Enugu State Polytechnic, Iwollo"},
  { id:"200", name: "Enugu State University of Medical and Applied Sciences, Igbo-Eno"},
  { id:"201", name: "Enugu State University of Science & Technology"},
  { id:"202", name: "Enville Institute of Management and Technology"},
  { id:"203", name: "European University of Nigeria, Duboyi, FCT"},
  { id:"204", name: "Evangel University, Akaeze"},
  { id:"205", name: "FCT College of Education, Zuba"},
  { id:"206", name: "Fct College Of Education, Zuba, Abuja, Fct"},
  { id:"207", name: "Federal coll. of Animal health & production Tech., Ibadan"},
  { id:"208", name: "Federal College of Education (Special), Oyo"},
  { id:"209", name: "Federal College Of Education (Tech), Bichi, Kano, Kano State"},
  { id:"210", name: "Federal College Of Education (Tech.) (Female Only), Gusau, Zamfara State"},
  { id:"211", name: "Federal College Of Education (Tech.), Asaba, Delta State"},
  { id:"212", name: "Federal College Of Education (Tech.), Omoku, Rivers State"},
  { id:"213", name: "Federal College Of Education (Tech.), Potiskum, Yobe State"},
  { id:"214", name: "Federal College Of Education (Tech.), Umunze, Anambra State"},
  { id:"215", name: "Federal College of Education (Technical) Gombe"},
  { id:"216", name: "Federal College of Education (Technical) Gusau"},
  { id:"217", name: "Federal College of Education, (Technical) Bich"},
  { id:"218", name: "Federal College Of Education, Abeokuta, Ogun State"},
  { id:"219", name: "Federal College of Education, Akoka"},
  { id:"220", name: "Federal College Of Education, Eha-amufu, Enugu State"},
  { id:"221", name: "Federal College Of Education, Ekiadolor, Ovia North, Edo State"},
  { id:"222", name: "Federal College Of Education, Gidan Madi, Tangaza, Sokoto State"},
  { id:"223", name: "Federal College Of Education, Isu, Onicha, Ebonyi State"},
  { id:"224", name: "Federal College Of Education, Iwo, Osun State"},
  { id:"225", name: "Federal College Of Education, Jama’are, Bauchi State"},
  { id:"226", name: "Federal College Of Education, Kano, Kano State"},
  { id:"227", name: "Federal College Of Education, Katsina, Katsina State"},
  { id:"228", name: "Federal College Of Education, Kontagora, Niger State"},
  { id:"229", name: "Federal College of Education, Obudu"},
  { id:"230", name: "Federal College Of Education, Odugbo, Apa, Benue State"},
  { id:"231", name: "Federal College of Education, Okene"},
  { id:"232", name: "Federal College Of Education, Okene, Kogi State"},
  { id:"233", name: "Federal College of Education, Omoku"},
  { id:"234", name: "Federal College of Education, pankshin"},
  { id:"235", name: "Federal College of Education, Yola"},
  { id:"236", name: "Federal College of Education, Zaira"},
  { id:"237", name: "Federal College of Education, Zaria"},
  { id:"238", name: "Federal Polytechnic Ayede, Oyo State"},
  { id:"239", name: "Federal Polytechnic Bali, Taraba State."},
  { id:"240", name: "Federal polytechnic Bida"},
  { id:"241", name: "Federal Polytechnic Ekowe, Bayelsa State"},
  { id:"242", name: "Federal Polytechnic Kaura Namoda"},
  { id:"243", name: "Federal Polytechnic Mubi, PMB 35 Mubi, Adamawa State."},
  { id:"244", name: "Federal Polytechnic Munguno, Borno State"},
  { id:"245", name: "Federal Polytechnic Nasarawa, P.M.B. 01 Nasarawa State."},
  { id:"246", name: "Federal Polytechnic Nekede, P.M.B 1036, Owerri, Imo State."},
  { id:"247", name: "Federal Polytechnic of Oil and Gas Bonny, Rivers State"},
  { id:"248", name: "Federal polytechnic Offa"},
  { id:"249", name: "Federal Polytechnic Ohodo, Enugu State"},
  { id:"250", name: "Federal Polytechnic Ukana,P.M.B 2014Akwa Ibom State"},
  { id:"251", name: "Federal Polytechnic Wannune, Benue State"},
  { id:"252", name: "Federal polytechnic, Ado – Ekiti"},
  { id:"253", name: "Federal polytechnic, Bauchi"},
  { id:"254", name: "Federal polytechnic, Birnin-Kebbi"},
  { id:"255", name: "Federal polytechnic, Damaturu"},
  { id:"256", name: "Federal Polytechnic, Daura, Katsina State"},
  { id:"257", name: "Federal polytechnic, Ede"},
  { id:"258", name: "Federal polytechnic, Idah"},
  { id:"259", name: "Federal polytechnic, Ilaro"},
  { id:"260", name: "Federal polytechnic, Mubi"},
  { id:"261", name: "Federal polytechnic, Nassarawa"},
  { id:"262", name: "Federal polytechnic, Nekede"},
  { id:"263", name: "Federal polytechnic, Oko"},
  { id:"264", name: "Federal University Gashua, Yobe"},
  { id:"265", name: "Federal University of Agriculture, Zuru, Kebbi State"},
  { id:"266", name: "Federal University of Health Sciences, Azare, Bauchi State"},
  { id:"267", name: "Federal University of Health Sciences, Ila Orangun, Osun State"},
  { id:"268", name: "Federal University of Health Technology, Otukpo Benue State"},
  { id:"269", name: "Federal University of Petroleum Resources, Effurun"},
  { id:"270", name: "Federal University of Technology, Akure"},
  { id:"271", name: "Federal University of Technology, Babura, Jigawa State"},
  { id:"272", name: "Federal University of Technology, Ikot Abasi, Akwa Ibom State"},
  { id:"273", name: "Federal University of Technology, Minna"},
  { id:"274", name: "Federal University of Technology, Owerri"},
  { id:"275", name: "Federal University Of Technology, Yola"},
  { id:"276", name: "Federal University, Birnin Kebbi"},
  { id:"277", name: "Federal University, Dutse, Jigawa State"},
  { id:"278", name: "Federal University, Dutsin-Ma, Katsina"},
  { id:"279", name: "Federal University, Gusau Zamfara"},
  { id:"280", name: "Federal University, Kashere, Gombe State"},
  { id:"281", name: "Federal University, Lafia, Nasarawa State"},
  { id:"282", name: "Federal University, Lokoja, Kogi State"},
  { id:"283", name: "Federal University, Otuoke, Bayelsa"},
  { id:"284", name: "Federal University, Oye-Ekiti, Ekiti State"},
  { id:"285", name: "Federal University, Wukari, Taraba State"},
  { id:"286", name: "Festmed College Of Education, Ajowa,ondo State"},
  { id:"287", name: "Fidei Polytechnic, Gboko"},
  { id:"288", name: "Franco British International University, Kaduna State"},
  { id:"289", name: "Gateway polytechnic Saapade"},
  { id:"290", name: "Gateway Polytechnic,"},
  { id:"291", name: "Gboko College Of Education, Gboko, Benue State"},
  { id:"292", name: "Gboko Polytechnic, Gboko,"},
  { id:"293", name: "Gerar University of Medical Science Imope Ijebu, Ogun State."},
  { id:"294", name: "Global College Of Education, Jos, Plateau State"},
  { id:"295", name: "Godfrey Okoye University, Ugwuomu-Nike – Enugu State"},
  { id:"296", name: "Gombe state University,"},
  { id:"297", name: "Good Shepherd College Of Education, Danlemo Alakuko, Ogun State"},
  { id:"298", name: "Grace College Of Education, Oshogbo, Osun State"},
  { id:"299", name: "Grace Polytechnic, Surulere,"},
  { id:"300", name: "Graceland Polytechnic, Offa"},
  { id:"301", name: "Greenfield University, Kaduna"},
  { id:"302", name: "Gregory University, Uturu"},
  { id:"303", name: "Grundtvig Polytechnic"},
  { id:"304", name: "Hallmark University, Ijebi Itele, Ogun"},
  { id:"305", name: "Hamzainab College Of Education, Osogbo, Osun State"},
  { id:"306", name: "Harvard Wilson College Of Education, Aba, Abia State"},
  { id:"307", name: "Hassan Usman Katsina Polytechnic"},
  { id:"308", name: "Hassan Usman Katsina Polytechnic, Katsina, Katsina State (Nce Awarding)"},
  { id:"309", name: "Havilla University, Nde-Ikom, Cross River State"},
  { id:"310", name: "Hensard University, Toru-Orua, Sagbama, Bayelsa State"},
  { id:"311", name: "Heritage Polytechnic, Ikot Udota, Eket,"},
  { id:"312", name: "Hezekiah University, Umudi"},
  { id:"313", name: "Hill College Of Education, Gwanji, Akwanga, Nasarawa State"},
  { id:"314", name: "Hillside University of Science and Technology, Okemisi, Ekiti State"},
  { id:"315", name: "Hope And Anchor College Of Education, Suleja, Niger State"},
  { id:"316", name: "Huda University, Gusau, Zamafara State"},
  { id:"317", name: "Hussaini Adamu Federal polytechnic, kazaure"},
  { id:"318", name: "Hussaini Adamu Federal Polytechnic, P.M.B 5004, Kazaure Jigawa State."},
  { id:"319", name: "Ibadan City Polytechnic,"},
  { id:"320", name: "I-CON Universal Polytechnic Osogbo"},
  { id:"321", name: "Iconic Open University, Sokoto State."},
  { id:"322", name: "Igbajo Polytechnic, Igbajo"},
  { id:"323", name: "Igbinedion University Okada"},
  { id:"324", name: "Igbinedion University, Okada"},
  { id:"325", name: "Ilori College Of Education, Ede, Osun State"},
  { id:"326", name: "Imam Hamzat College Of Education, Ilorin, Kwara State"},
  { id:"327", name: "Imam Saidu College Of Education, Funtua, Katsina State"},
  { id:"328", name: "Imo State College Of Education, Ihitte/uboma, Imo State"},
  { id:"329", name: "Imo State University"},
  { id:"330", name: "Institute Of Ecumenical Education, Thinker Corner, Enugu, Enugu State"},
  { id:"331", name: "Institute of Management Technology, Enugu"},
  { id:"332", name: "Ipere College Of Education, Agyaragu, Nasarawa State"},
  { id:"333", name: "Isa Kaita College Of Education, Dutsinma, Katsina State"},
  { id:"334", name: "Isa Mustapha Agwai Polytechnic Lafia"},
  { id:"335", name: "Isaac Jasper Boro College Of Education, Sagbama, Bayelsa State"},
  { id:"336", name: "Iwo City Polytechnic, Iwo"},
  { id:"337", name: "James Hope University, Lagos, Lagos State"},
  { id:"338", name: "Jewel University, Gombe state"},
  { id:"339", name: "Jibwis College Of Education, Gombe, Gombe State"},
  { id:"340", name: "Jibwis College Of Education, Jamaare, Bauchi State"},
  { id:"341", name: "Jibwis College Of Education, Keffi, Nasarawa State"},
  { id:"342", name: "Jibwis College Of Education, Potiskum, Yobe State"},
  { id:"343", name: "Jigawa State College Of Education And Legal Studies, Ringim, Jigawa State"},
  { id:"344", name: "Jigawa State College Of Education, Gumel, Jigawa State"},
  { id:"345", name: "Jigawa State Polytechnic, Dutse"},
  { id:"346", name: "Joseph Ayo Babalola University"},
  { id:"347", name: "Joseph Ayo Babalola University, Ikeji-Arakeji"},
  { id:"348", name: "Kaduna polytechnic"},
  { id:"349", name: "Kaduna Polytechnic, Kaduna, Kaduna State (Nce Awarding)"},
  { id:"350", name: "Kalac Christal Polytechnic,"},
  { id:"351", name: "Kano State College of Education, Kano"},
  { id:"352", name: "Kano State polytechnic, Kano"},
  { id:"353", name: "Kano State Polytechnic, Kano, Kano State (Nce Awarding)"},
  { id:"354", name: "Karl-Kumm University, Vom, Plateau State"},
  { id:"355", name: "Kashim-ibrahim College Of Education, Maiduguri, Borno State"},
  { id:"356", name: "Katsina State University"},
  { id:"357", name: "Kazaure College Of Education, Kazaure, Jigawa State"},
  { id:"358", name: "Ken Sarowiwa Polytechnic, Bori,"},
  { id:"359", name: "Khadija University, Majia, Jigawa State"},
  { id:"360", name: "Khalifa Isiyaku Rabiu University, Kano"},
  { id:"361", name: "King David Umahi University of Medical Sciences, Uburu, Ebonyi State"},
  { id:"362", name: "Kings Polytechnic, Ubiaja,"},
  { id:"363", name: "Kings University, Ode Omu"},
  { id:"364", name: "Kingsley Ozumba Mbadiwe University Ogboko, Imo State"},
  { id:"365", name: "Kinsey College Of Education, Ilorin, Kwara State"},
  { id:"366", name: "Kogi East College Of Education, Kogi State"},
  { id:"367", name: "Kogi State College Of Education (Technical), Kabba, Kogi State"},
  { id:"368", name: "Kogi State University, Anyigba"},
  { id:"369", name: "Kola Daisi University Ibadan, Oyo State"},
  { id:"370", name: "Kwara State College of Education"},
  { id:"371", name: "Kwara State polytechnic"},
  { id:"372", name: "Kwararafa College Of Education, Otukpo, Benue State"},
  { id:"373", name: "Kwararafa University, Wukari"},
  { id:"374", name: "Ladoke Akintola University of Tech."},
  { id:"375", name: "Lagos City Computer College"},
  { id:"376", name: "Lagos City polytechnic"},
  { id:"377", name: "Lagos City Polytechnic, Ikeja,"},
  { id:"378", name: "Lagos State polytechnic"},
  { id:"379", name: "Lagos State University"},
  { id:"380", name: "Lagos State University of Science and Technology Ikorodu"},
  { id:"381", name: "Landmark Polytechnic"},
  { id:"382", name: "Landmark University, Omu-Aran."},
  { id:"383", name: "Lead City University"},
  { id:"384", name: "Lead City University, Ibadan"},
  { id:"385", name: "Legacy University, Okija Anambra State"},
  { id:"386", name: "Lens Polytechnic"},
  { id:"387", name: "Lessel College Of Education, Lessel, Ushongo Lga, Benue State"},
  { id:"388", name: "Life Gate College Of Education, Afon, Asa, Kwara State"},
  { id:"389", name: "Lighthouse Polytechnic,"},
  { id:"390", name: "Lux Mundi University Umuahia, Abia State"},
  { id:"391", name: "Madonna University, Okija"},
  { id:"392", name: "Maduka University, Ekwegbe, Enugu State"},
  { id:"393", name: "Mai-Idris Alooma Polytechnic, Geidam,"},
  { id:"394", name: "Maranathan University, Mgbidi, Imo State"},
  { id:"395", name: "Margaret Lawrence University, Umunede, Delta State"},
  { id:"396", name: "Marist Polytechnic, Umuchigbo, Iji-Nike,"},
  { id:"397", name: "Maritime Academy of Nigeria, Oron"},
  { id:"398", name: "Maryam Abacha American University of Nigeria, Kano State"},
  { id:"399", name: "Mater Dei Polytechnic"},
  { id:"400", name: "Mcpherson University, Seriki Sotayo, Ajebo"},
  { id:"401", name: "Meadow Hall College Of Education, Alma Beach Estste, Lagos State"},
  { id:"402", name: "Mercy Medical University, Iwo, Ogun State"},
  { id:"403", name: "Metro College Of Education Adogi, Nassarawa State"},
  { id:"404", name: "Mewar International University, Masaka, Nasarawa State"},
  { id:"405", name: "Michael And Cecilia Foundation College Of Educ. Agbrha-otor,ughelli, Delta State"},
  { id:"406", name: "Michael Okpara University"},
  { id:"407", name: "Michael Otedola College Of Primary Education, Moforiji, Lagos State"},
  { id:"408", name: "Micheal & Cecilia Ibru University"},
  { id:"409", name: "Miva Open University, Abuja FCT"},
  { id:"410", name: "Modibbo Adama University of Technology, Yola"},
  { id:"411", name: "Mohammed Goni College Of Legal And Islamic Studies, Maiduguri, Borno State"},
  { id:"412", name: "Moje College Of Education, Erin-ile, Kwara State"},
  { id:"413", name: "Moshood Abiola polytechnic, Abeokuta"},
  { id:"414", name: "Moshood Abiola University of Science and Technology Abeokuta"},
  { id:"415", name: "Mountain Top University"},
  { id:"416", name: "Mudiame University, Irrua, Edo State"},
  { id:"417", name: "Muftaua Lanihun College Of Education, Ibadan-lagos Exp Way,oremeji, Oyo State"},
  { id:"418", name: "Muhammad Kamalud University Kwara"},
  { id:"419", name: "Muhyideen College Of Education, Ilorin, Kwara State"},
  { id:"420", name: "Muritadha College Of Education, Olodo, Ibadan, Oyo State"},
  { id:"421", name: "Nacabs Polytechnic, Akwanga"},
  { id:"422", name: "Nana Aishat Memorial College Of Education, Kwara State"},
  { id:"423", name: "Nasarrawa State University"},
  { id:"424", name: "National Institute For Nigerian Languages, Aba, Abia State"},
  { id:"425", name: "National Institute of Construction Technology Uromi"},
  { id:"426", name: "National Open University"},
  { id:"427", name: "National Teachers Institute, kaduna"},
  { id:"428", name: "Newgate University, Minna, Niger State."},
  { id:"429", name: "Niger Delta University Yenagoa"},
  { id:"430", name: "Niger State polytechnic, Zungeru"},
  { id:"431", name: "Niger State Polytechnic, Zungeru"},
  { id:"432", name: "Nigeria Police Academy Wudil"},
  { id:"433", name: "Nigerian Army Institute of Technology and Environmental Science (NAITES) Makurdi"},
  { id:"434", name: "Nigerian Army School Of Education, Ilorin, Kwara State"},
  { id:"435", name: "Nigerian Army University Biu"},
  { id:"436", name: "Nigerian British University, Asa, Abia State"},
  { id:"437", name: "Nigerian Coll. of Aviation Tech., Zaira"},
  { id:"438", name: "Nigerian Defence Academy Kaduna"},
  { id:"439", name: "Nigerian Maritime University Okerenkoko, Delta State"},
  { id:"440", name: "Nigerian University of Technology and Management, Apapa, Lagos State"},
  { id:"441", name: "Nile University of Nigeria, Abuja"},
  { id:"442", name: "Nnamdi Azikiwe University, Awka"},
  { id:"443", name: "Nogak Polytechnic, Ikom,"},
  { id:"444", name: "NOK University, Kachia, Kaduna State"},
  { id:"445", name: "NorthWest University Sokoto State"},
  { id:"446", name: "Nosakhare College Of Education, Benin City, Edo State"},
  { id:"447", name: "Novelty Polytechnic Kishi"},
  { id:"448", name: "Nuhu Bamalli Polytechnic, Zaria,"},
  { id:"449", name: "Nuhu Bamalli polytechnic, Zaria, Kaduna State"},
  { id:"450", name: "Nuhu Bamalli Polytechnic, Zaria, Kaduna State (Nce Awarding)"},
  { id:"451", name: "Nwafor Orizu College of Education"},
  { id:"452", name: "Obafemi Awolowo University,Ile-Ife"},
  { id:"453", name: "Obong University, Obong Ntak"},
  { id:"454", name: "Oduduwa University, Ipetumodu – Osun State"},
  { id:"455", name: "Oke-Ogun Polytechnic, Shaki,Oyo State"},
  { id:"456", name: "Olabisi Onabanjo University, Ago Iwoye"},
  { id:"457", name: "Ondo State University of Medical Sciences"},
  { id:"458", name: "Ondo State University of Science and Technology Okitipupa"},
  { id:"459", name: "Onit College Of Education Mbaukwu, Anambra State"},
  { id:"460", name: "Osun State College of Education, Ila-Orangun"},
  { id:"461", name: "Osun State College of Education, Ilesa"},
  { id:"462", name: "Osun State College of Technology"},
  { id:"463", name: "Osun State College of Technology, Esa-Oke"},
  { id:"464", name: "Osun State polytechnic, Iree"},
  { id:"465", name: "Osun State University Osogbo"},
  { id:"466", name: "Oswald Waller College Of Education, Shendam, Plateau State"},
  { id:"467", name: "Our Saviour Institute Of Science & Tech. College Of Education, Ogui, Enugu State"},
  { id:"468", name: "Our Saviour Institute of Science, Agriculture & Technology, Enugu,"},
  { id:"469", name: "Oyo State College of Agriculture and Technology, Igbo Ora"},
  { id:"470", name: "Oyo State College Of Education, Oyo"},
  { id:"471", name: "Oyo State Technical University Ibadan"},
  { id:"472", name: "PAMO University of Medical Sciences, Portharcourt"},
  { id:"473", name: "Pan African College Of Education, Offa, Kwara State"},
  { id:"474", name: "Pan-Atlantic University, Lagos"},
  { id:"475", name: "Peace College Of Education, Enokpoli, Kogi State"},
  { id:"476", name: "Peace Land College Of Education, Enugu, Enugu State"},
  { id:"477", name: "PeaceLand University, Enugu State"},
  { id:"478", name: "Peacock College Of Education, Jalingo, Taraba State"},
  { id:"479", name: "PEN Resource University, Gombe"},
  { id:"480", name: "Peter University, Achina-Onneh Anambra State"},
  { id:"481", name: "Philomath University, Kuje, Abuja"},
  { id:"482", name: "Phoenix University, Agwada, Nasarawa State"},
  { id:"483", name: "Piaget College Of Education, Ibara, Abeokuta, Ogun State"},
  { id:"484", name: "Plateau State Polytechnic, Barkin-Ladi,"},
  { id:"485", name: "Plateau State Polytechnic, Barkin-ladi, Plateau State (Nce Awarding)"},
  { id:"486", name: "Plateau State University Bokkos"},
  { id:"487", name: "Port-Harcourt Polytechnic"},
  { id:"488", name: "Precious Cornerstone University, Oyo"},
  { id:"489", name: "Prime Polytechnic, Jida Bassa, Ajaokuta,"},
  { id:"490", name: "Prime University, Kuje, FCT Abuja"},
  { id:"491", name: "Prince Abubakar Audu University Anyigba"},
  { id:"492", name: "Ramat polytechnic, Maiduguri"},
  { id:"493", name: "Ramat Polytechnic, Maiduguri, Borno State (Nce Awarding)"},
  { id:"494", name: "Rayhaan University, Kebbi"},
  { id:"495", name: "Redeemer’s University, Ede"},
  { id:"496", name: "Redeemers College of Technology and Management"},
  { id:"497", name: "Renaissance University, Enugu"},
  { id:"498", name: "Rhema University, Obeama-Asa – Rivers State"},
  { id:"499", name: "Ritman University, Ikot Ekpene, Akwa Ibom"},
  { id:"500", name: "River State polytechnic, Bori"},
  { id:"501", name: "Rivers State College of Education"},
  { id:"502", name: "Ronik Polytechnic, Ejigbo,"},
  { id:"503", name: "Royal City College Of Education, Iyesi, Otta, Ogun State"},
  { id:"504", name: "Rufus Giwa polytechnic, Owo"},
  { id:"505", name: "Sa’adatu Rimi University of Education"},
  { id:"506", name: "Saadatu Rimi College Of Education, Kumbotso, Kano State"},
  { id:"507", name: "Saf Polytechnic,"},
  { id:"508", name: "Saisa University of Medical Sciences and Technology, Sokoto State"},
  { id:"509", name: "Sam Maris University, Ondo"},
  { id:"510", name: "Sarkin Yamma Community College Of Education, Tilden Fulani, Bauchi State"},
  { id:"511", name: "Savanah Institute of Technology"},
  { id:"512", name: "Shaka polytechnic"},
  { id:"513", name: "Shaka Polytechnic"},
  { id:"514", name: "Shanahan University Onitsha, Anambra State"},
  { id:"515", name: "Shehu Shagari College Of Education, Sokoto, Sokoto State"},
  { id:"516", name: "Shehu Shagari University of Education, Sokoto"},
  { id:"517", name: "Sinai College Of Education And Entrepreneurial Studies, Gboko, Benue State"},
  { id:"518", name: "Skyline University, Kano"},
  { id:"519", name: "Sokoto State University"},
  { id:"520", name: "Southern Atlantic Polytechnic"},
  { id:"521", name: "Speedway Polytechnic"},
  { id:"522", name: "Spiritan University, Nneochi Abia State"},
  { id:"523", name: "Sports University, Idumuje, Ugboko, Delta State"},
  { id:"524", name: "St. Augustine College Of Education (Project Time), Yaba, Lagos State"},
  { id:"525", name: "St. Mary Polytechnic, Kwamba,"},
  { id:"526", name: "Stars Polytechnic"},
  { id:"527", name: "Steady Flow College Of Education, Akarabong, Ikom, Cross River State"},
  { id:"528", name: "Sule Lamido University, Kafin Hausa, Jigawa"},
  { id:"529", name: "Summit University"},
  { id:"530", name: "Sunnah College Of Education, Bauchi, Bauchi State"},
  { id:"531", name: "Sure Foundation Polytechnic, Ikot Akai,"},
  { id:"532", name: "Tai Solarin College Of Education, Ijebu-ode, Ogun State"},
  { id:"533", name: "Tai Solarin University of Education Ijebu Ode"},
  { id:"534", name: "Tansian University, Umunya"},
  { id:"535", name: "Taraba State Polytechnic, Suntai"},
  { id:"536", name: "Taraba State University, Jalingo"},
  { id:"537", name: "Temple Gate Polytechnic, Abayi, Osisioma"},
  { id:"538", name: "The African Church College Of Education, Lagos, Lagos State"},
  { id:"539", name: "The College Of Education, Nsukka, Enugu State"},
  { id:"540", name: "The Duke Medical University, Calabar, Cross River State"},
  { id:"541", name: "The Polytechnic Iresi"},
  { id:"542", name: "The Polytechnic Otada Adoka"},
  { id:"543", name: "The polytechnic, Ibadan"},
  { id:"544", name: "The Polytechnic, Igbo-Owu, Kwara State"},
  { id:"545", name: "The Polytechnic, Ile Ife"},
  { id:"546", name: "Thomas Adewumi University, Oko-Irese, Kwara State"},
  { id:"547", name: "Timeon Kairos Polytechnic Lagos"},
  { id:"548", name: "Topfaith University, Mkpatak, Akwa Ibom State"},
  { id:"549", name: "Topmost College Of Education, Ipaja, Lagos State"},
  { id:"550", name: "Tower Polytechnic, Ibadan,"},
  { id:"551", name: "Trinity Polytechnic Uyo,"},
  { id:"552", name: "Trinity University Ogun State"},
  { id:"553", name: "Turath College Of Islamic Studies, Goron Dutse, Kano State"},
  { id:"554", name: "Uli College Of Education, Uli, Anambra State"},
  { id:"555", name: "Uma Ukpai Polytechnic,"},
  { id:"556", name: "Umar Bun Khatab College Of Education, Kaduna State"},
  { id:"557", name: "Umar Musa Yar’ Adua University Katsina"},
  { id:"558", name: "Umar Suleiman College Of Education, Gashua, Yobe State"},
  { id:"559", name: "Umar-ibn Ei-kanemi College Of Educ. Science And Tech, Bama, Borno State"},
  { id:"560", name: "Unity College Of Education, Aukpa-adoka, Benue State"},
  { id:"561", name: "University of Abuja"},
  { id:"562", name: "University of Abuja, Gwagwalada"},
  { id:"563", name: "University of Ado-Ekiti"},
  { id:"564", name: "University of Agriculture, Abeokuta"},
  { id:"565", name: "University of Agriculture, Makurdi"},
  { id:"566", name: "University of Benin"},
  { id:"567", name: "University of Calabar"},
  { id:"568", name: "University of Education, Ikere-Ekiti"},
  { id:"569", name: "University of Ibadan"},
  { id:"570", name: "University of Ilesa, Osun State"},
  { id:"571", name: "University of Ilorin"},
  { id:"572", name: "University of Jos"},
  { id:"573", name: "University of Lagos"},
  { id:"574", name: "University of Maiduguri"},
  { id:"575", name: "University of Mkar, Mkar"},
  { id:"576", name: "University of Mkar, Mkar"},
  { id:"577", name: "University of Nigeria"},
  { id:"578", name: "University of Offa, Kwara State"},
  { id:"579", name: "University of port-harcourt"},
  { id:"580", name: "University of Technology, Akwa-Ibom"},
  { id:"581", name: "University Of Uyo"},
  { id:"582", name: "University on the Niger, Umunya, Anambra state"},
  { id:"583", name: "Upland College Of Education, Ipesi-ifira, Akoko, Ondo State"},
  { id:"584", name: "Usumanu Danfodiyo University"},
  { id:"585", name: "Uyo City Polytechnic,"},
  { id:"586", name: "Valley View Polytechnic, Ohafia"},
  { id:"587", name: "Venite University, Iloro-Ekiti, Ekiti State"},
  { id:"588", name: "Veritas University, Abuja"},
  { id:"589", name: "Villanova Polytechnic, Imesi-Ile"},
  { id:"590", name: "Vision University, Ikogbo, Ogun State"},
  { id:"591", name: "Waziri Umaru Federal Polytechnic, Birnin Kebbi, Kebbi State (Nce Awarding)"},
  { id:"592", name: "Waziri Umaru Federal Polytechnic, P.M.B. 1034, Birnin Kebbi."},
  { id:"593", name: "Wellspring University, Evbuobanosa – Edo State"},
  { id:"594", name: "Wesley University Ondo"},
  { id:"595", name: "West Midlands Open University, Ibadan, Oyo State"},
  { id:"596", name: "Western Delta University, Oghara Delta State"},
  { id:"597", name: "Westland University Iwo, Osun State"},
  { id:"598", name: "Wigwe University, Isiokpo Rivers State"},
  { id:"599", name: "Wolex Polytechnic, Iwo."},
  { id:"600", name: "Yaba College of Tech."},
  { id:"601", name: "Yewa Central College Of Education, Abeokuta, Ogun State"},
  { id:"602", name: "Yusuf Bala Usman College Of Legal And General Studies, Daura, Katsina State"},
  { id:"603", name: "Yusuf Maitama Sule University Kano"},
  { id:"604", name: "Zamfara State College of Arts and Science"}  
  
]
  levelOfStudy: any = [{ id: "100", name: "100" },
  { id: "200", name: "200" },
  { id: "300", name: "300" },
  { id: "400", name: "400" },
  { id: "500", name: "500" },
  { id: "600", name: "600" },
  { id: "700", name: "700" }
  ]
  public LastDate: Date = new Date();
  public PreviousDate: Date = new Date();
  public defaultMonth: IMyDefaultMonth = {
    defMonth: `0${this.LastDate.getMonth()}/${this.LastDate.getFullYear() - 15}`
  };
  public mytime: Date = new Date();
  currentYear: any = this.mytime.getUTCFullYear();
  currentDate: any = this.mytime.getUTCDate();
  currentMonth: any = this.mytime.getUTCMonth() + 1;
  public myDatePickerOptions: IMyDpOptions = {
    inline: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    // disableSince: {
    //   year: this.currentYear - 15,
    //   month: this.currentMonth + 1,
    //   day: this.currentDate
    // },
    // disableUntil: {
    //   year: this.PreviousDate.getFullYear() - 40,
    //   month: this.PreviousDate.getMonth() + 1,
    //   day: this.PreviousDate.getDate()
    // },
    dateFormat: 'dd-mmm-yyyy',
  };

  public startDate: Object = {
    date: {
      year: 2008,
      month: this.PreviousDate.getMonth(),
      day: this.PreviousDate.getDate()
    }
  };
  enable: boolean;
  getTimeSlot: { id: number; value: string; }[];
  classList: any;
  affiliatedList: any;
  trigger: Subject<void> = new Subject<void>();
  webcamImage: WebcamImage = null;
  webActive: boolean;
  webcamImageEnable: boolean;

  constructor(
    private fb: FormBuilder,
    private service: HomeServiceService,
    private ref: ChangeDetectorRef,
    // private secondService: HomeSecondService,
    private alertService: AlertService,
    // private route: ActivatedRoute,
    private _router: Router,
    private el: ElementRef,
    private activeRoute: ActivatedRoute,
    private schoolService: SchoolService
  ) { }

  ngOnInit() {
    window.scroll(0, 0)
    this.activeRoute.queryParams.subscribe((params) => {
      // if (params["type"] === "advn") {
      //   this.registrationType = 'Advance';
      // }
      // else {
      //   this.registrationType = 'Continental';
      // }
      if (params["Id"]) {
        this.id = JSON.parse(localStorage.getItem("logincredential")).body.Id;
      }
    });
    this.getTimeSlot = [{ id: 1, value: '9am-10am' },
    { id: 2, value: '12pm-1pm' },
    { id: 3, value: '3pm-4pm' }]
    this.getCourseOfStudy()
    this.getStatesname();
    this.getCountryname();
    this.getCountry();
    // this.getTimeDropDownList();
    this.password = 'password'
    this.candidateForm();
    // this.setValidators();
    // this.today = new Date();
    // this.sixMonthsAgo = new Date();
    // this.sixMonthsAgo.setMonth(this.today.getMonth() - 6);

    // openDatePicker(dp) {
    //   dp.open();
    // }

    // closeDatePicker(eventData: any, dp?:any) {
    //   // get month and year from eventData and close datepicker, thus not allowing user to select date
    //   dp.close();    
    // }

  }
  programType: any = [{ "id": '1', value: 'Part Time' }, { "id": '2', value: 'Full Time' },];
  courseType: any = [{ "id": '1', value: 'Bsc' }, { "id": '2', value: 'Msc' }, { "id": "3", value: "PhD" }];
  secschooldata: any = [{ "id": '1', value: 'welcome' },];
  ssecschooldata: any = [{ "id": '1', value: 'wereje' }, { "id": '2', value: 'Lagos' },];
  selectyear: any = [{ "id": '1', value: '2012' }, { "id": '1', value: '2013' },];
  IdentificationId: any = [
    { "id": '1', value: 'National ID' }, 
    { "id": '2', value: "Voter's Card" },
    { "id": '3', value: 'School ID' },
    { "id": '4', value: "Driver's License" },
    { "id": '5', value: 'Others' },
  ];
  cardNumber: any = [{ "id": '1', value: '1001' },];
  relationShip: any = [{ "id": '1', value: 'Father' }, { "id": '2', value: 'Mother' },{ "id": '3', value: 'Other' }];
  candidateForm() {
    this.candidateRegForm = this.fb.group({
      Id: [this.id],
      ProfilePic: ["", [Validators.required]],
      FirstName: ["", [Validators.required]],
      LastName: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      // OriginState: ["", [Validators.required]],
      OriginCountry: ["", [Validators.required]],
      // CountryOfOrigin: ["", [Validators.required]],
      // ResidenceState: ["", [Validators.required]],
      ResidenceCountry: ["", [Validators.required]],
      CandidateEmail: ["", [Validators.required, Validators.email]],
      Gender: ["", [Validators.required]],
      CandidatePhone: ["", [Validators.required,
      Validators.minLength(7),
      Validators.required,
      Validators.pattern(pattern.phonePattern),
      ]],
      AlternateCandidatePhone: [""],
      CandidateAddress: [""],
      SchoolName: ["", [Validators.required]],
      CourseOfStudy: ["", [Validators.required]],
      Programme: ["", [Validators.required]],
      CourseType: ["", [Validators.required]],
      DateOfAdmission: ["", [Validators.required]],
      LevelOfStudy: ["", [Validators.required]],
      SecondarySchoolName: ["", [Validators.required]],
      YearGraduated: ["", Validators.required],
      // IdFirstName: ["", [Validators.required]],
      // IdMiddleName: ["", [Validators.required]],
      // IdSurname: ["", [Validators.required]],
      // IdType: ["", [Validators.required]],
      // IdCardNumber: ["", [Validators.required]],
      // IdDateOfIssue: ["", [Validators.required]],
      // IdDateOfExpiry: ["", [Validators.required]],
      ParentName: ["", [Validators.required]],
      ParentSurname: ["", [Validators.required]],
      ParentRelation: ["", [Validators.required]],
      ParentEmail: ["", [Validators.required]],
      ParentPhone: ["", [Validators.required]],
      Password: [""],
      ConfirmPassword: [""],
      PreferedTime: [""],
      prepClass: [""],
      SecondarySchoolState: [""]
    },
      { validator: [MustMatch("Password", "ConfirmPassword"), DuplicateMailVerify("CandidateEmail", "ParentEmail")] }
    );
    if (this.id != '' && this.id != null) {
      this.getCandidateDetails();
      this.candidateRegForm.get('Password').clearValidators();
      this.candidateRegForm.get('Password').updateValueAndValidity();
      this.candidateRegForm.get('ConfirmPassword').clearValidators();
      this.candidateRegForm.get('ConfirmPassword').updateValueAndValidity();
    }
    else if (!this.id) {
      this.candidateRegForm.get('Password').setValidators([Validators.required, Validators.minLength(6)]);
      this.candidateRegForm.get('Password').updateValueAndValidity();
      this.candidateRegForm.get('ConfirmPassword').setValidators([Validators.required]);
      this.candidateRegForm.get('ConfirmPassword').updateValueAndValidity();
    }
  }

  CandidateRegistration(form) {
    console.log(form.value)
    form.value.Id = this.id
    this.isSubmitted = true;
    // if (this.candidateRegForm.value.IdDateOfIssue && this.candidateRegForm.value.IdDateOfExpiry && (this.candidateRegForm.value.IdDateOfIssue.jsdate > this.candidateRegForm.value.IdDateOfExpiry.jsdate)) {
    //   this.candidateRegForm.controls["IdDateOfExpiry"].setErrors({ "dateCheck": true });
    // }
    // else {
    //   this.candidateRegForm.controls["IdDateOfExpiry"].setErrors({ "dateCheck": null });
    //   this.candidateRegForm.controls["IdDateOfExpiry"].updateValueAndValidity();
    // }
    if (this.id != '' && this.id != null) {
      this.candidateRegForm.get('ConfirmPassword').setErrors(null);
      this.candidateRegForm.get('ConfirmPassword').clearValidators();
    }
    if (this.candidateRegForm.invalid) {
      this.submittedError = true;
      for (const key of Object.keys(this.candidateRegForm.controls)) {
        if (this.candidateRegForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          $(invalidControl).focus();
          break;
        }
      }
      return;
    }
    if (form.value.dob.date != undefined) {
      let dateofbirth = form.value.dob.date;
      form.value.dob = new Date(dateofbirth.year, dateofbirth.month - 1, dateofbirth.day).toISOString()
    } else {
      form.value.dob = ""
    }
    // if (form.value.IdDateOfIssue.date != undefined) {
    //   let dateofissue = form.value.IdDateOfIssue.date;
    //   form.value.IdDateOfIssue = new Date(dateofissue.year, dateofissue.month - 1, dateofissue.day).toISOString()
    // } else {
    //   form.value.IdDateOfIssue = ""
    // }
    // if (form.value.IdDateOfExpiry.date != undefined) {
    //   console.log(form.value.IdDateOfExpiry.date)
    //   let dateofexpiry = form.value.IdDateOfExpiry.date;
    //   form.value.IdDateOfExpiry = new Date(dateofexpiry.year, dateofexpiry.month - 1, dateofexpiry.day).toISOString()
    // } else {
    //   form.value.IdDateOfExpiry = ""
    // }
    if (form.value.DateOfAdmission.date != undefined) {
      let dateofaddmission = form.value.DateOfAdmission.date;
      form.value.DateOfAdmission = new Date(dateofaddmission.year, dateofaddmission.month - 1, dateofaddmission.day).toISOString()
    } else {
      form.value.DateOfAdmission = ""
    }
    form.value.Password = btoa(this.candidateRegForm.value.Password);
    form.value.ConfirmPassword = btoa(this.candidateRegForm.value.ConfirmPassword);
    this.loader = true;
    this.service.CandidateUniversityRegister(this.candidateRegForm.value).subscribe((res) => {
      this.loader = false;
      if (res) {
        if (this.id) {
          this._router.navigateByUrl("advance/unversity-dashboard");
          this.alertService.success("Updated Successfully");

        } else {
          this._router.navigateByUrl("login");
          this.alertService.success("Registration Successful, Please verify the account using the verification link send to email provided.");
        }
      }
    },
      (error) => {
        this.loader = false;
        this.alertService.error(error, true);
      });
  }

  get s() {
    return this.candidateRegForm.controls;
  }
  setValidators() {
    if (this.registrationType === "Continental") {
      this.candidateRegForm.get('ParentName').setValidators([]);
      this.candidateRegForm.get('ParentName').updateValueAndValidity();
      this.candidateRegForm.get('ParentEmail').setValidators([Validators.email]);
      this.candidateRegForm.get('ParentEmail').updateValueAndValidity();
      this.candidateRegForm.get('ParentPhone').setValidators([
        Validators.minLength(7),
        Validators.pattern(pattern.phonePattern),]);
      this.candidateRegForm.get('ParentPhone').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorName').setValidators([Validators.required]);
      this.candidateRegForm.get('MathTutorName').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorEmail').setValidators([Validators.required, Validators.email]);
      this.candidateRegForm.get('MathTutorEmail').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorPhone').setValidators([Validators.required,
      Validators.minLength(7),
      Validators.pattern(pattern.phonePattern)]);
      this.candidateRegForm.get('MathTutorPhone').updateValueAndValidity();
      this.candidateRegForm.get('SchoolEmail').setValidators([Validators.required, Validators.email]);
      this.candidateRegForm.get('SchoolEmail').updateValueAndValidity();
      this.candidateRegForm.get('SchoolPhone').setValidators([
        Validators.minLength(7),
        Validators.pattern(pattern.phonePattern)]);
      this.candidateRegForm.get('SchoolPhone').updateValueAndValidity();
    }
    else {
      this.candidateRegForm.get('ParentName').clearValidators();
      this.candidateRegForm.get('ParentName').updateValueAndValidity();
      this.candidateRegForm.get('ParentEmail').clearValidators();
      this.candidateRegForm.get('ParentEmail').updateValueAndValidity();
      this.candidateRegForm.get('ParentPhone').clearValidators();
      this.candidateRegForm.get('ParentPhone').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorName').clearValidators();
      this.candidateRegForm.get('MathTutorName').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorEmail').clearValidators();
      this.candidateRegForm.get('MathTutorEmail').updateValueAndValidity();
      this.candidateRegForm.get('MathTutorPhone').clearValidators();
      this.candidateRegForm.get('MathTutorPhone').updateValueAndValidity();
      this.candidateRegForm.get('SchoolEmail').clearValidators();
      this.candidateRegForm.get('SchoolEmail').updateValueAndValidity();
      this.candidateRegForm.get('SchoolPhone').clearValidators();
      this.candidateRegForm.get('SchoolPhone').updateValueAndValidity();
      // this.candidateRegForm.get('ProfilePic').clearValidators();
      // this.candidateRegForm.get('ProfilePic').updateValueAndValidity();
    }
  }

  getClassbyCategory(event) {
    var cat = event.target.value;
    this.schoolService.getClass(cat).subscribe(
      res => {
        this.getClass = res;
      },
      error => { }
    );
  }
  numbersOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.tempImg = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.ref.markForCheck();
    }

    this.fileList = event.target.files;
    this.uploadStudentImage = event.target.files[0];
    if (this.fileList.length > 0) {
      let file: File = this.fileList[0];
      // if(file.size > 204800){
      //   this.resizeImage(file);
      // }
      // else {
      // // this.candidateRegForm.get("ProfilePic").setValue(file);
      // }
    }
  }
  public resizeImage() {
    let imgFile;
    if (typeof this.uploadStudentImage === "string") {
      imgFile = this.dataURLtoFile(this.uploadStudentImage, "myfile.jpg");
    } else {
      imgFile = this.uploadStudentImage;
    }
    if (imgFile && imgFile.size > 204800) {
      const src_img: any = document.getElementById("myprofileimg");
      const percentage = (200 * 100) / (imgFile.size / 1024);
      const targetImg = jic.compress(src_img, Math.floor(percentage), "jpg")
        .src;
      const file = this.dataURLtoFile(targetImg, imgFile.name);
      this.uploadStudentImage = file;
      src_img.src = targetImg;
      if (file && file.size > 204800) {
        this.resizeImage();
      } else {
        this.candidateRegForm
          .get("ProfilePic")
          .setValue(this.uploadStudentImage);
        this.reader.readAsDataURL(this.uploadStudentImage);
      }
    }
    else if (this.webcamImageEnable) {
      console.log(this.tempImg.imageAsDataUrl.split(",")[1])
      const cameraFile = this.dataURLtoFile(this.tempImg.imageAsDataUrl, "my.jpeg");
      this.candidateRegForm
        .get("ProfilePic")
        .setValue(cameraFile);
    } 
    else {
      this.candidateRegForm
        .get("ProfilePic")
        .setValue(this.uploadStudentImage);
    }
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getStatesname() {
    this.service.getStatefromCountry(2).subscribe(
      (res: any) => {
        this.statesList = res;
      },
      (error) => { }
    );
  }
  getCountry() {
    this.service.CountryListApi().subscribe(
      (res: any) => {
        this.countryList = res
        for (let i = 0; i < res.length; i++) {
          if (res[i].Continent == 'Africa') {
            this.africaCountryList.push(res[i])
          }
        }
      },
      (error) => { }
    );
  }
  getCountryname() {
    this.service.getCountry().subscribe(
      (res: any) => {
        this.countryList = res;
      },
      (error) => { }
    );
  }
  showPassword() {
    if (this.password === "password") {
      this.password = "text";
      this.show = true;
    } else {
      this.password = "password";
      this.show = false;
    }
  }
  nameValidations(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  // prep classes
  // getTimeAsNumberOfMinutes(time) {
  //   var timeParts = time.split(":");

  //   var timeInMinutes = (timeParts[0] * 60) + timeParts[1];

  //   return timeInMinutes;
  // }

  // getTimeDropDownList() {
  //   for (let i = 1; i <= 24; i++) {
  //     for (let j = 1; j <= 2; j++) {
  //       let time = {
  //         value: '',
  //         viewValue: ''
  //       }
  //       if (j == 1) {
  //         time.value = i < 12 ? i + ':' + '00' + ' AM' : i + ':' + '00' + ' PM'
  //         time.viewValue = i < 12 ? i + ':' + '00' + ' AM' : i + ':' + '00' + ' PM'
  //         this.timeDropDownList.push(time)
  //       } else {
  //         if (i != 24) {
  //           time.value = i < 12 ? i + ':' + '30' + ' AM' : i + ':' + '30' + ' PM'
  //           time.viewValue = i < 12 ? i + ':' + '30' + ' AM' : i + ':' + '30' + ' PM'
  //           this.timeDropDownList.push(time)

  //         }
  //       }
  //     }
  //   }
  // }
  // timeChange(value, type) {
  //   if (type == 'startTime') {
  //     this.candidateRegForm.value.preferedstartTime = value
  //   }
  //   else {
  //     this.candidateRegForm.value.preferedEndTime = value
  //   }
  //   let t1 = this.getTimeAsNumberOfMinutes(this.candidateRegForm.value.preferedstartTime)
  //   let t2 = this.getTimeAsNumberOfMinutes(this.candidateRegForm.value.preferedEndTime)
  //   if (t1 >= t2) {
  //     this.candidateRegForm.controls['preferedEndTime'].setErrors({ timeError: true })
  //   }
  //   else {
  //     this.candidateRegForm.controls['preferedEndTime'].setErrors(null)
  //   }
  // }
  getCandidateDetails() {
    this.loader = true;
    if (this.id) {
      this.service.getUniversityCandidateDetail(this.id).subscribe((res: any) => {
        this.getStatesname();
        var start = new Date(res.Dob);
        var x: Object = {
          date: {
            year: start.getFullYear(),
            month: start.getMonth() + 1,
            day: start.getDate(),
            formatted: start.toUTCString()
          }
        };
        var start1 = new Date(res.DateOfAdmission);
        var y: Object = {
          date: {
            year: start1.getFullYear(),
            month: start1.getMonth() + 1,
            day: start1.getDate(),
            formatted: start.toUTCString()
          }
        };
        // var start2 = new Date(res.IdDateOfIssue);
        // var z: Object = {
        //   date: {
        //     year: start2.getFullYear(),
        //     month: start2.getMonth() + 1,
        //     day: start2.getDate(),
        //     formatted: start2.toUTCString()
        //   }
        // };
        // var start3 = new Date(res.IdDateOfExpiry);
        // var a: Object = {
        //   date: {
        //     year: start3.getFullYear(),
        //     month: start3.getMonth() + 1,
        //     day: start3.getDate(),
        //     formatted: start3.toUTCString()
        //   }
        // };
        this.candidateRegForm.patchValue(res);
        if (res.PreferedTime != null && res.PreferedTime != "") {
          this.checkboxEnabled = true
          this.candidateRegForm.patchValue({
            prepClass: true,
            PreferedTime: res.PreferedTime,
          })
        }
        this.candidateRegForm.patchValue({
          dob: x,
          ProfilePic: res.Picture,
          DateOfAdmission: y,
          // IdDateOfIssue: z,
          // IdDateOfExpiry: a
        })
        this.url = res.Picture
        this.loader = false;
      },
        (error) => {
          this.loader = false;
        })
    }
    else {
      this.id = "";
    }
  }

  chckbox() {
    var el = document.getElementById('checkTick') as HTMLInputElement
    if (el.checked) {
      console.log('checked')
      this.checkboxEnabled = true
      this.candidateRegForm.controls['PreferedTime'].setValidators(Validators.required)
      this.candidateRegForm.controls['PreferedTime'].updateValueAndValidity();
    } else {
      this.checkboxEnabled = false
      this.candidateRegForm.controls['PreferedTime'].clearValidators()
      this.candidateRegForm.controls['PreferedTime'].updateValueAndValidity();
    }
  }

  dashboard() {
    this._router.navigateByUrl("advance/unversity-dashboard");
  }

  getCourseOfStudy() {
    this.service.getUniversityCourseOfStudyApi().subscribe((res: any) => {
      if (res) {
        this.classList = res
      }
    })
  }

  triggerSnapshot(): void {
    this.webActive = true
    this.trigger.next();
  }
  camera() {
    this.tempImg = "";
    $('#webCameraModel').modal('show')
    this.webActive = true
  }
  handleImage(webcamImage: WebcamImage): void {
    this.tempImg = "";
    console.info("received webcam image", webcamImage);
    this.webcamImageEnable = true
    this.webActive = false
    $("#webCameraModel").modal("hide");
    this.tempImg = webcamImage;
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
