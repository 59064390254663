import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl
} from "@angular/forms";
import { HomeServiceService } from "src/app/_service/home-service.service";
import * as pattern from "../model/user";
import { AlertService } from "src/app/_service/alert.service";
@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"]
})
export class ContactUsComponent implements OnInit {
  contactusForm: FormGroup;
  submittedError: boolean;
  loader: boolean;
  emailErrRes: any;
  phoneErrRes: any;
  concernList: any;
  constructor(
    public formBuilder: FormBuilder,
    public service: HomeServiceService,
    public alert: AlertService
  ) {}

  ngOnInit() {
    this.contactusForm = this.formBuilder.group({
      //   Email: ["", Validators.compose([Validators.required]),
      //   this.service.emailExistValidator()
      // ],
      Email: ["", Validators.compose([Validators.required, Validators.email])],
      Address: ["", Validators.required],
      ConcernType: ["", Validators.required],
      Name: ["", Validators.required],
      Phone: [
        "",
        Validators.compose([Validators.required]),
        // this.service.phoneExistValidator()
      ]
    });

    this.getConcernList();
  }

  onSubmit(form) {
    if (form.invalid) {
      this.submittedError = true;
      return;
    }
    this.loader = true;
    this.service.contactUs(form.value).subscribe(
      res => {
        this.loader = false;
        this.alert.success(JSON.stringify(res), true);
        this.contactusForm.reset();
      },
      err => {
        this.loader = false;
        this.alert.error("Something went wrong contact to admin", true);
        console.log("Contact us form submitted negative", err);
      }
    );
  }

  //   validateEmailExist(control: AbstractControl) {
  //     var value = control.value;
  //     const emailPattern = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;
  //     if(emailPattern.test(value)){
  //       return this.emailVerification(value);
  //     }
  //   }

  // emailVerification(email){
  // this.service.emailVerify(email).subscribe(res => {
  //   console.log("email verification from server positive",res);
  //     if(res == true)
  //       {return null;}
  //       else
  //       {
  //         return { emailTaken: true };
  //       }

  //      },err=>{
  //       return null
  //   })
  // }

  //   validatePhoneExist(control: AbstractControl) {
  //     var value = control.value;
  //     const phonePattern = /^[0-9]{11}$/;
  //      if(phonePattern.test(value)){

  //     }
  //   }

  // phoneVerification(phone){
  //     return this.service.phoneVerify(phone).subscribe(res => {
  //          console.log("phone verification from server positive",res);
  //           if(res == true)
  //       {return null;}
  //       else
  //       {
  //         return { phoneTaken: true };
  //       }
  //     },err=>{
  //       return null;
  //     });
  // }

  getConcernList() {
    this.service.getConcernType().subscribe(
      res => {
        this.concernList = res;
        this.concernList = this.concernList.List;
      },
      err => {
        console.log("Concern Type negative", err);
      }
    );
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  public restrictNumeric(event) {
    if (!event.shiftKey && (event.which >= 48 && event.which <= 57)) {
      // to allow numbers
      return true;
    } else if (!event.shiftKey && (event.which >= 96 && event.which <= 105)) {
      // to allow numpad number
      return true;
    } else if (
      !event.shiftKey &&
      [8, 13, 27, 37, 38, 39, 40, 9, 35, 36, 46, 43].indexOf(event.which) > -1
    ) {
      // to allow backspace, enter, escape, arrows
      return true;
    } else if (!event.shiftKey && (event.which >= 112 && event.which <= 126)) {
      // to allow F1,F2,F3.....
      return true;
    } else {
      event.preventDefault();
      // to stop others
      return false;
    }
  }
}
