import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { contactUs } from 'src/app/_models/contactus-model';
import { AlertService } from 'src/app/_service/alert.service';
import { HomeServiceService } from 'src/app/_service/home-service.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactUsModel: contactUs = new contactUs();
  contactusForm: FormGroup;
  isSubmitted: boolean = false;
  countryData = [];
  loader:Boolean = false;
  countryList: any[] =[];
  constructor(
    private formBuilder: FormBuilder,
    private service:HomeServiceService,
    private alert:AlertService
  ) {}

  ngOnInit() {
    this.getCountryList()
    // this.getCountryData();
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.contactusForm = this.formBuilder.group({
      userName: [this.contactUsModel.userName, [Validators.required]],
      Email: [
        this.contactUsModel.Email,
        [Validators.required, Validators.pattern(emailregex)],
      ],
      Content: [this.contactUsModel.Content, [Validators.required]],
      Category: [this.contactUsModel.Category, [Validators.required]],
      Priority: [this.contactUsModel.Priority, [Validators.required]],
      CountryCode: [this.contactUsModel.CountryCode],
      Phone: [
        this.contactUsModel.Phone,
        [
          Validators.required,
          Validators.minLength(7),
          // Validators.maxLength(11),
        ],
      ],
    });
  }
  // getCountryData() {
    // this.spinnerService.show()
    // this.service.getCountryData().subscribe(
    //   (res: any) => {
    //     this.countryData = res.Data;
    //   },
    //   (error) => {
    //     // console.log(error);
    //   }
    // );
  // }

  get f() {
    return this.contactusForm.controls;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  submit() {
    this.isSubmitted = true;
    if (this.contactusForm.invalid) {
      return;
    }
    this.loader = true
    this.service.ContactUsdetailsApi(this.contactUsModel).subscribe(
      (res: any) => {
        this.alert.success(res,true)
        this.loader = false
        this.contactusForm.reset();
        this.isSubmitted = false;
      },
      (error) => {
        this.loader = false
        this.alert.error(error,true)
      }
    );
  }

  getCountryDialCodeError() {
    if (
      this.contactUsModel.CountryCode == "" ||
      this.contactUsModel.CountryCode == null
    ) {
      return true;
    }
  }
  getCountryList(){
    this.service.CountryListApi().subscribe((res:any)=>{
      // let merge3 = res.flat(1);
      // console.log(merge3)
      this.countryData = res
      // this.countryData = [].concat.apply([], res);
    // res.forEach(element => { 
    //   if(element.Id){
    //     this.countryData.push(element)
    //   } 
    // });
//     res = res.reduce(function(a, b){
//       return a.concat(b);
//  }, []);
//     console.log(res)

    
    })
  }

  nameValidation(evt) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
    if (!regex.test(key)) {
      evt.preventDefault();
      return false;
    }
  }
}
