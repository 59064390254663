import { Component, Input, OnInit } from "@angular/core";
import * as url from "src/app/model/config";
import { HomeSecondService } from "../_service/home-second.service";
import {
  CompendiumModel,
  SyllabusModel
} from "../models/home-page-model/home-page-model.module";
import { HomeServiceService } from "../_service/home-service.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ShareDataService } from "../_shared/share-data.service";
declare var $: any;
@Component({
  selector: "app-home-header",
  templateUrl: "./home-header.component.html",
  styleUrls: ["./home-header.component.css"]
})
export class HomeHeaderComponent implements OnInit {
  Category = [
    { Id: "Junior", CategoryName: "Junior" },
    { Id: "Senior", CategoryName: "Senior" }
  ];
  Filters = {
    pageno: 1,
    pagesize: "5",
    isactive: "",
    sortorder: "",
    sortby: "",
    Season: "",
    Category: ""
  };
  SelectedItem = [];
  SelectedItemArray = [];
  List = { TotalRecords: 0, List: [] };
  SyllabusList = { TotalRecords: 0, List: [] };
  hideLabelText = null;
  year: any;
  BaseAddress: any;
  noRecordFoundText: any;
  isPublished: any;
  public loginExist: boolean = false;
  public compendiumModel: CompendiumModel = new CompendiumModel();
  public SyllabusModel: SyllabusModel = new SyllabusModel();
  public compendiumYear: any;
  public syllabusYear: any;
  public compendiumVolume: any;
  //parms = $routeParams;
  States = [];
  userProfile: any;
  afterLoginHeader: boolean;
  currentUrl: string;
  IsprePublished: any;
  IsQualifyPublished: any;
  nigeria: boolean = false;
  africa: boolean = false;
  countryType: string;
  // africarRegestrationDetails: any;
  // africaRegistartionStartDate: Date;
  // africaRegistartionEndDate: Date;
  currentDate = new Date()
  // universityRegClosed: boolean;
  // universityRegistartionEndDate: Date;
  // universityRegistartionStartDate: Date;
  // universityRegestrationDetails: any;
  isAfricaPublished: any;
  IsAfricaPrePublished: any;
  IsAfricaQualifyPublished: any;
  isUniversityPublished: any;
  IsUniversityQualifyPublished: any;
  IsUniversityPrePublished: any;
  nigRegClosed: boolean;
  regestrationDetails: any;
  registartionStartDate: Date;
  registartionEndDate: Date;
  allRegistartionDetails: any;
  regClosed: any;
  constructor(public serviceSecond: HomeSecondService, public service: HomeServiceService, private router: Router,
    private _share: ShareDataService,
    private activeRoute: ActivatedRoute) {
      this.countryType = 'Nigeria'
    // this.service.countryType.subscribe((res: string) => {
    //   this.countryType = res
    //   if (this.countryType) {
    //     this.countryType = res
    //   }
    //   else {
    //     this.countryType = 'Nigeria'
    //   }
    // })
    this.service.regClosedBySeason.subscribe((res: any) => {
      console.log(res)
      if (res) {
        this.regClosed = res
      }
      else {
        this.regClosed = false
      }
    })

    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        window.scroll(0, 0);
        var url = document.location.pathname;
        this.currentUrl = url;
        if (url == "/" || url == "/home") {
          if (localStorage.getItem("auth_token") != undefined) {
            this.afterLoginHeader = true;
          } else {
            this.afterLoginHeader = false;
          }
        }
        if (url == "/" || url == "/login") {
          // if (localStorage.getItem("auth_token") != undefined) {          
          this.afterLoginHeader = true;
          // } 
        }
      }
    });
  }

  ngOnInit() {
    this.countryType = localStorage.getItem('type')
    if (this.countryType) {
      this.countryType = localStorage.getItem('type')
    }
    else {
      this.countryType = 'Nigeria'
    }
    if (this.currentUrl == "/" || this.currentUrl == "/home") {
      if (localStorage.getItem("auth_token") != undefined) {
        this.afterLoginHeader = true;
      } else {
        this.afterLoginHeader = false;
      }
    }
   
    this.service.getIsResultPublishedURI().subscribe(
      (res: any) => {
        if (res) {
          if (res && res.List && res.List[0]) {
            this.List = res.List
            for (let i = 0; i < res.List.length; i++) {
              if (res.List[i].seasontype == 'Nigeria') {
                this.isPublished = res.List[i].IsPublished;
                this.IsprePublished = res.List[i].IsprePublished;
                this.IsQualifyPublished = res.List[i].IsQualifyPublished
              }
              else if (res.List[i].seasontype == 'Africa') {
                this.isAfricaPublished = res.List[i].IsPublished;
                this.IsAfricaPrePublished = res.List[i].IsprePublished;
                this.IsAfricaQualifyPublished = res.List[i].IsQualifyPublished
              }
              else if (res.List[i].seasontype == 'University') {
                this.isUniversityPublished = res.List[i].IsPublished;
                this.IsUniversityPrePublished = res.List[i].IsprePublished;
                this.IsUniversityQualifyPublished = res.List[i].IsQualifyPublished
              }
            }

          }
        }
      },
      err => { }
    );
    // 
    if (localStorage.getItem("Role")) this.loginExist = true;
    this.getYearsList();
    this.getCompendiumYears();
    this.getSyllabusYears();
    this.getCompendiumVolumes();
    this.userProfile = JSON.parse(localStorage.getItem("ls.userProfile"));
  }

  categoryChange = function (CategoryID) {
    this.Filters.Category = CategoryID;
  };

  setNoRecordMessage = function () {
    if (this.List.TotalRecords == 0 || this.List.TotalRecords == undefined)
      this.noRecordFoundText = "No Question document found.";
    else this.noRecordFoundText = "";
  };

  setNoSyllabusMessage = function () {
    if (this.List.TotalRecords == 0 || this.List.TotalRecords == undefined)
      this.noRecordFoundText = "No Syllabus document found.";
    else this.noRecordFoundText = "";
  };

  loadList = function () {
    this.serviceSecond.LoadQuestions(this.Filters).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.List = res;
          this.BaseAddress = url.portalDocumentLocation;
          this.hideLabelText = res.List.length;
          this.setNoRecordMessage();
        } else {
          this.List.TotalRecords = 0;
          this.List.List = [];
          this.hideLabelText = null;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.setNoRecordMessage();
        this.hideLabelText = null;
        this.List = { TotalRecords: 0, List: [] };
      }
    );
  };

  clearAll = function () {
    this.List.TotalRecords = 0;
    this.List.List = [];
    this.hideLabelText = null;
    this.Filters.Category = "";
    //this.year = "";
  };
  getYearsList = function () {
    this.serviceSecond.getYears(this.Filters).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.year = res.List;
        } else {
          this.List.TotalRecords = 0;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.List = { TotalRecords: 0, List: [] };
        this.setNoRecordMessage();
      }
    );
  };

  yearChange = function (year) {
    this.Filters.Season = year;
  };

  //Phase 2

  getCompendiumYears() {
    this.serviceSecond.getCompendiumYears().subscribe(
      (res: any) => {
        if (res != null && res != "" && res.List.length > 0) {
          this.compendiumYear = res.List;
        } else {
          this.List.TotalRecords = 0;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.List = { TotalRecords: 0, List: [] };
        this.setNoRecordMessage();
      }
    );
  }

  getSyllabusYears() {
    this.serviceSecond.getSyllabusYears().subscribe(
      (res: any) => {
        if (res != null && res != "" && res.List.length > 0) {
          this.syllabusYear = res.List;
        } else {
          this.List.TotalRecords = 0;
          this.setNoSyllabusMessage();
        }
      },
      err => {
        this.List = { TotalRecords: 0, List: [] };
        this.setNoSyllabusMessage();
      }
    );
  }

  getCompendiumVolumes() {
    this.serviceSecond.getCompendiumVolumes().subscribe(
      (res: any) => {
        if (res != null && res != "" && res.List.length > 0) {
          this.compendiumVolume = res.List;
        } else {
          this.List.TotalRecords = 0;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.List = { TotalRecords: 0, List: [] };
        this.setNoRecordMessage();
      }
    );
  }

  closeHeader() {
    let ele = document.getElementById("home-left-nav");
    let el = document.getElementById("navbar");
    if (el && el.classList.contains('in')) {
      ele.click()
    }
  }


  getCompendiumList = function () {
    this.serviceSecond.LoadAnswers(this.compendiumModel).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.List = res;
          this.BaseAddress = url.serviceBaseUrl + "/CompendiumDocuments/";
          this.hideLabelText = res.List.length;
          this.setNoRecordMessage();
        } else {
          this.List.TotalRecords = 0;
          this.List.List = [];
          this.hideLabelText = null;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.setNoRecordMessage();
        this.hideLabelText = null;
        this.List = { TotalRecords: 0, List: [] };
      }
    );
  };

  getSyllabusList = function () {
    this.SyllabusModel.SeasonType = this.countryType
    this.serviceSecond.LoadSyllabus(this.SyllabusModel).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.List = res;
          this.BaseAddress = url.serviceBaseUrl + "/SyllabusDocuments/";
          this.hideLabelText = res.List.length;
          this.setNoSyllabusMessage();
        } else {
          this.List.TotalRecords = 0;
          this.List.List = [];
          this.hideLabelText = null;
          this.setNoSyllabusMessage();
        }
      },
      err => {
        this.setNoSyllabusMessage();
        this.hideLabelText = null;
        this.List = { TotalRecords: 0, List: [] };
      }
    );
  };

  logout() {
    localStorage.clear();
    if (this.router.url == "/") {
      // location.reload();
      return;
    }
    this.router.navigate([""]);
  }
  goTo(type) {
    if (type == 'objective') {
      this.router.navigate(['/home'], { queryParams: { type: 'objective' } })
    }
    if (type == 'target') {
      this.router.navigate(['/home'], { queryParams: { type: 'target' } })
    }
    if (type == 'registration') {
      this.router.navigate(['/home'], { queryParams: { type: 'registration' } })
    }
    if (type == 'stages') {
      this.router.navigate(['/home'], { queryParams: { type: 'stages' } })
    }
    if (type == 'prizes') {
      this.router.navigate(['/home'], { queryParams: { type: 'prizes' } })
    }
    if (type == 'about-cruxlearning') {
      this.router.navigate(['/home'], { queryParams: { type: 'about-cruxlearning' } })
    }

  }
  resultTab() {
    // $("#viewResultModel").modal("show");
    if (this.countryType == 'Nigeria') {
      this.router.navigate(['/result/finalists'])
    }
    else if (this.countryType == 'Africa') {
      this.router.navigate(['result/africa/finalists'])
    }
    else if (this.countryType == 'University') {
      this.router.navigate(['result/university/finalists'])
    }
  }
  selectResultsFor(type) {
    if (type == 'nigeria') {
      this.router.navigate(['result/finalists'])
      $("#viewResultModel").modal("hide");
    }
    else if (type == 'advanced') {
      this.router.navigate(['result/africa/finalists'])
      $("#viewResultModel").modal("hide");
    }
    else if (type == 'continental') {
      this.router.navigate(['result/university/finalists'])
      $("#viewResultModel").modal("hide");
    }

  }
  prepClass() {
    this.router.navigate(['/prep-classes'])
  }
  regChange() {
    if (this.countryType == 'Nigeria') {
      this.regClosed ? $('#regModel').modal('show') : this.router.navigate(['/school-registration'])
      // this.nigeriaRegStatus(this.countryType)
    }
    else if (this.countryType == 'Africa') {
      
      this.regClosed ? $('#regModel').modal('show') : this._share.registration.next(true)
    }
    else if (this.countryType == 'University') {
      this.regClosed ? $('#regModel').modal('show') : this.router.navigate(['/university-signup'])
    }
  }
  goHome() {
    this.router.navigate(['/main'])
  }
  goToHallOfFame() {
    this.router.navigate(['/hall-of-fame'])

  }
  goToHonourRole() {
    this.router.navigate(['/honars-roll'])

  }
 
//   nigeriaRegStatus(type) {
//     this.service.getHomeSeasonDetail().subscribe((res: any) => { 
//       this.allRegistartionDetails = res
//       if (this.allRegistartionDetails) {
//         for (let i = 0; i < this.allRegistartionDetails.length; i++) {
//           if (this.allRegistartionDetails[i].SeasonType == type) {
//             this.regestrationDetails = this.allRegistartionDetails[i]
//             this.registartionStartDate = new Date(this.regestrationDetails.RegistrationStartDate)
//             this.registartionEndDate = new Date(this.regestrationDetails.RegistrationEndDate)
//             this.registartionEndDate = new Date(this.registartionEndDate.setHours(this.registartionEndDate.getHours() + 24));
//             this.regestrationDetails = this.allRegistartionDetails[i]
//             if (this.registartionStartDate > this.currentDate) {
//               this.nigRegClosed = true
//             }
//             else if (this.registartionStartDate < this.currentDate && this.registartionEndDate > this.currentDate) {
//               this.nigRegClosed = false
//             }
//             else {
//               this.nigRegClosed = true;
//             }

//           }
//           else if (this.regestrationDetails == undefined) {
//             this.nigRegClosed = true;
//           }
//         }
//         if (this.allRegistartionDetails.length == 0) {
//           this.nigRegClosed = true;
//         }
//       }
//       else {
//         this.nigRegClosed = true;
//       }
//       console.log(this.nigRegClosed)
//       this.nigRegClosed ? $('#regModel').modal('show') : this.router.navigate(['/school-registration'])
//     })
     
   
//   }
}
