import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidateSchoolComponent } from 'src/app/admin_management/school/validate-school/validate-school.component';
import { AlertService } from 'src/app/_service/alert.service';
import { HomeServiceService } from 'src/app/_service/home-service.service';
import * as pattern from "../../model/user";
declare var $: any;
declare var jic: any;
import { IPayPalConfig } from 'ngx-paypal';
import { ICreateOrderRequest } from "ngx-paypal";
import { emailValidator } from 'src/app/schoolmanagement/add-candidate/validators';
import { formatNumber } from '@angular/common';
@Component({
  selector: 'app-corporate-organisation',
  templateUrl: './corporate-organisation.component.html',
  styleUrls: ['./corporate-organisation.component.css']
})
export class CorporateOrganisationComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  fileList: FileList;
  tempImg: string | ArrayBuffer;
  uploadStudentImage: any;
  isSubmitted: boolean = false
  reader = new FileReader();
  materialList: any = [
    { name: "Text Books", value: "Text Books" },
    { name: "Exercise Books", value: "Exercise Books" },
    { name: "Uniform", value: "Uniform" }
  ];
  openMaterialList: boolean = false;
  statesList: any = [];
  countryList: any;
  stateId: any;
  transactioData = {
    TransectionId: "",
    Id: ""
  }

  id: any;
  payAccess: boolean;
  amount: any;
  item: any;
  loader: boolean;
  constructor(private fb: FormBuilder, private ref: ChangeDetectorRef,
    private service: HomeServiceService, private alert: AlertService, private router: Router) { }
  organisationDonateRegForm: FormGroup;
  ngOnInit() {
    // this.initConfig()
    window.scroll(0, 0);
    this.organisationDonateRegForm = this.fb.group({
      DonateType: [""],
      OrganisationProfile: ['', [Validators.required]],
      OrganisationName: ["", Validators.required],
      OrganisationType: ["", Validators.required],
      OrganisationEmail: ["", [Validators.required, Validators.email]],
      OrganisationPhone: ["", [
        Validators.required,
        Validators.minLength(7),
        Validators.pattern(pattern.phonePattern),
      ],
        // this.service.phoneExistValidator(),
      ],
      OrganisationAddress: ["", Validators.required],
      OrganisationStateOfOriign: ["", Validators.required],
      OrganisationNationality: ["", Validators.required],
      OrganisationPostalCode: ["", [Validators.required, Validators.minLength(6),
      Validators.maxLength(6)]],
      OrganisationDonateItems: ["", Validators.required],
      OrganisationDonateMaterial: new FormArray([], [Validators.required]),
      OrganisationAmountNeeded: ["", [Validators.required,Validators.min(1)]],
      OrganisationDropPoints: ["", Validators.required],
      Purpose: ["", Validators.required],
      agreed: ["", Validators.required]
    });
    // this.getStatesname()
    this.getCountry()
  }
  getCountry() {
    this.service.getCountry().subscribe(
      (res: any) => {
        this.countryList = res;
      },
      (error) => { }
    );
  }
  getStatesname(value) {
    if (value) {
      for (let i = 0; i < this.countryList.length; i++) {
        if (value == this.countryList[i].CountryName) {
          this.stateId = this.countryList[i].Id
        }
      }
    }
    this.service.getStatefromCountry(this.stateId).subscribe(
      (res: any) => {
        this.statesList = res;
      },
      (error) => { }
    );
  }
  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.tempImg = (<FileReader>event.target).result;
        this.organisationDonateRegForm.patchValue({
          OrganisationProfile: event.target.result
        });
      };
      reader.readAsDataURL(event.target.files[0]);
      this.ref.markForCheck();
    }

    this.fileList = event.target.files;
    this.uploadStudentImage = event.target.files[0];
  }
  donateItemsChange(value) {
    console.log(value)
    console.log("jhghjg")
    if (value == 'Material') {
      console.log("mm")
      this.openMaterialList = true
    }
    else {
      this.openMaterialList = false
    }
  }
  get f() {
    return this.organisationDonateRegForm.controls
  }
  onCheckboxChange(event) {
    const formArray: FormArray = this.organisationDonateRegForm.get(
      "OrganisationDonateMaterial"
    ) as FormArray;
    if (event.target.checked) {
      formArray.push(new FormControl(event.target.value));
    } else {
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }
  submit(form) {
    this.isSubmitted = true
    if (this.organisationDonateRegForm.invalid) {
      return
    }
    this.loader = true
    this.service.organisationdonationData(form.value).subscribe((res: any) => {
      // console.log(res)
      if (res) {
        if (form.value.OrganisationDonateItems == '' || form.value.OrganisationDonateItems == null) {
          this.item = form.value.OrganisationDonateItems
        }
        else {
          this.item = 'Donation'
        }
        var paymentData = {
          'Email': form.value.OrganisationEmail,
          Amount: form.value.OrganisationAmountNeeded, Items: this.item
        };
        sessionStorage.setItem('paymentData', JSON.stringify(paymentData))
      }

      this.alert.success("Registered successfully")
      this.router.navigate(["/payment-gateway"])

    }, (err) => {
      this.loader = false
      this.alert.error(err, true)
    })
  }
  nameValidations(event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  numbersOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  materialChange(value) {
    if (value == 'Material') {
      this.organisationDonateRegForm.get('OrganisationDonateMaterial').setValidators([Validators.required])
      this.organisationDonateRegForm.get('OrganisationDonateMaterial').updateValueAndValidity()
    }
    else {
      this.organisationDonateRegForm.get('OrganisationDonateMaterial').clearValidators()
      this.organisationDonateRegForm.get('OrganisationDonateMaterial').updateValueAndValidity()

    }
  }

  // private initConfig(): void {
  //   // this.paymentInitialization()
  //   this.payPalConfig = {
  //     currency: "EUR",
  //     clientId: "AYvU7p49APJ3TWCP7EPq6Z1Sm7LijDirPdDI-G6DjNasJ2tyIVCwb0IZL1v5cKy_tw7qPr_2ybS62gCR",
  //     createOrderOnClient: data =>
  //       <ICreateOrderRequest>{
  //         intent: 'CAPTURE',
  //         purchase_units: [
  //           {
  //             amount: {
  //               currency_code: 'EUR',
  //               // value: '9.99',
  //               value: this.organisationDonateRegForm.value.OrganisationAmountNeeded,
  //               breakdown: {
  //                 item_total: {
  //                   currency_code: 'EUR',
  //                   value: this.organisationDonateRegForm.value.OrganisationAmountNeeded
  //                 }
  //               }
  //             },
  //             items: [
  //               {
  //                 name: 'Enterprise Subscription',
  //                 quantity: '1',
  //                 category: 'DIGITAL_GOODS',
  //                 unit_amount: {
  //                   currency_code: 'EUR',
  //                   value: this.organisationDonateRegForm.value.OrganisationAmountNeeded
  //                 }
  //               }
  //             ]
  //           }
  //         ]
  //       },

  //     advanced: {
  //       commit: "true"
  //     },
  //     style: {
  //       label: "paypal",
  //       layout: "vertical"
  //     },
  //     onInit: (data, actions) => {
  //       console.log(data)
  //       console.log(actions)
  //     },
  //     onApprove: (data, actions) => {
  //       console.log(
  //         "onApprove - transaction was approved, but not authorized",
  //         data,
  //         actions
  //       );

  //       actions.order.get().then(details => {
  //         console.log(
  //           "onApprove - you can get full order details inside onApprove: ",
  //           details
  //         );
  //       });
  //     },
  //     onClientAuthorization: data=> {
  //       console.log(
  //         "onClientAuthorization - you should probably inform your server about completed transaction at this point",
  //         data
  //       );
  //       this.transactioData.TransectionId = data.id
  //       this.transactioData.Id = this.id
  //       this.service.paymentSucessfulApi(this.transactioData).subscribe((res: any) => {
  //         if(res){
  //           this.alert.success(res,true)
  //           this.router.navigate(["/ready-help"])
  //         }

  //       })

  //     },
  //     onCancel: (data, actions) => {
  //       console.log("OnCancel", data, actions);
  //       this.service.transactionFailedApi(this.id).subscribe((res:any)=>{

  //       })
  //     },
  //     onError: err => {
  //       this.service.transactionFailedApi(this.id ).subscribe((res:any)=>{
  //       })
  //       console.log("OnError", err);
  //     },
  //     onClick: (data, actions) => {
  //       console.log("onClick", data, actions);
  //     }
  //   };
  // }
  // paymentInitialization() {
  //   var data = {
  //     EmailId: this.organisationDonateRegForm.value.OrganisationEmail,
  //     Amount: this.organisationDonateRegForm.value.OrganisationAmountNeeded
  //   }
  //   this.service.paymentIntializationApi(data).subscribe((res: any) => {
  //     console.log(res)
  //     this.id = res

  //   })
  // }

}

