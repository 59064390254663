import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.css']
})
export class LoadingPageComponent implements OnInit {
  type: any;
  constructor(private route: Router) { }

  ngOnInit() {
  }

  countryType(value) {
    this.type = value
    localStorage.setItem('type', this.type)
    this.route.navigate(['/home'])
  }
}
