import * as url from "src/app/model/config";
export class FilterData {
  constructor() {
    this.Category = "";
    this.Class = "";
    this.ExamCentre = "";
    this.FlagGradeApproved = "";
    this.School = "";
    this.State = "";
    this.isactive = "";
    this.keyword = "";
    this.sortby = "";
    this.sortorder = "";
    this.Type = "Online";
    this.ispublished = 0;
    this.pageno = 1;
    this.pagesize = 10;
    this.Country = "";
  }
  Category: any;
  Class: any;
  ExamCentre: any;
  Country:any;
  FlagGradeApproved: any;
  School: any;
  State: any;
  Type: any;
  isactive: any;
  ispublished: any;
  keyword: any;
  pageno: any;
  pagesize: any;
  sortby: any;
  sortorder: any;
}
export class AdvanceFilterData {
  constructor() {
    this.Category = "";
    this.RegistrationType="";
    this.Class = "";
    this.ExamCentre = "";
    this.FlagGradeApproved = 2;
    this.School = "";
    this.State = "";
    this.isactive = "";
    this.keyword = "";
    this.sortby = "";
    this.sortorder = "";
    this.Type = "Online";
    this.ispublished = 0;
    this.pageno = 1;
    this.pagesize = 10;
    this.Country = "";
    this.levelOfStudy="";
    this.courseOfStudy="";
  }
  RegistrationType:any;
  Category: any;
  Class: any;
  ExamCentre: any;
  Country:any;
  FlagGradeApproved: any;
  School: any;
  State: any;
  Type: any;
  isactive: any;
  ispublished: any;
  keyword: any;
  pageno: any;
  pagesize: any;
  sortby: any;
  sortorder: any;
  levelOfStudy:any;
  courseOfStudy:any;
}


export class messageFilterData {
  constructor() {
    this.Category = "";
    this.EmailSubject = "";
    this.EmailText = "";
    this.IsBothDisabled = true;
    this.IsEmail = true;
    this.IsSms = true;
    this.MessageType = "";
    this.RecipientType = "";
    this.Season = "";
    this.SmsSender = "";
    this.SmsText = "";
    this.State = "";
    this.Country = "";
    this.pageno = 1;
    this.pagesize = 10;
    this.sortby = "";
    this.sortorder = "";
  }
  Category: any;
  EmailSubject: any;
  EmailText: any;
  IsBothDisabled: any;
  IsEmail: any;
  IsSms: any;
  MessageType: any;
  RecipientType: any;
  Season: any;
  SmsSender: any;
  SmsText: any;
  State: any;
  Country: any;
  pageno: any;
  pagesize: any;
  sortby: any;
  sortorder: any;
}

export class feedBackFilterData {
  constructor() {
    this.Concern = "";
    this.isactive = "";
    this.keyword = "";
    this.pageno = 1;
    this.pagesize = 10;
    this.sortby = "";
    this.sortorder = "";
  }
  Concern: any;
  isactive: any;
  keyword: any;
  pageno: any;
  pagesize: any;
  sortby: any;
  sortorder: any;
}

export class failedMessageFilterData {
  constructor() {
    this.IsEmail = false;
    this.IsSms = false;
    this.MessageType = "";
    this.TransactionId = 1;
    this.pageno = 1;
    this.pagesize = 10;
    this.sortby = "";
    this.sortorder = "";
  }
  IsEmail: any;
  IsSms: any;
  MessageType: any;
  TransactionId: any;
  pageno: any;
  pagesize: any;
  sortby: any;
  sortorder: any;
}

export class SeasonFilterData {
  constructor() {
    this.Id = 0;
    this.IsDeleted = false;
    this.SeasonName = "";
    this.Status = "";
    this.pageno = 1;
    this.pagesize = 10;
  }
  Id: any;
  IsDeleted: any;
  SeasonName: any;
  Status: any;
  pageno: any;
  pagesize: any;
}

export class resultFilterData {
  constructor() {
    this.CandidateType = "";
    this.Category = "Junior";
    this.Class = "";
    this.FirstName = "";
    this.FlagGradeApproved = 2;
    this.IsPublished = "";
    this.Name = "";
    this.QualifiedContestant = 54;
    this.SchoolName = "";
    this.State = "";
    this.Status = "";
    this.TotalRequired = 54;
    this.isactive = "";
    this.keyword = "";
    this.pageno = 1;
    this.pagesize = "10";
    this.sortby = "";
    this.sortorder = "DESC";
  }
  CandidateNumber: any;
  CandidateType: any;
  Category: any;
  Class: any;
  FirstName: any;
  FlagGradeApproved: any;
  IsPublished: any;
  Name: any;
  QualifiedContestant: any;
  SchoolName: any;
  State: any;
  Status: any;
  TotalRequired: any;
  isactive: any;
  keyword: any;
  pageno: any;
  pagesize: any;
  sortby: any;
  sortorder: any;
}
export class AdvanceResultFilterData {
  constructor() {
    // this.CandidateType = "";
    this.FirstName = "";
    // this.FlagGradeApproved = 2;
    // this.IsPublished = "";
    this.SchoolName=""
    this.CandidateNumber =""
    this.QualifiedContestant = 54;
    this.CandidateStatus = "";
    this.TotalRequired = 500;
    this.keyword = "";
    this.PageNo = 1;
    this.PageSize = "10";
    this.SortBy = "";
    this.SortOrder = "DESC";
    this.CourseOfStudy="";
    this.Season="";
    this.Name = "" ;
    this.Status = "";
    this.RegistrationType="";
  }
  RegistrationType:any;
  Season:any;
  CourseOfStudy:any
  CandidateNumber: any;
  CandidateStatus: any;
  FirstName: any;
  Name: any;
  Status: any;
  // FlagGradeApproved: any;
  // IsPublished: any;
  QualifiedContestant: any;
  SchoolName: any;
  // State: any;
  TotalRequired: any;
  // isactive: any;
  keyword: any;
  PageNo: any;
  PageSize: any;
  SortBy: any;
  SortOrder: any;
}
export class ResultFilterData {
  constructor() {
    // this.CandidateType = "";
    this.FirstName = "";
    // this.FlagGradeApproved = 2;
    // this.IsPublished = "";
    this.SchoolName=""
    this.CandidateNumber =""
    this.QualifiedContestant = 54;
    this.CandidateStatus = "";
    this.TotalRequired = 500;
    this.keyword = "";
    this.PageNo = 1;
    this.PageSize = "10";
    this.SortBy = "";
    this.SortOrder = "DESC";
    this.CourseOfStudy="";
    this.Season="";
    this.Name = "" ;
    this.Status = "";
    this.RegistrationType="";
  }
  RegistrationType:any;
  Season:any;
  CourseOfStudy:any
  CandidateNumber: any;
  CandidateStatus: any;
  FirstName: any;
  Name: any;
  Status: any;
  // FlagGradeApproved: any;
  // IsPublished: any;
  QualifiedContestant: any;
  SchoolName: any;
  // State: any;
  TotalRequired: any;
  // isactive: any;
  keyword: any;
  PageNo: any;
  PageSize: any;
  SortBy: any;
  SortOrder: any;
}
export class SeasonData {
  constructor() {
    this.Id = "0";
    this.IsDeleted = false;
    this.SeasonName = "";
    this.SeasonType = ""; 
    this.RegistrationStartDate = null;
    this.RegistrationEndDate = null;
    this.ExamStartDate = null;
    this.ExamEndDate = null;
    this.Max = "";
    this.Min = "";
    this.GenderType = "";
    this.Status = "";
    this.Category = ""
    this.pageno = 1;
    this.pagesize = "10";
    this.enableCheckbox = false;
    this.qualityEnableCheckbox = false;
    this.ExamTime="";
    this.ExamEndTime="";
    this.ExamType="";
    this.MinGirls ="";
    this.TestUrl="";
  }
  Id: any;
  IsDeleted: any;
  SeasonName: any;
  SeasonType: any;
  RegistrationStartDate: any;
  RegistrationEndDate: any;
  ExamStartDate: any;
  ExamEndDate: any;
  Max: any;
  Min:any;
  GenderType: any;
  Status: any;
  Category: any;
  pageno: any;
  pagesize: any;
  enableCheckbox: any;
  ExamTime:any;
  ExamEndTime:any;
  ExamType:any;
  qualityEnableCheckbox:any;
  MinGirls:any;
  TestUrl:any;
}

export class SchoolAndStudentReport {
  constructor() {
    this.Category = "School";
    this.FromDate = "";
    this.PageNo = 1;
    this.PageSize = "10";
    this.Registered = "true";
    this.State = "";
    this.Country=""
    this.ToDate = "";
  }
  Category: any;
  FromDate: any;
  PageNo: any;
  PageSize: any;
  Registered: any;
  State: any;
  Country:any;
  ToDate: any;
}

export class SchoolDropdownModel {
  SchoolName: string;
  Id: number;
  State: number;
}
export class ExamCentreDropdownModel {
  Name: string;
  Id: number;
  State: string | number;
  Country: string
}

export class resultFinalistModel {
  Category: string;
  QualifiedContestant: any = url.QualifiedCandidates;
  TotalRequired: any = url.TotalCandidateRequired;
  sortorder: string = "DESC";
  pageno: any;
  pagesize: any;
}
export class advanceRsultFinalistModel {
  Category: string;
  QualifiedContestant: any = url.QualifiedCandidates;
  TotalRequired: any = url.TotalCandidateRequired;
  RegistrationType:any;
  SortBy: any = "";
  SortOrder: any = "DESC";
  pageno: any;
  pagesize: any;
}

export class TopRequestParam {
  Category: any;
  SortBy: any = "";
  SortOrder: any = "";
  pageno: any;
  pagesize: any;
  isMailSend: boolean = false
}
export class AdvanceTopRequestParam {
  Category: any;
  SortBy: any = "";
  SortOrder: any = "";
  pageno: any;
  pagesize: any;
  RegistrationType:any = "";
  isMailSend: boolean = false
}

export class FinalPublishResultFilter {
  Category: any;
  SortBy: any = "";
  SortOrder: any = "";
  pageno: any;
  pagesize: any;
  CandidateRegistrationNo: any = "";
  Id: "0";
  IsDeleted: boolean = false;
  Name: any = "";
  SchoolName: any = "";
  SeasonName: any = "";
}
export class AdvanceFinalPublishResultFilter {
  Category: any;
  SortBy: any = "";
  SortOrder: any = "";
  pageno: any;
  pagesize: any;
  CandidateRegistrationNo: any = "";
  Id: "0";
  IsDeleted: boolean = false;
  Name: any = "";
  SchoolName: any = "";
  SeasonName: any = "";
  RegistrationType:any = "";
}

export class MannualCandidateModel {
  Id: string;
  Season: any;
  ExaminationNo: any;
  ExamCentreName: any;
  Category: any;
  CandidateState: any;
  Attendance: any;
  FirstName: any;
  LastName: any;
  CandidateEmail: any;
  CandidatePhone: any;
  SchoolName: any;
  Gender: any;
  Class: any;
  DOB: any;
  FatherName: any;
  FathersPhone: any;
  GaurdianEmail: any;
  CandidateStateOfOrigin: any;
  CandidateHobbies: any;
  MathTeacherName: any;
  MathTeacherPhone: any;
  MothersName: any;
  MothersPhone: any;
  Q1: any;
  Q2: any;
  Q3: any;
  Q4: any;
  IsUsed: any;
}
export class SchoolTypeReports {
  constructor(){
    this.Season = "";
    this.PageNo = 1;
    this.PageSize = "10"
    this.Country=""
    this.GroupState = []
  }
  Season: any;
  PageNo: any;
  PageSize: any;
  Country: any;
  GroupState: any;
}

export class SchoolTypeReport {
  constructor(){
    this.Season = "";
    this.PageNo = 1;
    this.PageSize = "10"
    this.Country=""
    this.GroupState = []
    this.RegistrationType = ""
  }
  Season: any;
  PageNo: any;
  PageSize: any;
  Country: any;
  GroupState: any;
  RegistrationType:any;
}
export class ScoreReport {
  constructor(){
    this.ScoreBracket = "";
    this.Country="";
    this.Season="";
    this.PageNo = 1;
    this.PageSize = "10"
    this.GroupState=""
    this.RegistrationType=""
  }
  ScoreBracket;any;
  Country:any;
  Season:any;
  PageNo: any;
  PageSize: any;
  GroupState:any;
  RegistrationType:any;
}
export class previousSchoolTypeReport {
  constructor(){
    this.Season = "";
    this.PageNo = 1;
    this.PageSize = "10"
    this.Country=""
    this.Id=""
  }
  Season: any;
  PageNo: any;
  PageSize: any;
  Country: any;
  Id:any;
}
export class AdminUsersModel {
  Id: number;
  Role: number;
  Email: string;
  Password: string;
}
export class PercentageTypeReport {
  constructor(){
    this.Season = "";
    this.PageNo = 1;
    this.PageSize = "10"
    this.Country=""
    this.GroupState=""
    this.RegistrationType= "Nigeria"
    // this.RegistrationType=""
  }
  Season: any;
  PageNo: any;
  PageSize: any;
  Country: any;
  GroupState:any;
  RegistrationType:any
  // RegistrationType:any;
}
export class ContactReport {
  constructor(){
    this.ContactType = "Candidate";
    this.PageNo = 1;
    this.PageSize = "10"
    this.IsComplete=""
    this.Module="Nigeria"
    this.Season=""
  }
  ContactType: any;
  PageNo: any;
  PageSize: any;
  IsComplete:any;
  Module:any;
  Season:any;
}
export class UniversityFilterData {
  constructor() {
    this.Category = "";
    this.Class = "";
    this.ExamCentre = "";
    this.FlagGradeApproved = "";
    this.School = "";
    this.State = "";
    this.isactive = "";
    this.keyword = "";
    this.sortby = "";
    this.sortorder = "";
    this.Type = "Online";
    this.ispublished = 0;
    this.pageno = 1;
    this.pagesize = 10;
    this.Country = "";
  }
  Category: any;
  Class: any;
  ExamCentre: any;
  Country:any;
  FlagGradeApproved: any;
  School: any;
  State: any;
  Type: any;
  isactive: any;
  ispublished: any;
  keyword: any;
  pageno: any;
  pagesize: any;
  sortby: any;
  sortorder: any;
}
export class UniversityResultFilterData {
  constructor() {
    // this.CandidateType = "";
    this.FirstName = "";
    // this.FlagGradeApproved = 2;
    // this.IsPublished = "";
    this.SchoolName=""
    this.CandidateNumber =""
    this.QualifiedContestant = 54;
    this.CandidateStatus = "";
    this.TotalRequired = 500;
    this.keyword = "";
    this.PageNo = 1;
    this.PageSize = "10";
    this.SortBy = "";
    this.SortOrder = "DESC";
    this.CourseOfStudy="";
    this.Season="";
    this.Name = "" ;
    this.Status = "";
    this.RegistrationType="";
  }
  RegistrationType:any;
  Season:any;
  CourseOfStudy:any
  CandidateNumber: any;
  CandidateStatus: any;
  FirstName: any;
  Name: any;
  Status: any;
  // FlagGradeApproved: any;
  // IsPublished: any;
  QualifiedContestant: any;
  SchoolName: any;
  // State: any;
  TotalRequired: any;
  // isactive: any;
  keyword: any;
  PageNo: any;
  PageSize: any;
  SortBy: any;
  SortOrder: any;
}