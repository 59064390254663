import { Component, OnInit } from "@angular/core";
import {
  PaginationModel,
  PropertyMetaModel,
  GridColumnType
} from "src/app/_models/pagination-model";

@Component({
  selector: "app-Temp",
  templateUrl: "./Temp.component.html",
  styleUrls: ["./Temp.component.css"]
})
export class TempComponent implements OnInit {
  cars: Car[];
  displayColumns: PropertyMetaModel[];

  public pageInfo: PaginationModel = {
    PageSize: 10,
    CurrentPage: 2,
    TotalRecord: 30,
    SortBy: "",
    SortOrder: "desc"
  };

  constructor() {
    this.cars = [
      {
        id: 1,
        year: "2019",
        vin_id: "asas",
        brand: "assa",
        color: "red",
        link: "/temp"
      },
      {
        id: 1,
        vin_id: "asas",
        year: "2019",
        brand: "assa",
        color: "red",
        link: "http://localhost:4200/temp?2"
      },
      {
        id: 1,
        vin_id: "asas",
        year: "2019",
        brand: "assa",
        color: "red",
        link: "http://localhost:4200/temp?3"
      },
      {
        id: 1,
        vin_id: "asas",
        year: "2019",
        brand: "assa",
        color: "red",
        link: "http://localhost:4200/temp?4"
      },
      {
        id: 1,
        vin_id: "asas",
        year: "2019",
        brand: "assa",
        color: "red",
        link: "http://localhost:4200/temp?5"
      },
      {
        id: 1,
        vin_id: "asas",
        year: "2019",
        brand: "assa",
        color: "red",
        link: "http://localhost:4200/temp?6"
      },
      {
        id: 1,
        vin_id: "asas",
        year: "2019",
        brand: "assa",
        color: "red",
        link: "http://localhost:4200/temp?7"
      },
      {
        id: 1,
        vin_id: "asas",
        year: "2019",
        brand: "assa",
        color: "red",
        link: "http://localhost:4200/temp?8"
      },
      {
        id: 1,
        vin_id: "asas",
        year: "2019",
        brand: "assa",
        color: "red",
        link: "http://localhost:4200/temp?9"
      },
      {
        id: 1,
        vin_id: "asas",
        year: "2019",
        brand: "assa",
        color: "red",
        link: "http://localhost:4200/temp?10"
      },
      {
        id: 1,
        vin_id: "asas",
        year: "2019",
        brand: "assa",
        color: "red",
        link: "http://localhost:4200/temp?11"
      }
    ];
    this.displayColumns = [
      {
        PropertyName: "year",
        ColumnName: "YEAR",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "color",
        ColumnName: "Colour",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "brand",
        ColumnName: "Brand",
        PropertyType: GridColumnType.Text
      },
      {
        PropertyName: "year",
        ColumnName: "Year",
        PropertyType: GridColumnType.Text
      },

      {
        PropertyName: "link",
        ColumnName: "Action",
        PropertyType: GridColumnType.Link,
        DisplayText: "Details"
      },
      {
        PropertyName: "id",
        ColumnName: "Action",
        PropertyType: GridColumnType.Action,
        DisplayText: ["Edit", "Update"]
      }
      // { PropertyName: "id", ColumnName: "", PropertyType: GridColumnType.Action, DisplayText:  }
    ];
  }

  ngOnInit() {
    //   setInterval(() => {
    //     if (this.cars.length > 12) {
    //       this.cars = [];
    //     } else {
    //       var obj = Object.assign([], this.cars);
    //       obj.push({ vin: "asas", year: "2019", brand: "assa", color: "red" });
    //       obj.push({ vin: "asas", year: "2019", brand: "assa", color: "red" });
    //       this.cars = obj;
    //     }
    //   }, 5000)
  }

  PageChange(pageInfo) {
    this.pageInfo = Object.assign({}, pageInfo);
  }

  gridClicked(data) {}
}

class Car {
  id;
  year;
  vin_id;
  brand;
  color;
  link;
}
