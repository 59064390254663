import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  jobData: any = [];
  totRec: number;
  viewAll = false;
  searchData: string = "";
  isDirty = false;

  constructor(
  ) {}

  ngOnInit() {
   
  }
  
}

