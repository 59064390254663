
//export const serviceBaseUrl = 'http://10.0.0.5:50790/';   //local machine
// export const serviceBaseUrl = 'http://10.0.0.12:50790/';   //local machine

// export const serviceBaseUrl = 'http://localhost:50790/';   //local machine
// export const serviceBaseUrl = 'http://125.63.74.98:5901/';   //hosted  
export const serviceBaseUrl = 'https://mathserver.quicklearning.africa/';
// export const serviceBaseUrl = 'http://95ba-2405-201-3026-b046-d13d-1feb-45ee-54ef.ngrok.io/';
// export const serviceBaseUrl = 'https://testserver.quicklearning.africa/';
// export const serviceBaseUrl = 'http://cd2f-2405-204-140a-d2ca-551b-91e8-dbdf-9811/'; 
// export const serviceBaseUrl = 'http://c2c2-47-31-184-82.ngrok.io/';
// http://fb44bc1c2a4b.ngrok.io/

// 

// PDF
export const getAchievmentPdf = `${serviceBaseUrl}PDF/GeneratePDFCertificateOfAchievement`
export const getExcellencePdf = `${serviceBaseUrl}PDF/GeneratePDFCertificateOfExcellence`
export const GeneratePDFCertificateOfExcellenceAfrica = `${serviceBaseUrl}PDF/GeneratePDFCertificateOfExcellenceAfrica?candidateId=`
export const GeneratePDFCertificateOfAchievementAfrica = `${serviceBaseUrl}PDF/GeneratePDFCertificateOfAchievementAfrica?candidateId=`
export const GeneratePDFCertificateOfClassOfYear = `${serviceBaseUrl}PDF/GeneratePDFCertificateOfClassOfYear`
export const GeneratePDFCertificateOfAchievementUniversity = `${serviceBaseUrl}PDF/GeneratePDFCertificateOfAchievementUniversity`
export const GeneratePDFCertificateOfExcellenceUniversity = `${serviceBaseUrl}PDF/GeneratePDFCertificateOfExcellenceUniversity`
export const getBatchPdf = `${serviceBaseUrl}PDF/GeneratePDFBatchCertificate`

export const getPdfURI = `${serviceBaseUrl}pdf/GeneratePDFBatchCertificate`
export const getIsResultPublishedURI = `${serviceBaseUrl}api/portal/portalcommon/Getisresultpublished`
export const approveCandidatesURI = `${serviceBaseUrl}api/portal/secure/candidate/verifyscore`
export const rejectCandidatesURI = `${serviceBaseUrl}api/portal/secure/candidate/rejectscore`

export const QualifiedCandidates = 54;
export const TotalCandidateRequired = 500;
// Guidlines 
export const PreviousSessionCandidatesReport = `${serviceBaseUrl}api/portal/secure/report/PreviousSessionCandidatesReport`
export const SchoolRegistrationReportByType = `${serviceBaseUrl}api/portal/secure/report/SchoolRegistrationReportbyType`
export const RegisteredStudentAverageAgeReport=`${serviceBaseUrl}api/portal/secure/report/RegisteredStudentAverageAgeReport`
export const SchoolRegistrationReportbyGenderType = `${serviceBaseUrl}api/portal/secure/report/SchoolRegistrationReportbyGenderType`
export const SchoolRegistrationReportbyRegistrationComplete = `${serviceBaseUrl}api/portal/secure/report/SchoolRegistrationReportbyRegistrationComplete`
export const ScoreWiseAverageAgeReport = `${serviceBaseUrl}api/portal/secure/report/RegisteredStudentScoreWiseAverageAgeReport`
export const qualifiedReport = `${serviceBaseUrl}api/portal/secure/report/RegisteredStudentQualifiedReport`
export const SchoolRankReport = `${serviceBaseUrl}api/portal/secure/report/SchoolRankReport`
export const StateRankReport = `${serviceBaseUrl}api/portal/secure/report/StateRankReport`
export const individualdonationData = `${serviceBaseUrl}api/portal/portalcommon/SaveIndividualDonationData`; 
export const organisationdonationData = `${serviceBaseUrl}api/portal/portalcommon/SaveOrganisationDonationData`; 
export const helpNeedDatta = `${serviceBaseUrl}api/portal/portalcommon/SaveStudentNeedHelpData`; 
export const schoolPreviousStudentData =  `${serviceBaseUrl}api/portal/secure/report/SchoolPreviousSessionCandidatesReport`;

export const getGuideLineURI = `${serviceBaseUrl}api/portal/portalcommon/getfaqs`
export const updateGuidelineURI = `${serviceBaseUrl}api/portal/secure/admin/updatefaq`
export const getallfaqURI = `${serviceBaseUrl}api/portal/portalcommon/getallfaq`
export const login = `${serviceBaseUrl}api/portalauthentication/AuthenticateportalUser`;
export const validateEmail = `${serviceBaseUrl}api/portal/portalcommon/ValidateEmail`;
export const validateNumber = `${serviceBaseUrl}api/portal/portalcommon/ValidateNumber`;
export const forgetPassword = `${serviceBaseUrl}api/portalauthentication/forgetpassword`;
export const getSeasonURI = `${serviceBaseUrl}api/portal/secure/candidate/getseason`;
export const loadPastQuestions = `${serviceBaseUrl}api/portal/secure/portalquestion/getallquestions`;
export const getYear = `${serviceBaseUrl}api/portal/secure/portalquestion/getyears`;
export const portalDocumentLocation = `${serviceBaseUrl}/QuestionDocuments/`;
export const getStates = `${serviceBaseUrl}api/portal/portalcommon/getstate`;
export const getSchoolType = `${serviceBaseUrl}api/portal/portalcommon/getschooltype`;
export const registerSchool = `${serviceBaseUrl}api/portal/portalcommon/addschool`;
// export const registerCandidate = `${serviceBaseUrl}api/portal/secure/candidate/AdvanceSignup`;
export const registerCandidate = `${serviceBaseUrl}api/portal/secure/candidate/AfricaSignup`;
export const registerUniversityCandidate = `${serviceBaseUrl}api/portal/secure/candidate/UniversitySignup`;
export const concertType = `${serviceBaseUrl}api/portal/portalcommon/getconcerntype`;
export const contactUs = `${serviceBaseUrl}api/portal/portalcommon/submitcontactus`;
export const getSchoolDetails = `${serviceBaseUrl}api/portal/secure/school`;
export const candidateGetAllcandidate = `${serviceBaseUrl}api/portal/secure/candidate/GetAllcandidate`;
// export const candidateGetAlladvancecandidate = `${serviceBaseUrl}api/portal/secure/candidate/getalladvancecandidate`;
export const candidateGetAlladvancecandidate = `${serviceBaseUrl}api/portal/secure/candidate/getallafricacandidate`;
export const candidateGetAllUniversitycandidate = `${serviceBaseUrl}api/portal/secure/candidate/getallUniversitycandidate`;

export const getClass = `${serviceBaseUrl}api/portal/secure/candidate/getclass`;
// country api state api
// api/portal/portalcommon/SaveIndividualDonationData
export const getCountry = `${serviceBaseUrl}api/portal/portalcommon/getcountry`;
export const getStatefromCOuntry = `${serviceBaseUrl}api/portal/portalcommon/getstate?CountryId=`;

export const createCenter = `${serviceBaseUrl}api/portal/secure/examcentre`;
export const getRegistrationStatusURI = `${serviceBaseUrl}api/portal/secure/candidate/getregistrationstatus`;
export const candidateRegisterAllURI = `${serviceBaseUrl}api/portal/secure/candidate/registerall?RegistrationCaptureDevice=`;
export const getCenterList = `${serviceBaseUrl}api/portal/secure/examcentre/getallexamcentre`;
export const getIsResultPublished = `${serviceBaseUrl}api/portal/portalcommon/Getisresultpublished`;
export const getSeason = `${serviceBaseUrl}api/portal/secure/candidate/getseason`;
export const getState = `${serviceBaseUrl}api/portal/portalcommon/getstate`;
export const getSchoolByState = `${serviceBaseUrl}api/portal/secure/candidate/getschoolbystate`;
export const getallSchoolWithState = `${serviceBaseUrl}api/portal/secure/candidate/getallSchoolWithState`;  ///testing
export const getExamCentreByState = `${serviceBaseUrl}api/portal/secure/candidate/getexamcentrebystate`;
export const getexamcentreWithstate = `${serviceBaseUrl}api/portal/secure/candidate/getexamcentreWithstate`; //testing
export const saveCandidateData = `${serviceBaseUrl}api/portal/secure/candidate?AddCaptureDevice=`;
export const deleteCandidateData = `${serviceBaseUrl}api/portal/secure/candidate`;

export const schoolListingURI = `${serviceBaseUrl}api/portal/secure/school/getallschool`
export const deleteCenter = `${serviceBaseUrl}api/portal/secure/examcentre/?id=`;
export const getCenterById = `${serviceBaseUrl}api/portal/secure/examcentre/?id=`;
export const validateSchools =  `${serviceBaseUrl}api/portal/portalcommon/validateschools`;
export const getValidateSchools= `${serviceBaseUrl}api/portal/portalcommon/getvalidateschools`;
export const category = `${serviceBaseUrl}api/portal/secure/candidate/getclass?category=`;
export const schoolDeleteURI = `${serviceBaseUrl}api/portal/secure/school`;
export const getCandidateList = `${serviceBaseUrl}api/portal/secure/candidate/GetAllcandidate`;
export const schoolUpdateURI=  `${serviceBaseUrl}api/portal/secure/school/updateschool`;
export const getAllManualCandidate = `${serviceBaseUrl}api/portal/secure/candidate/getallmanualcandidate`;
export const uploadManualCandidates = `${serviceBaseUrl}api/portal/secure/candidate/uploadmanualcandidates`;
export const attendanceSheet = `${serviceBaseUrl}api/portal/secure/report/AttendanceSheet`;
export const mas = `${serviceBaseUrl}api/portal/secure/candidate/GetAllcandidate/mas`;
export const getAddCandidateData = `${serviceBaseUrl}api/portal/secure/candidate`
export const getCandidateData = `${serviceBaseUrl}api/portal/secure/candidate/Dashboard`;
export const getAfricaCandidateData = `${serviceBaseUrl}api/portal/secure/candidate/Africa`;
export const getUniversityCandidateData = `${serviceBaseUrl}api/portal/secure/candidate/University`;
export const getCertificateDetails = `${serviceBaseUrl}api/portal/secure/candidate/CertificateDetails?Id=`;
export const getAllCandidate = `${serviceBaseUrl}api/portal/secure/candidate/GetAllcandidate`;
export const approveScore = `${serviceBaseUrl}api/portal/secure/candidate/verifyscore`;
export const rejectScore = `${serviceBaseUrl}api/portal/secure/candidate/rejectscore`;
export const getRecipientType = `${serviceBaseUrl}api/portal/secure/candidate/getrecipientsforbulkmessaging`;
export const sendMessageInBulk = `${serviceBaseUrl}api/portal/secure/candidate/sendmessageinbulk`;
export const IsCurrentSeasonPublished= `${serviceBaseUrl}api/portal/portalcommon/IsCurrentSeasonPublished`;
export const getTransactionId = `${serviceBaseUrl}api/portal/secure/candidate/gettransactionid`;
export const getFailedRecipients = `${serviceBaseUrl}api/portal/secure/candidate/getfailedrecipients`;
export const resendMessageInBulk = `${serviceBaseUrl}api/portal/secure/candidate/resendmessageinbulk`;
export const deleteMessage = `${serviceBaseUrl}api/portal/secure/candidate/deletemessage`;
export const result = `${serviceBaseUrl}api/portal/secure/report/result`;
// export const advanceresult = `${serviceBaseUrl}api/portal/secure/report/advanceCandidateResult`;

export const africaresult = `${serviceBaseUrl}api/portal/secure/report/africaCandidateResult`;
export const universityresult = `${serviceBaseUrl}api/portal/secure/report/univeristyCandidateResult`;


export const deleteRecord = `${serviceBaseUrl}api/portal/secure/report/delete`;
export const importQuestion = `${serviceBaseUrl}api/portal/secure/portalquestion/importquestions`;
export const deleteUploDocument = `${serviceBaseUrl}api/portal/secure/portalquestion/deleteuploaddocument`;
export const resetPasswordURI= `${serviceBaseUrl}api/portal/secure/user/changepassword`;
export const getSettings= `${serviceBaseUrl}api/portal/secure/admin/getsettings`;
export const updatesettings= `${serviceBaseUrl}api/portal/secure/admin/updatesettings`;
export const uploadMarks = `${serviceBaseUrl}api/portal/secure/candidate/uploadmarks`;
export const uploadadvMarks = `${serviceBaseUrl}api/portal/secure/candidate/uploadadvancecandidatemarks`;
export const concernTypeURI= `${serviceBaseUrl}api/portal/portalcommon/getconcerntype`;
export const feedbacksURI= `${serviceBaseUrl}api/portal/secure/admin/getfeedback`;
export const getPortalseasonURI= `${serviceBaseUrl}api/portal/secure/admin/getseason`;
export const updateScore = `${serviceBaseUrl}api/portal/secure/candidate/updatescore`;
// export const updateAdvScore = `${serviceBaseUrl}api/portal/secure/candidate/updateAdvanceCandidatescore`;
export const updateAfricaScore = `${serviceBaseUrl}api/portal/secure/candidate/updateAfricaCandidatescore`;
export const updateUniversityScore = `${serviceBaseUrl}api/portal/secure/candidate/updateUniversityCandidatescore`;
export const updateArchiveSeasonURI= `${serviceBaseUrl}api/portal/secure/admin/updatearchiveseason`;
export const deleteSeasonURI= `${serviceBaseUrl}api/portal/secure/admin/deleteSeason`;
export const updatePublishSeasonURI= `${serviceBaseUrl}api/portal/secure/admin/updatepublishseason`;
export const modifySeasonURI= `${serviceBaseUrl}api/portal/secure/admin/modifyseason`;
export const addSeasonURI = `${serviceBaseUrl}api/portal/secure/admin/modifyseason`;
// export const getRegisteredSchools= `${serviceBaseUrl}api/portal/secure/school/getschoolbydate?stateId=`;
export const getRegisteredSchools= `${serviceBaseUrl}api/portal/secure/school/getschoolbydate`;
export const getRegisteredStudents= `${serviceBaseUrl}api/portal/secure/candidate/getcandidatebydate?stateId=`;
export const deleteManualRecord= `${serviceBaseUrl}api/portal/secure/deleteManualCandidate`;
export const updateEmail = `${serviceBaseUrl}api/portal/portalcommon/UpdateSchoolEmail`;
export const africaUpdateEmail = `${serviceBaseUrl}api/portal/secure/candidate/UpdateAfricaCandidateEmail`;
export const universityUpdateEmail = `${serviceBaseUrl}api/portal/secure/candidate/UpdateUniversityCandidateEmail`;
export const resendLink  = `${serviceBaseUrl}api/portal/portalcommon/ResendVerificationMail?email=`;
export const africaResendLink = `${serviceBaseUrl}api/portal/secure/candidate/ResendAfricaCandidateVerificationMail?email=`
export const universityResendLink = `${serviceBaseUrl}api/portal/secure/candidate/ResendUniversityCandidateVerificationMail?email`

//Phase 2
export const getCompendiumYears= `${serviceBaseUrl}api/portal/secure/portalanswer/getyears`;
export const getCompendiumVolumes = `${serviceBaseUrl}api/portal/secure/portalanswer/getVolume`;
export const loadAnswers = `${serviceBaseUrl}api/portal/secure/portalanswer/getCompendium`;
export const importCompendium = `${serviceBaseUrl}api/portal/secure/portalanswer/ImportCompendium`;
export const deleteUploadCompendium = `${serviceBaseUrl}api/portal/secure/portalanswer/deleteuploaddocument`;

//Syllabus
export const getSyllabus = `${serviceBaseUrl}api/getSyllabus`;
export const deleteSyllabus = `${serviceBaseUrl}api/DeleteSyllabus`;
export const importSyllabus = `${serviceBaseUrl}api/AddSyllabus`;
export const getSyllabusYears = `${serviceBaseUrl}api/getyears`;

//Publish Result
export const getPublishResult= `${serviceBaseUrl}api/portal/portalcommon/getpublishresult`;
export const getTop3Result= `${serviceBaseUrl}api/portal/portalcommon/getstatetop3candidateresult`;
export const getFinalPublishResult= `${serviceBaseUrl}api/portal/portalcommon/getfinalPublishResult`;
export const downLoadPdf= `${serviceBaseUrl}PortalDocs/Ghana_JSCE_Maths_syllabus.pdf`;

// userRole
export const addAdminUserRole=`${serviceBaseUrl}api/portal/portalcommon/AddAdmin`
export const getAdminUserRoles =`${serviceBaseUrl}api/portal/portalcommon/GetAdminUserDetails`
export const getUserRolesById =`${serviceBaseUrl}api/portal/portalcommon/GetAdminUserDetailsById?Id=`

//prepclasses
export const AddUpdatePrepClasses =`${serviceBaseUrl}api/portal/secure/candidate/AddUpdatePrepClassData`

// contact us-footer
export const ContactUsdetails=`${serviceBaseUrl}api/portal/portalcommon/SubmitContactUs`
export const countryList=`${serviceBaseUrl}api/portal/portalcommon/GetCountryCodeList`
// continental reports
export const ContinentalCountryRatingReport=`${serviceBaseUrl}api/portal/secure/report/AfricaCountryRatingReport`
export const ContactDetailsReport=`${serviceBaseUrl}api/portal/secure/report/ContactDetailsReport`
export const ContinentalRegistrationReport=`${serviceBaseUrl}api/portal/secure/report/AfricaRegistrationReport`
export const ContinentalPercentageReport=`${serviceBaseUrl}api/portal/secure/report/CountryRegistrationPercentageReport`
export const ContinentalAverageAgeReport=`${serviceBaseUrl}api/portal/secure/report/CountryAverageAgeReport`
// school deregister
export const deRegisterCandidate = `${serviceBaseUrl}api/portal/secure/school/DeregisterSchool?schoolId=`
// export const advanceCandidateList = `${serviceBaseUrl}api/portal/secure/candidate/getalladvancecandidate`
export const advanceCandidateList = `${serviceBaseUrl}api/portal/secure/candidate/getallafricacandidate`
// paypal 
export const paymentIntialization = `${serviceBaseUrl}api/portal/portalcommon/InitializePaypalTransection`
export const paymentSuccessfulTransaction =`${serviceBaseUrl}api/portal/portalcommon/SuccessfulPaypalTransection`
export const failedTransaction =`${serviceBaseUrl}api/portal/portalcommon/FailedPaypalTransection?id=`
// 
// export const getAdvanceCandidatePublishResult=`${serviceBaseUrl}api/portal/portalcommon/GetAdvanceCandidatePublishResult`
export const getAfricaCandidatePublishResult=`${serviceBaseUrl}api/portal/portalcommon/GetPublishAfricaCandidateResult`
export const getUniversityPublishResult=`${serviceBaseUrl}api/portal/portalcommon/GetPublishUniversityCandidateResult`
export const getTop3AdvanceCandidatePublishResult=`${serviceBaseUrl}api/portal/portalcommon/GetStateTop3AdvanceCandidateResult`
export const getAdvanceFinalCandidatePublishResult=`${serviceBaseUrl}api/portal/portalcommon/GetAdvanceCandidateFinalPublishResult`
export const getHomeSeasonDetail=`${serviceBaseUrl}api/portal/portalcommon/GetHomeSeasonDetail`
export const registerEmail=`${serviceBaseUrl}api/portal/secure/candidate/SaveCandidateRecord?email=`
export const getCourseOfStudy=`${serviceBaseUrl}api/portal/secure/candidate/getclass`
export const getUniversityCourseOfStudy = `${serviceBaseUrl}api/portal/secure/candidate/getCourseOfStudy`
export const getUniversityOfStudy = `${serviceBaseUrl}api/portal/secure/candidate/getUniversityOfStudy`
export const hallOfFame=`${serviceBaseUrl}api/portal/secure/report/HallOfFameDetails`
export const universityHonourRollDetail=`${serviceBaseUrl}api/portal/secure/report/universityHonourRollDetail`
export const nigeriaHonourRollDetail=`${serviceBaseUrl}api/portal/secure/report/nigeriaHonourRollDetail`
export const africaHonourRollDetail=`${serviceBaseUrl}api/portal/secure/report/africaHonourRollDetail`
export const uploadhofcandidate=`${serviceBaseUrl}api/portal/secure/admin/uploadhofcandidate`
export const emailVerification=`${serviceBaseUrl}api/portal/portalcommon/ResendVerificationMail?email=`