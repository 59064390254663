import { Component, OnInit } from '@angular/core';
import { CompendiumModel, SyllabusModel } from 'src/app/models/home-page-model/home-page-model.module';
import * as url from "src/app/model/config";
import { HomeSecondService } from 'src/app/_service/home-second.service';
import { HomeServiceService } from 'src/app/_service/home-service.service';
import { NavigationEnd, Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-continental-header',
  templateUrl: './continental-header.component.html',
  styleUrls: ['./continental-header.component.css']
})
export class ContinentalHeaderComponent implements OnInit {
  Category = [
    { Id: "Junior", CategoryName: "Junior" },
    { Id: "Senior", CategoryName: "Senior" }
  ];
  Filters = {
    pageno: 1,
    pagesize: "5",
    isactive: "",
    sortorder: "",
    sortby: "",
    Season: "",
    Category: ""
  };
  SelectedItem = [];
  SelectedItemArray = [];
  List = { TotalRecords: 0, List: [] };
  SyllabusList = { TotalRecords: 0, List: [] };
  hideLabelText = null;
  year: any;
  BaseAddress: any;
  noRecordFoundText: any;
  isPublished: any;
  public loginExist: boolean = false;
  public compendiumModel: CompendiumModel = new CompendiumModel();
  public SyllabusModel: SyllabusModel = new SyllabusModel();
  public compendiumYear: any;
  public syllabusYear: any;
  public compendiumVolume: any;
  //parms = $routeParams;
  States = [];
  userProfile: any;
  afterLoginHeader: boolean;
  currentUrl: string;
  IsprePublished: any;
  IsQualifyPublished: any;
  constructor(public serviceSecond: HomeSecondService, public service: HomeServiceService,private router: Router) {
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        window.scroll(0, 0);
        var url = document.location.pathname;
        this.currentUrl = url;
        if (url == "/" || url == "/home") {
          if (localStorage.getItem("auth_token") != undefined) {          
            this.afterLoginHeader = true;
          } else {           
            this.afterLoginHeader = false;
          }
        }
        if (url == "/" || url == "/login") {
          // if (localStorage.getItem("auth_token") != undefined) {          
            this.afterLoginHeader = true;
          // } 
        }
      }
    });
  }

  ngOnInit() {
    if ( this.currentUrl == "/" || this.currentUrl == "/home" ) 
    {
        if (localStorage.getItem("auth_token") != undefined) {
           this.afterLoginHeader = true;
        } else
         {       
          this.afterLoginHeader = false;
        }
   }
    this.service.getIsResultPublishedURI().subscribe(
      (res: any) => {
        if (res) {
          // var result = JSON.parse(JSON.stringify(res));
          if (res && res.List && res.List[0]) {
            this.isPublished = res.List[0].IsPublished;
            this.IsprePublished = res.List[0].IsprePublished;
            this.IsQualifyPublished = res.List[0].IsQualifyPublished
          }
          // var data = {
          //   IsprePublished :res.List[0].IsprePublished,
          //   IsQualifyPublished:res.List[0].IsQualifyPublished
          // }
          // localStorage.setItem("isResultPublished", JSON.stringify(data));
        }
      },
      err => {}
    );
    // 
    if (localStorage.getItem("Role")) this.loginExist = true;
    this.getYearsList();
    this.getCompendiumYears();
    this.getSyllabusYears();
    this.getCompendiumVolumes();
    this.userProfile = JSON.parse(localStorage.getItem("ls.userProfile"));


    
  }

  categoryChange = function(CategoryID) {
    this.Filters.Category = CategoryID;
  };

  setNoRecordMessage = function() {
    if (this.List.TotalRecords == 0 || this.List.TotalRecords == undefined)
      this.noRecordFoundText = "No Question document found.";
    else this.noRecordFoundText = "";
  };

  setNoSyllabusMessage = function() {
    if (this.List.TotalRecords == 0 || this.List.TotalRecords == undefined)
      this.noRecordFoundText = "No Syllabus document found.";
    else this.noRecordFoundText = "";
  };

  loadList = function() {
    this.serviceSecond.LoadQuestions(this.Filters).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.List = res;
          this.BaseAddress = url.portalDocumentLocation;
          this.hideLabelText = res.List.length;
          this.setNoRecordMessage();
        } else {
          this.List.TotalRecords = 0;
          this.List.List = [];
          this.hideLabelText = null;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.setNoRecordMessage();
        this.hideLabelText = null;
        this.List = { TotalRecords: 0, List: [] };
      }
    );
  };

  clearAll = function() {
    this.List.TotalRecords = 0;
    this.List.List = [];
    this.hideLabelText = null;
    this.Filters.Category = "";
    //this.year = "";
  };
  getYearsList = function() {
    this.serviceSecond.getYears(this.Filters).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.year = res.List;
        } else {
          this.List.TotalRecords = 0;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.List = { TotalRecords: 0, List: [] };
        this.setNoRecordMessage();
      }
    );
  };

  yearChange = function(year) {
    this.Filters.Season = year;
  };

  //Phase 2

  getCompendiumYears() {
    this.serviceSecond.getCompendiumYears().subscribe(
      (res: any) => {
        if (res != null && res != "" && res.List.length > 0) {
          this.compendiumYear = res.List;
        } else {
          this.List.TotalRecords = 0;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.List = { TotalRecords: 0, List: [] };
        this.setNoRecordMessage();
      }
    );
  }

  getSyllabusYears() {
    this.serviceSecond.getSyllabusYears().subscribe(
      (res: any) => {
        if (res != null && res != "" && res.List.length > 0) {
          this.syllabusYear = res.List;
        } else {
          this.List.TotalRecords = 0;
          this.setNoSyllabusMessage();
        }
      },
      err => {
        this.List = { TotalRecords: 0, List: [] };
        this.setNoSyllabusMessage();
      }
    );
  }

  getCompendiumVolumes() {
    this.serviceSecond.getCompendiumVolumes().subscribe(
      (res: any) => {
        if (res != null && res != "" && res.List.length > 0) {
          this.compendiumVolume = res.List;
        } else {
          this.List.TotalRecords = 0;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.List = { TotalRecords: 0, List: [] };
        this.setNoRecordMessage();
      }
    );
  }

  closeHeader(){
    let ele = document.getElementById("home-left-nav");
    let el = document.getElementById("navbar");
    if(el && el.classList.contains('in')){
      ele.click()
    }
  }


  getCompendiumList = function() {
    this.serviceSecond.LoadAnswers(this.compendiumModel).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.List = res;
          this.BaseAddress = url.serviceBaseUrl + "/CompendiumDocuments/";
          this.hideLabelText = res.List.length;
          this.setNoRecordMessage();
        } else {
          this.List.TotalRecords = 0;
          this.List.List = [];
          this.hideLabelText = null;
          this.setNoRecordMessage();
        }
      },
      err => {
        this.setNoRecordMessage();
        this.hideLabelText = null;
        this.List = { TotalRecords: 0, List: [] };
      }
    );
  };

  getSyllabusList = function() {
    this.serviceSecond.LoadSyllabus(this.SyllabusModel).subscribe(
      res => {
        if (res != null && res != "" && res.List.length > 0) {
          this.List = res;
          this.BaseAddress = url.serviceBaseUrl + "/SyllabusDocuments/";
          this.hideLabelText = res.List.length;
          this.setNoSyllabusMessage();
        } else {
          this.List.TotalRecords = 0;
          this.List.List = [];
          this.hideLabelText = null;
          this.setNoSyllabusMessage();
        }
      },
      err => {
        this.setNoSyllabusMessage();
        this.hideLabelText = null;
        this.List = { TotalRecords: 0, List: [] };
      }
    );
  };

    logout() {
      localStorage.clear();
      if (this.router.url == "/") {
        // location.reload();
        return;
      }
      this.router.navigate([""]);
    }
    goTo(type){
      if(type === 'objective'){
        this.router.navigate(['/home'], { queryParams: { type: 'objective' } })
      }
      if(type === 'target'){
        this.router.navigate(['/home'], { queryParams: { type: 'target' } })
      }
      if(type === 'registration'){
        this.router.navigate(['/home'], { queryParams: { type: 'registration' } })
      }
      if(type === 'stages'){
        this.router.navigate(['/home'], { queryParams: { type: 'stages' } })
      }
      if(type === 'prizes'){
        this.router.navigate(['/home'], { queryParams: { type: 'prizes' } })
      }
      if(type === 'about-cruxlearning'){
        this.router.navigate(['/home'], { queryParams: { type: 'about-cruxlearning' } })
      }

    }
    resultTab(){
      $("#viewResultModel").modal("show");
    }
    selectResultsFor(type){
      if(type == 'nigeria'){
        this.router.navigate(['result/finalists'])
        $("#viewResultModel").modal("hide");
      }
      else if(type == 'advanced'){
        this.router.navigate(['result/africa/finalists'])
        $("#viewResultModel").modal("hide");
      }
      else if(type == 'continental'){
        this.router.navigate(['result/university/finalists'])
        $("#viewResultModel").modal("hide");
      }
     
    }
}

