import { Component, OnInit } from '@angular/core';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { Router } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { AlertService } from '../_service/alert.service';
import { HomeServiceService } from '../_service/home-service.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  amount : string = '1'
  transactioData = {
    TransectionId: "",
    Id: ""
  }
  payData :any;
  paymentInitId: any;
  orderID: string;
  constructor(
    private service: HomeServiceService, private alert: AlertService, private router: Router) { }

  ngOnInit() {
    this.initConfig();
    // debugger
    if(sessionStorage.paymentData != undefined){
      this.payData = JSON.parse(sessionStorage.paymentData);
      this.payData.Amount = parseFloat(this.payData.Amount).toFixed(2)
    }
    console.log(this.payData.Amount)
  
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: "EUR",
      clientId: "Af6uy_WnOJgryAETUr4mv8veXhR14LMdsI4wKgEVxG0pdlZ91UQhEhktLfMahwaDJrzSugXBmGMAD2yn",
      createOrderOnClient: data =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'EUR',
                value: this.payData.Amount,
                breakdown: {
                  item_total: {
                    currency_code: 'EUR',
                    value:  this.payData.Amount
                  }
                }
              },
              items: [
                {
                  name: this.payData.Items,
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'EUR',
                    value: this.payData.Amount
                  }
                }
              ]
            }
          ]
        },

      advanced: {
        commit: "true"
      },
      style: {
        label: "paypal",
        layout: "vertical"
      },
      onInit: (data, actions) => {
        console.log(data)
        console.log(actions)
      },
      onApprove: (data, actions) => {
        console.log(
          "onApprove - transaction was approved, but not authorized",
          data,
          actions
        );
        this.orderID = data.orderID
        this.paymentInitialization()
        actions.order.get().then(details => {
          console.log(
            "onApprove - you can get full order details inside onApprove: ",
            details
          );
        });
      },
      onClientAuthorization: data=> {
        console.log(
          "onClientAuthorization - you should probably inform your server about completed transaction at this point",
          data
        );
        this.transactioData.TransectionId = data.id
        this.transactioData.Id = this.paymentInitId
        this.service.paymentSucessfulApi(this.transactioData).subscribe((res: any) => {
          if(res){
            this.alert.success(res,true)
            this.router.navigate(["/ready-help"])
            sessionStorage.clear()
          }
        })

      },
      onCancel: (data, actions) => {
        console.log("OnCancel", data, actions);
        this.service.transactionFailedApi(this.paymentInitId).subscribe((res:any)=>{

        })
      },
      onError: err => {
        this.service.transactionFailedApi(this.paymentInitId).subscribe((res:any)=>{
        })
        console.log("OnError", err);
      },
      onClick: (data, actions) => {
        console.log("onClick", data, actions);
      }
    };
  }
  paymentInitialization() {
    var data = {
      EmailId: this.payData.Email,
      Amount: this.payData.Amount,
      orderId: this.orderID
    }
    this.service.paymentIntializationApi(data).subscribe((res: any) => {
      console.log(res)
      this.paymentInitId = res

    })
  }
}
